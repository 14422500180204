import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { pt } from 'date-fns/locale';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import {
    fetchReservationBlockedDays,
    fetchReservationBlockedWeekdays,
  } from '../actions/reservationsActions';
import Messages from './Messages'
import Loader from './Loader'
import { Table, Button, Container } from 'react-bootstrap';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz'

const DatePickerWrapper = styled.div`
  width: 100%;
  font-size: 1.5rem;
  justify-content: center;

  .react-datepicker__day:hover {
    background-color: inherit !important;
    opacity: 1 !important;
    transform: none !important;
    box-shadow: none !important;
  }
  

  .react-datepicker__day--selected:hover {
    background-color: #61ab9b !important;  /* Setting hover background color for the selected date */
  }

  .react-datepicker {
    border: 0;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  .react-datepicker__day--selected {
    background-color: #25d9b2 !important; 
  }

  .react-datepicker__header {
    background-color: #f8f9fa;
    border-bottom: 1px solid #ccc;
  }

  .react-datepicker__current-month {
    font-size: 1.2rem;
  }

  .react-datepicker__day {
    width: 40px;
    line-height: 40px;
    font-size:15px;
    margin:7px 7px!important;
  }

  .react-datepicker__day-name {
    font-weight: bold;
    padding: 0 0 10px 0 !important; 
    margin: 0 12px !important; 
  }
  @media (max-width: 458px) {
    font-size: 1.9rem;
    .react-datepicker__day {
      width: 1.9rem;
      height: 1.9rem;
      line-height: 1.9rem;
    }
    .react-datepicker__month-container {
      width: auto; // Adjust as needed
    } 
    .react-datepicker__day-name {
      gap:5px;
      margin: 0 9px !important; /* Add horizontal margins for spacing */
    }
  }

  @media (max-width: 390px) {
    font-size: 1rem;
    .react-datepicker__day {
      width: 1.5rem;
      height: 1.5rem;
      line-height: 1.5rem;
    }
    .react-datepicker__month-container {
      width: auto; // Adjust as needed
    } 
    .react-datepicker__day-name {
      gap:5px;
      margin: 0 5px !important; /* Add horizontal margins for spacing */
    }
  }
`;

const CustomDatePicker = ({ selectedDate, onDateChange, id, handleCloseDateModal}) => {
    const dispatch = useDispatch();
    const [showDate, setShowDate] = useState(new Date());
    const [ currentTimeZone, setCurrentTimeZone ] = useState('Europe/London');
    const reservationBlockedDays = useSelector((state) => state.reservationBlockedDays);
    const { blockedDays } = reservationBlockedDays;
    const reservationBlockedWeekDays = useSelector((state) => state.reservationBlockedWeekDays);
    const { blockedWeekdays, isLoading, error, isRestaurantOpen, restaurantTimeZone } = reservationBlockedWeekDays;


    useEffect(() => {
    console.log('Calling fetchReservationBlockedDays and fetchReservationBlockedWeekdays');
    dispatch(fetchReservationBlockedDays(id));
    dispatch(fetchReservationBlockedWeekdays(id));
    console.log('Called fetchReservationBlockedDays and fetchReservationBlockedWeekdays');
    }, [dispatch]);

    let blockedWeekdayss = [];
    if (blockedWeekdays !== undefined && blockedWeekdays !== '') {
        blockedWeekdayss = blockedWeekdays
        .split(',')
        .map((str) => parseInt(str.trim()))
        .filter((num) => !isNaN(num));
    }
    
    useEffect(() => {
      if (restaurantTimeZone) {
        setCurrentTimeZone(restaurantTimeZone.timezone);
      };
    },[restaurantTimeZone]);


    let blockedDayss;
    let dateObject = null;

    if (isRestaurantOpen && isRestaurantOpen.block_day !== 'No day needs to be blocked.') {
      let dateParts = isRestaurantOpen.block_day.split("-");
      dateObject = new Date(+dateParts[0], +dateParts[1] - 1, +dateParts[2]); 
    }

    if (blockedDays) {
      blockedDayss = blockedDays.split(',').map(dateStr => {
        const [day, month, year] = dateStr.split('-').map(Number);
        let blockedDate = new Date(year, month - 1, day);
        // Convert blockedDate to restaurant's timezone
        return utcToZonedTime(blockedDate, currentTimeZone);
      });
    } else {
      blockedDayss = [];
    }

    if (dateObject !== null) {
      blockedDayss.push(dateObject);
    }

     const isBlocked = (date) => {
        const day = date.getDay();
        // Adjusting the comparison to take into account only the date, not the time
        const isBlockedDay = Array.isArray(blockedDayss) && blockedDayss.some(
          (blockedDate) => blockedDate.getDate() === date.getDate() && blockedDate.getMonth() === date.getMonth() && blockedDate.getFullYear() === date.getFullYear()
        );
        const isBlockedWeekday = blockedWeekdayss.includes(day);
      
        return isBlockedDay || isBlockedWeekday;
      };

      useEffect(() => {
        if (!selectedDate) {
            return;
        }
    
        let currentDate = utcToZonedTime(new Date(selectedDate.getTime()), currentTimeZone);
    
        if (!isBlocked(currentDate)) {
            onDateChange(currentDate);
            return;
        }
    
        currentDate.setDate(currentDate.getDate() + 1);  // Move to the next day
        
        while (isBlocked(currentDate)) {
            currentDate.setDate(currentDate.getDate() + 1);
        }
    
        let dateInUTC = zonedTimeToUtc(currentDate, currentTimeZone);
    
        onDateChange(dateInUTC);
    }, [blockedDays, blockedWeekdays, currentTimeZone]);

      // Get the date in the New York time zone
let dateInNewYork = utcToZonedTime(new Date(), currentTimeZone)  
  
    return (
        <DatePickerWrapper>
        {isLoading ? 
        <h2><Loader></Loader></h2>
        :blockedWeekdays?    
        <DatePicker
        inline
        locale={pt}
        selected={selectedDate}
        dayClassName={(date) => {
          let classNames = "";
    
    if (
      selectedDate &&
      date.getDate() !== selectedDate.getDate() &&
      date.getMonth() !== selectedDate.getMonth() &&
      date.getFullYear() !== selectedDate.getFullYear()
    ) {
      // Class name for the selected date
      classNames += "react-datepicker__day";
    }

    return classNames.trim();
  }}
  onChange={(date) => {
    console.log("Selected date (before conversion):", date);
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    let dateInUTC = zonedTimeToUtc(date, currentTimeZone);
    console.log("Selected date (after conversion):", dateInUTC);
    onDateChange(dateInUTC);
    handleCloseDateModal();
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }}
  filterDate={(date) => !isBlocked(date)}
  minDate={dateInNewYork} // Use dateInNewYork here instead of new Date()
/>
        
        : error  ? 
        <h2><Messages variant='danger'>{error}</Messages></h2>
        :
        <h2>notting to be found</h2>
        }
      </DatePickerWrapper>
    );
    
  };

export default CustomDatePicker;