import React, { useState, useEffect } from 'react';
import 'react-phone-input-2/lib/style.css'; // Import the default CSS for react-phone-input-2
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css'; // Import the Bootstrap CSS for additional styling
import { PhoneNumber } from 'libphonenumber-js';
import {getDialCode, getCountryCode} from './CountryCode';
import { isValidNumber } from 'libphonenumber-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePhoneFlip } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const InputOverlay = styled.input`
  position: absolute;
  top: 33px;
  font-size:16px;
  left: 105px; 
  height: 36px;
  border: none;
  border-radius: 0 3px 0 0;
  background: none;
  outline: none;
  background-color:none;
  padding-bottom:4px;
`;

const StyledPhoneInput = styled(PhoneInput)`
  .form-control {
    max-width: 100%;
    box-sizing: border-box;
    overflow:hidden;
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
`;


function PhoneField({countryCodeNumber, setCountryCodeNumber, phoneWithoutCountryCode, setPhoneWithoutCountryCode, isPhoneValid, setIsPhoneValid}) {

    const [countryCode, setCountryCode] = useState('pt');

    const detectCountryCode = () => {
        const userCountryCode = navigator.language.split("-")[1];
        const formattedCountryCode = userCountryCode ? userCountryCode.toLowerCase() : "us";
        // Add more specific checks if needed based on the origin or location detection mechanism
        return formattedCountryCode; // Default to 'PT' if the country code is not detected
      };

    useEffect(() => {
        if (countryCodeNumber === '') {
          const detectedCountryCode = detectCountryCode();
          setCountryCode(detectedCountryCode);
          setCountryCodeNumber(getDialCode(detectedCountryCode));
        } else {
          setCountryCode(getCountryCode(countryCodeNumber));
        }
      }, [countryCodeNumber, countryCode]);

    useEffect(() => {
        setIsPhoneValid(isValidNumber('+' + countryCodeNumber + phoneWithoutCountryCode));
    },[phoneWithoutCountryCode, countryCode, countryCodeNumber])

    const MAX_CHARACTER_LIMIT = 10; 
    const handleInputPhoneChange = (e) => {
      const inputValue = e.target.value;
      if (inputValue.length <= MAX_CHARACTER_LIMIT) {
        setPhoneWithoutCountryCode(inputValue);
      }
    };

  return (
    <div style={{ position: 'relative' }}>
    <Label>Telemóvel</Label>
    <StyledPhoneInput
        country={countryCode}
        onChange={setCountryCodeNumber}
        inputClass="form-control"
        inputProps={{
        name: 'phone',
        required: true,
        readOnly: true, // make the input field read-only
        position:'relative',
        }}
    > 
    </StyledPhoneInput>
    <InputOverlay maxlength={10} value={phoneWithoutCountryCode} onChange={handleInputPhoneChange}/>
    <FontAwesomeIcon size='xl' icon={faSquarePhoneFlip} style={{color: "#48c778", position: 'absolute', top: '39px', left: '270px'}} />
  </div>
  )
}

export default PhoneField