import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import SubMenu from '../components/SubMenu';
import styled from 'styled-components';
import {getRestaurantClients} from '../actions/userActions'
import { Card, Button, Row, Col, Modal, CardText, InputGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPhone, faCopy, faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import TutorialSteps  from '../components/TutorialSteps';
import { getTutorialSteps } from '../actions/restaurantActions';

const SearchBar = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid grey;
`;

const SearchIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 10px;
  top: 15px;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledCard = styled(Card)`
  margin: 10px 0;
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
  border-radius: 5px;
  overflow: hidden;
`;

const CardTop = styled.div`
  padding: 10px;
  background-color: white;
  justify-content:center;
`;

const CardBottom = styled.div`
  padding: 10px;
  background-color: #8FEBC5;
`;

const CardBottomClients = styled.div`
  padding: 3px;
  background-color: #8FEBC5;
`;
const ShowInfo = styled.div`
  padding: 4px;
  margin:2px;
  font-size: 10px;
  background-color: ${props => props.bgColor || '#8FEBC5'};
  color:white;
  border-radius:3px;
`;

const StyledRow = styled(Row)`
  height: 100%;
  align-items: center;
  width: 100%;
  position:relative;
  margin-left:0;
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justify ? props.justify : "start"};
`;

const StyledColEnd = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const Styledh6 = styled.h6`
  font-size: 1rem;
  margin-left:3px;
  padding:2px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Smallh6 = styled.h6`
  font-size: 0.8rem;
  margin: 0;
`;

const CopyConfirm = styled.span`
    position: absolute;
    bottom: 0;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    &.show {
        bottom: 10px;
        opacity: 1;
    }
`;

function Separator({step, stepName}) {
    const [showStepsModal, setShowStepsModal] = useState(false);
    const [preSelectedTitle, setPreSelectedTitle] = useState(false);
    const dispatch = useDispatch();
    const [currentStep, setCurrentStep] = useState(stepName);
    const tutorial_steps_list = useSelector(state => state.tutorial_steps)
    const { tutorialSteps ,isLoadingTutorialSteps, tutorialStepsError } = tutorial_steps_list

    useEffect(() =>{
        dispatch(getTutorialSteps(currentStep));
    },[])
    const handleCloseStepsModal = () => {
        setShowStepsModal(false);
      };
      const handleOpenStepsModal = () => {
        setShowStepsModal(true);
      };
  return (
    <StyledCard className="p-0">
        
      <>
      <CardTop>
        <StyledRow>
        <StyledCol xs={6} md={6}>
            <Styledh6>{step}</Styledh6>
        </StyledCol>{/* 
        <StyledColEnd xs={6} md={6}>
            <FontAwesomeIcon onClick={handleOpenStepsModal} icon={faCircleQuestion} size="2xl" className='FaIcon' style={{color: "#41d279",}} />
        </StyledColEnd> */}
        </StyledRow>
      </CardTop>
      <CardBottom>
      </CardBottom>
      </>
      <TutorialSteps
      showModal={showStepsModal}
      step={currentStep}
      preSelectedTitle={preSelectedTitle}
      setShowModalFalse={handleCloseStepsModal} 
      />
    </StyledCard>
    
  )
}

export default Separator
