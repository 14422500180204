import React, { useEffect, useState, useRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import clickSound from '../sounds/click-sound.mp3';  // replace with your actual path to the audio file

const GlobalStyle = createGlobalStyle`
  .fade-up-enter {
    transform: translateY(100%);
  }

  .fade-up-enter-active {
    transform: translateY(0);
    transition: transform 250ms ease-in;
  }

  .fade-up-exit {
    transform: translateY(0);
  }

  .fade-up-exit-active {
    transform: translateY(-100%);
    transition: transform 250ms ease-in;
  }

  .fade-down-enter {
    transform: translateY(-100%);
  }

  .fade-down-enter-active {
    transform: translateY(0);
    transition: transform 250ms ease-in;
  }

  .fade-down-exit {
    transform: translateY(0);
  }

  .fade-down-exit-active {
    transform: translateY(100%);
    transition: transform 250ms ease-in;
  }GlobalStyle
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const StyledInput = styled.div`
  width: 70px;
  height: 25px;
  border-radius: 8px;
  border: solid 1px black;
  appearance: none;
  background: #fff;
  outline: none;
  font-size: 14px;
  text-align: center;
  margin: 0 3px;
  align-items: center;
  justify-content: center;
  display: flex;            
  align-items: center;      

  &:focus {
    border: 2px solid #007BFF;
  }

`;

const OptionContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const Option = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ArrowButton = styled.button`
  background: #40d69d;
  border-radius: 4px; 
  border: none;
  outline: none;
  font-size: 20px;
  cursor: pointer;
  color: #333;
  line-height: 1.3rem;
  transition: background 0.3s;  // transition for smooth color change
  
  &:hover {
    background: #45a049;  // darker green on hover
    color: white;
  }

  &:disabled {
    color: grey;
    background: #ccc;  // grey out background when disabled
  }
`;

const ScrollInputOptions = ({ currentOption, handleOptionsChange, options }) => {
    const [currentIndex, setCurrentIndex] = useState(null);
    const [direction, setDirection] = useState('down');
  
    const audio = new Audio(clickSound);
  
    useEffect(() => {
        setCurrentIndex(options.indexOf(currentOption))
        console.log('options: ', options)
        console.log('currentOption): ',currentOption)
        console.log('options.indexOf(currentOption): ', options.indexOf(currentOption))
    },[currentOption, options])
  
    const handleOptionChange = (increment) => {
      const newIndex = currentIndex + increment;
      console.log('currentIndex): ', currentIndex)
      console.log('newIndex): ', newIndex)
      console.log('increment): ', increment)
      if (newIndex < 0 || newIndex >= options.length) {
        return;
      }
      setDirection(increment > 0 ? 'up' : 'down');
      setCurrentIndex(newIndex);
        // Create a synthetic event object
      const syntheticEvent = {
        target: {
          value: options[newIndex]
        }
      };

      // Call handleOptionsChange with the synthetic event
      handleOptionsChange(options[newIndex]);
      // audio.play();
    };
  
    const handleWheel = (e) => {
/*       if (e.deltaY < 0) {
        if (currentIndex > 0) {
          e.preventDefault();
          handleOptionChange(-1);
        }
      } else {
        if (currentIndex < options.length - 1) {
          e.preventDefault();
          handleOptionChange(1);
        }
      } */
    };
  
    return (
      <>
        <GlobalStyle />
        <Container>
          <ArrowButton 
            onClick={(e) => {e.preventDefault(); handleOptionChange(-1);}} 
            onTouchEnd={(e) => {e.preventDefault(); handleOptionChange(-1);}} 
            disabled={currentIndex === 0}
          >
            {'-'}
          </ArrowButton>
          <StyledInput 
            onWheel={handleWheel} 
          >
            <OptionContainer>
              <TransitionGroup>
                <CSSTransition
                  key={currentIndex}
                  timeout={500}
                  classNames={`fade-${direction}`}
                >
                  <Option>
                    {options[currentIndex]}
                  </Option>
                </CSSTransition>
              </TransitionGroup>
            </OptionContainer>
          </StyledInput>
          <ArrowButton 
            onClick={(e) => {e.preventDefault(); handleOptionChange(1);}} 
            onTouchEnd={(e) => {e.preventDefault(); handleOptionChange(1);}} 
            disabled={currentIndex === options.length - 1}
          >
            {'+'}
          </ArrowButton>
        </Container>
      </>
    );
  };
  
export default ScrollInputOptions;