import { configureStore } from '@reduxjs/toolkit'
import counterReducer from './features/counter/counterSlice'
import  restaurantListReducer, {restaurantDetailsSlice, reservationsFromRestaurantSlice,
        reservationAcceptSlice, availabeTablesToChangeSlice, changeTablesSlice, tablesSlice,
        restaurantOpenDaysOfTheWeekSlice, estimatedTimeCustomersSpendSlice, closedExceptionsSlice,
        fetchHoursEachTimeSlice, placeOfTableSlice, fetchConnectTablesSlice, create_confirmationSlice,
        fetchAllExistingShiftsSlice, getReservationSumsSlice, customerAttendUpdateSlice, getTutorialStepsSlice,
        createRestaurantSlice, destroyItemSlice, restaurantNotificationsSlice, restaurantCreatePaymentIntentSlice,
        filtersSlice} from './reducers/restaurantReducers'
import { userUpdateReducerSlice,userLoginReducerSlice, userRegisterReducerSlice, fetchUserSubscriptionSlice,
         storeUserSubscriptionSlice, getRestaurantSubscriptionSlice, getRestaurantClientsSlice, fiscalAdressCrudSlice, 
         getSubscriptionPackagesSlice, whatsappSubscriptionsSlice } from './reducers/userReducers'
import { reservationCustomersMenuSlice ,reservationBlockedDaysSlice, reservationBlockedWeekdaysSlice, 
        reservationShiftSlice, openTimeSlice, createReservationSlice, cancelReservationSlice, editReservationSlice,
        reservationActiveSlice, reservationLimitPeopleBabySlice,  } from './reducers/reservationReducers'
import { crudManagerPermissionsSlice } from './reducers/managersReducers'

export const store = configureStore({
  reducer: {
    counter:counterReducer,
    restaurantList:restaurantListReducer,
    restaurantDetails:restaurantDetailsSlice.reducer,
    userLogin:userLoginReducerSlice.reducer,
    userRegister:userRegisterReducerSlice.reducer,
    userUpdate:userUpdateReducerSlice.reducer,
    reservationsFromRestaurant:reservationsFromRestaurantSlice.reducer,
    reservationBlockedDays:reservationBlockedDaysSlice.reducer,
    reservationBlockedWeekDays:reservationBlockedWeekdaysSlice.reducer,
    reservationShift:reservationShiftSlice.reducer,
    openTime:openTimeSlice.reducer,
    createReservation:createReservationSlice.reducer,
    cancelReservation:cancelReservationSlice.reducer,
    editReservation:editReservationSlice.reducer,
    reservationCustomersMenu:reservationCustomersMenuSlice.reducer,
    reservationActive:reservationActiveSlice.reducer,
    reservationLimitsPeopleBaby:reservationLimitPeopleBabySlice.reducer,
    reservationAccept:reservationAcceptSlice.reducer,
    availableTablesToChange:availabeTablesToChangeSlice.reducer,
    changedTables:changeTablesSlice.reducer,
    restaurantOpenDaysOfTheWeek: restaurantOpenDaysOfTheWeekSlice.reducer,
    estimatedTimeCustomersSpend: estimatedTimeCustomersSpendSlice.reducer,
    closedExceptions: closedExceptionsSlice.reducer,
    hoursEachTime:fetchHoursEachTimeSlice.reducer,
    restaurantZones:placeOfTableSlice.reducer,
    restaurantTables:tablesSlice.reducer,
    connectTablesConfig:fetchConnectTablesSlice.reducer,
    createConfirmation:create_confirmationSlice.reducer,
    allExistingShifts:fetchAllExistingShiftsSlice.reducer,
    userSubscription:fetchUserSubscriptionSlice.reducer,
    storeUserSubscription:storeUserSubscriptionSlice.reducer,
    reservationSums:getReservationSumsSlice.reducer,
    customerAttendUpdate: customerAttendUpdateSlice.reducer,
    restaurant_subscription: getRestaurantSubscriptionSlice.reducer,
    tutorial_steps: getTutorialStepsSlice.reducer,
    create_a_restaurant:createRestaurantSlice.reducer,
    destroy_Item:destroyItemSlice.reducer,
    restaurant_clients:getRestaurantClientsSlice.reducer,
    crudManager:crudManagerPermissionsSlice.reducer,
    restaurantNotifications: restaurantNotificationsSlice.reducer,
    fiscalAddressStore:fiscalAdressCrudSlice.reducer,
    subscriptionPackagesStore:getSubscriptionPackagesSlice.reducer,
    paymentIntent:restaurantCreatePaymentIntentSlice.reducer,
    whatsappSubscriptions:whatsappSubscriptionsSlice.reducer,
    filtersModels:filtersSlice.reducer,
  },
})