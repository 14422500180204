import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import Loader from '../components/Loader';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faUserGroup, faFloppyDisk } from '@fortawesome/free-solid-svg-icons'


function cleanList(list) {
  return list.map(sublist => {
    return sublist.map(item => {
      const splitItem = item.split(';');
      return { number: splitItem[0], status: splitItem[1] };
    });
  });
}
const StyledModalHeader = styled(Modal.Header)`
  padding: 15px;
  width: 100%;
`;

const StyledModalBody = styled(Modal.Body)`
    overflow-y: auto;
    max-height: 400px;
`;

const StyledTableDiv = styled.div`
    border: 1px solid black;
    border-radius: 4px;
    padding: 4px 8px;
    margin: 4px;
    cursor: pointer;
    background-color: ${({ isSelected, tableStatus }) => 
        isSelected 
            ? (tableStatus === '1' ? 'yellow' : 'lightblue')
            : (tableStatus === '1' ? '#f57b42' : 'white')
    };
    color:${({ isSelected, tableStatus }) => 
    isSelected 
        ? (tableStatus === '1' ? 'black' : 'black')
        : (tableStatus === '1' ? 'white' : 'black')
  };
`;

const StyledH6 = styled.h6`
    color: ${({ enoughSeats }) => 
    enoughSeats ? 'green' : 'red'
    };
`;


const ZoneContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const TablesModal = ({ show, tables_list, onClose, onSave, initialSelectedTables, token, id, numPeople, handleTablesUsedChange, setSelectedTablesSave, setPlaceOfTable }) => {

    const tables = cleanList(tables_list);
    const all_tables = useSelector(state => state.restaurantTables.tables);
    const connectTablesConfig = useSelector(state => state.connectTablesConfig.connectTablesConfig);
    const [selectedTables, setSelectedTables] = useState({});
    const [enoughSeats, setEnoughSeats] = useState(false);
    const [countPeople, setCountPeople] = useState(0);
    const [tablesConfigList, setTablesConfigList] = useState([]);

    useEffect(() => {
        setSelectedTables(initialSelectedTables);
    }, [initialSelectedTables]);
 
    useEffect(() => {
      if (connectTablesConfig) {
        let table_config = [];
        for (let key in connectTablesConfig) {
          table_config.push(connectTablesConfig[key].number_of_chairs);
         }
         setTablesConfigList(table_config);
      }
  }, [connectTablesConfig]);

  useEffect(() => {
    if (all_tables) {
        // 1. Retrieve all the table numbers from selectedTables
        let selectedTableNumbers = [];
        for (let key in selectedTables) {
            selectedTables[key].forEach(table => {
                selectedTableNumbers.push(table.number);
            });
        }

        if (typeof handleTablesUsedChange === 'function') {
          handleTablesUsedChange(selectedTableNumbers);
        } 

        // 2. Filter out the tables from all_tables using the retrieved table numbers
        const filteredTables = all_tables.filter(table => selectedTableNumbers.includes(table.id.toString()));

        // 3. Sum the number of seats of each filtered table
        const totalSeats = filteredTables.reduce((acc, table) => acc + parseInt(table.number_of_seats), 0);

        // Logic for summing values from tablesConfigList
        let numOfTables = filteredTables.length;
        let configSum = 0;
        if (numOfTables >= 2) configSum += tablesConfigList[0];
        if (numOfTables >= 3) configSum += tablesConfigList[1];
        if (numOfTables >= 4) configSum += tablesConfigList[2];
        for(let i = 5; i <= numOfTables; i++) {
            configSum += tablesConfigList[((i + 1) % 2) + 1];
        }

        if (totalSeats + configSum >= numPeople) {
            setEnoughSeats(true);
        } else {
            setEnoughSeats(false);
        }
        setCountPeople(totalSeats + configSum);
    }
}, [selectedTables, all_tables]);

    useEffect(() => {
      if (typeof setSelectedTablesSave === 'function') {
        setSelectedTablesSave(selectedTables);
      } 
    }, [selectedTables, setSelectedTablesSave]);

    const handleTableSelect = (table, zone) => {
      const previousZone = Object.keys(selectedTables)[0];
      if (previousZone && previousZone !== zone) {
        setSelectedTables({
          [zone]: [{ number: table.number, status: table.status }],
        });
        setPlaceOfTable(zone);
      } else {
        if (selectedTables[zone]?.some(t => t.number === table.number)) {
          setSelectedTables({
            ...selectedTables,
            [zone]: selectedTables[zone].filter((t) => t.number !== table.number),
          });
        } else {
          setSelectedTables({
            ...selectedTables,
            [zone]: selectedTables[zone] 
              ? [...selectedTables[zone], { number: table.number, status: table.status }] 
              : [{ number: table.number, status: table.status }],
          });
        }
      }
    };

    return (
        <Modal show={show} onHide={onClose} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' ,top: '12%'}}>
            <StyledModalHeader  closeButton>
              <Row style={{width: '100%'}}>
                <Col xs={12} className='d-flex justify-content-center'>
                  <Modal.Title>Mudar mesas</Modal.Title>
                </Col>
                <Col className='d-flex justify-content-center' xs={12} style={{marginTop: '5px'}}>
                 <StyledH6
                  enoughSeats={enoughSeats}
                 > 
                   <FontAwesomeIcon 
                      icon={faUserGroup} 
                      style={ enoughSeats ? {color: "green", marginRight: '10px' } : {color: "red", marginRight: '10px'}}
                  /> 
                    {countPeople}  / {numPeople}
                  </StyledH6> 
                </Col>

              </Row>
                
            </StyledModalHeader>
            <StyledModalBody>
                {tables &&  all_tables?
                    <div>
                        {tables.map((zoneTables) => (
                            <div key={zoneTables[0].number}>
                                <h5>{zoneTables[0].number}</h5>
                                <ZoneContainer>
                                    {zoneTables.slice(1).map((table) => {
                                        const isSelected = selectedTables[zoneTables[0].number]?.some(t => t.number === table.number);
                                        const the_table = all_tables.find(tables => tables.id.toString() === table.number);
                                        return (
                                            <StyledTableDiv
                                                key={table.number}
                                                isSelected={isSelected}
                                                tableStatus={table.status}
                                                onClick={() => handleTableSelect(table, zoneTables[0].number)}
                                            >
                                                {the_table && the_table.table_name}
                                            </StyledTableDiv>
                                        );
                                    })}
                                </ZoneContainer>
                            </div>
                        ))}
                    </div>
                    :
                    <Loader />
                }
            </StyledModalBody>
            <Modal.Footer>
                
                {token != '' ?
                <>
                <Button
                    variant="primary"
                    onClick={() => {
                        onSave(selectedTables, token, id);
                        onClose();
                    }}
                  >
                      Guardar <FontAwesomeIcon icon={faFloppyDisk} style={{color: "#ffffff", marginLeft: '4px'}} />
                  </Button>
                  <Button variant="secondary" onClick={onClose}>
                    Fechar x
                  </Button>
                </>
                :
                  <Button variant="primary" onClick={onClose} style={{color: "#ffffff", marginLeft: '4px'}}>
                    Escolher
                  </Button>
                }
                
            </Modal.Footer>
        </Modal>
    );
};

export default TablesModal;
