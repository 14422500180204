import { memo, useEffect, useState } from 'react'
import { Table }  from './Table.js'
const styles = {
  display: 'inline-block',
  // transform: 'rotate(-7deg)',
  // WebkitTransform: 'rotate(-7deg)',
}
export const TableDragPreview = memo(function TableDragPreview({ title, id,  table_name, item, selectedItem }) {
  const [tickTock, setTickTock] = useState(false)
  useEffect(
    function subscribeToIntervalTick() {
      const interval = setInterval(() => setTickTock(!tickTock), 500)
      return () => clearInterval(interval)
    },
    [tickTock],
  )
  return (
    <div style={styles}>
        <Table table_name={table_name} title={title} id={id} yellow={tickTock} selectedItem={selectedItem} item={item} preview/>
    </div>
  )
})
