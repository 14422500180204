import React, { useEffect, useState, useRef } from 'react';
import { Card, Button,  Row, Col, Modal } from 'react-bootstrap';
import { Link, redirect, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getTutorialSteps, fetchRestaurantDetails, updateRestaurant, createRestaurant } from '../actions/restaurantActions';
import Loader from '../components/Loader'
import Messages from '../components/Messages'
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowRight, faCircleArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons';
import TutorialSteps  from '../components/TutorialSteps';
import AutoFillAddress from '../components/AutoFillAddress'; // Adjust the import path
import FiscalAdress from './FiscalAdress'
import DropDownButton from '../components/DropDownButton'
import StripePay from '../components/StripePay';
import PhoneField from '../components/PhoneField';
import { setISODay } from 'date-fns';
import { GoogleMap, Marker, useLoadScript, useJsApiLoader  } from '@react-google-maps/api';
import { parsePhoneNumberWithError } from 'libphonenumber-js';
/* global google */

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding:0;
`;

const SubContainer = styled.div`
  display:flex;
  items-align:center;
  justify-content:center;
  width: 100%;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top:10px;
  gap:10px;
`;

const StyledCard = styled(Card)`
  margin: 10px 0;
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
  border-radius: 5px;
  overflow: hidden;
`;

const CardTop = styled.div`
  padding: 10px;
  background-color: white;
  justify-content:center;
`;

const CardBottom = styled.div`
  padding: 10px;
  background-color: #8FEBC5;
`;

const StyledRow = styled(Row)`
  height: 100%;
  align-items: center;
  width: 100%;
  position:relative;
  margin-left:0;
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justify ? props.justify : "start"};
`;

const StyledColForIcons = styled(Col)`
  align-items: start;
  position:relative;
  justify-content:start;
`;

const Styledh6 = styled.h6`
  font-size: .8rem;
  margin-left:3px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Smallh6 = styled.h6`
  font-size: 0.8rem;
  margin: 0;
`;

const StyledIconText = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px; 
  position:absolute;
  top:3px;
  left:2rem;
`;

const TutorialBox = styled.div`
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  padding:30px;
  padding-bottom:75px;
  width: 80%;
  height: auto;
  min-height: 300px;
  max-width: 600px;
  background-color: #3ec261;
  color:white;
  border-radius: 25px;
  position:relative;
`;
const TutorialBoxText = styled.div`
  font-size:25px;
  color:white;
  width:100%;
  height:80px;
  border-bottom: 1px solid white;
`;

const TutorialBoxMiddleSteps = styled.div`
  position:absolute;
  bottom:20px;
  font-size:20px;
  color:white;
  width:20%px;
`;

const StyledInput = styled.input`
  margin:10px;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.1);
  width: 250px;
  outline: none;
  transition: box-shadow 0.3s ease;
  height:40px;

  &:focus {
    box-shadow: 0px 0px 10px 4px rgba(0,0,0,0.2);
  }
`;

const StyledArrowRight = styled(FontAwesomeIcon)`
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: white;
  cursor: pointer;
  font-size: 2.4em; 
`;

const CreateButtom = styled.div`
  padding:8px;
  display:flex;
  align-items:center;
  justify-content:center;
  border-radius:6px;
  border: 3px solid #55e6c9;
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: white;
  cursor: pointer;
  font-size:1.2em; 
  width:auto;
  height:40px;
  color:black;
  background-color:white;
  &:hover { 
    background-color: #55e6c9;
  }
`;
const StyledArrowLeft = styled(FontAwesomeIcon)`
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
  cursor: pointer;
  font-size: 2.4em; 
`;


const getTimezone = () => {
  if (window.Intl && window.Intl.DateTimeFormat) {
      const options = new window.Intl.DateTimeFormat().resolvedOptions();
      return options && options.timeZone ? options.timeZone : '';
  }
  return '';
}


function TutorialScreen() {
  
  const params = useParams();
  const [currentStep, setCurrentStep] = useState('name');
  const [stepCounter, setStepCounter] = useState(1);
  const [restaurant_id, setRestaurant_id] = useState(params.restaurant_id === 'null'  ? false : params.restaurant_id);
  const [maxSteps, setMaxSteps] = useState(3);
  const [showStepsModal, setShowStepsModal] = useState(params.tutorial === 'yes' ? true : false);
  const [preSelectedTitle, setPreSelectedTitle] = useState(false);
  const [numbers, setNumbers] = useState(Array.from({ length: 301 }, (_, index) => index));

  const [showNextButton, setShowNextButton] = useState(true);
  const [showBackButton, setShowBackButton] = useState(true);
  const [restaurantCreated, setRestaurantCreated] = useState(false);

  const tutorial_steps_list = useSelector(state => state.tutorial_steps)
  const { tutorialSteps ,isLoadingTutorialSteps, tutorialStepsError } = tutorial_steps_list
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const created_restaurant = useSelector(state => state.create_a_restaurant)
  const { createRestaurant: myCustomCreateRestaurant, isLoadingCreateRestaurant, createRestaurantError } = created_restaurant;

  const [restaurantName, setRestaurantName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [country, setCountry] = useState('');
  const [postal_code, setPostal_code] = useState('');
  const [longitudeField, setLongitudeField] = useState(false);
  const [latitudeField, setLatitudeField] = useState(false);
  const [address, setAddress] = useState('');
  const [timezone, setTimezone] = useState(getTimezone());
  const [numberOfChairs, setNumberOfChairs] = useState(0);
  const [numberOfBabyChairs, setNumberOfBabyChairs] = useState('');
  const [limitPeopleReservation, setLimitPeopleReservation] = useState('');
  const [limitBabyChairsReservation, setLimitBabyChairsReservation] = useState('');
  const [autoAcceptLimit, setAutoAcceptLimit] = useState('');
  const [autoAcceptTimeLimit, setAutoAcceptTimeLimit] = useState('');
  const [eachTime, setEachTime] = useState('00:15');
  const [acceptPeoplePerEachTime, setAcceptPeoplePerEachTime] = useState('');
  const [fiscalAdressId, setFiscalAdressId] = useState('');
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isCreateRestaurant, setIsCreateRestaurant] = useState(false);
  

  const [price, setPrice] = useState(false);
  const [packageName, setPackageName] = useState(false);

  const [countryCodeNumber, setCountryCodeNumber] = useState('351');
  const [phoneWithoutCountryCode, setPhoneWithoutCountryCode] = useState('');
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  

  const [selectedPlace, setSelectedPlace] = useState(null);

  const { isLoaded: isGoogleLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyA7wQgzKIx0-y6j3pfy341jX6LmYq5_Sc4"
  });

  const [marker, setMarker] = useState(false);

  useEffect(() => {
    if (postal_code && isGoogleLoaded) {
      console.log('blyat');
      // Use Google Geocoding API to fetch coordinates
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: postal_code }, (results, status) => {
        if (status === 'OK') {
          const location = results[0].geometry.location;
          setLatitudeField(location.lat());
          setLongitudeField(location.lng());
          setMarker({
            lat:location.lat(),
            lng:location.lng(),
          })
           // Extracting the formatted address
            const formattedAddress = results[0].formatted_address;
            // Extracting the country from the address components
            const addressComponents = results[0].address_components;
            const countryComponent = addressComponents.find(component => component.types.includes('country'));
            if (countryComponent) {
              const country = countryComponent.long_name;  // Use 'short_name' for the country code
              setCountry(country);  // Assuming setCountry sets the country name or code
            }
        } else {
          console.error('Geocode was not successful for the following reason:', status);
        }
      }); 
    }
  }, [postal_code]);

  const handleOnLoad = (map) => {
    // The google object is available here
    if (isGoogleLoaded) {
      google.maps.event.trigger(map, "resize");
    }
    
  };

  const onMapClick = React.useCallback((event) => {
    setMarker({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
    setLatitudeField(event.latLng.lat());
    setLongitudeField(event.latLng.lng());
  }, []);

  const generateIntervalOptions = () => {
    const intervalOptions = [];
    for (let i = 0; i < 24; i++) {
        for (let j = 0; j < 60; j += 15) {
            // Format the hour and minute values to have two digits
            const hour = i.toString().padStart(2, '0');
            const minute = j.toString().padStart(2, '0');
            const time = `${hour}:${minute}`;

            if (time >= '00:15' && time <= '08:00') {
                intervalOptions.push(time);
            }
        }
    }
    return intervalOptions;
};

// Usage:
const [intervalOptions, setIntervalOptions] = useState(generateIntervalOptions());
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addressInputRef = useRef('');

  const restaurantDetails = useSelector(state => state.restaurantDetails);
  const { restaurants , isLoading, error} = restaurantDetails;

  const paymentIntentInfo = useSelector(state => state.paymentIntent)
  const {  paymentIntent, isLoadingPaymentIntent,  paymentIntentError} = paymentIntentInfo

  useEffect(() => {
    if(paymentIntent) {
      setPrice(paymentIntent.value);
      setPackageName(paymentIntent.package);
    }
  },[paymentIntent])

  useEffect(() => {
    dispatch(createRestaurant('', true));
  },[])

  
  useEffect(() => {
    // when it first loads the page and every time 
    // it changes steps this useEffect will run
    console.log('showStepsModal: ',showStepsModal)
    dispatch(getTutorialSteps(currentStep));
    if (params.tutorial === 'yes') {
      setShowStepsModal(true);
    }
    if (stepCounter === 1) {
      setShowBackButton(false);
      setShowNextButton(true);
    } else if ( stepCounter >= 2 && stepCounter <= 2 ) {
      setShowBackButton(true);
      setShowNextButton(true);
    } else if ( stepCounter === 3 ) {
      setShowBackButton(true);
      setShowNextButton(false);
    }
  },[currentStep])

  useEffect(() => {
    if (params.restaurant_id !== 'null') {
      dispatch(fetchRestaurantDetails(restaurant_id));
    }
  },[dispatch])

  useEffect(() => {
    console.log('stepCounter: ', stepCounter)
    if (restaurant_id && currentStep === 'estimated_time_per_reservation') {
      const restaurant_params = {
        tutorial_step:'complete',
      };
      dispatch(updateRestaurant(restaurant_id, restaurant_params));
    } else if (restaurant_id) {
      const restaurant_params = {
        tutorial_step:currentStep,
      };
      dispatch(updateRestaurant(restaurant_id, restaurant_params));
    }
  },[stepCounter]);

  useEffect(() => {
    if (restaurants && params.restaurant_id !== 'null') {
        let newStepCounter;
        switch (currentStep) {
          case 'name':
            newStepCounter = 1;
            break;
          case 'adress':
            newStepCounter = 2;
            break;
          case 3:
            setCurrentStep('fiscal_address');
            break;
          case 4:
            setCurrentStep('payment')
            break;
          default:
            newStepCounter = 1;
        }
        setStepCounter(newStepCounter);
      };
  },[restaurants]);

  const handleCreateRestaurant = () => {
    setRestaurantCreated(true);
    console.log('handleCreateRestaurant')
  };

  const handleNextSteps = () => {
    let newStepCounter = stepCounter + 1;
    if (newStepCounter == 2) {
      let errors = [];
      if (restaurantName === '') {
        errors.push('Nome')
      }
      if (!isPhoneValid) {
        errors.push('Telémovel')
      }
      if (errors.length > 0) {
        setErrorMessage('Os seguintes campos estão imcompletos: ' + errors.join(', '));
        return;
      } else {
        setErrorMessage('');
      }
    } else if (newStepCounter == 3 && (address === '' || country === '' || postal_code === '')) {
      setErrorMessage('*Preencher campos em falta.');
      return;
    } else if (newStepCounter == 4 && (fiscalAdressId === '')) {
      setErrorMessage('*Preencher campos em falta.');
      return;
    } else {
      setErrorMessage('');
    }
    setStepCounter(newStepCounter); 
    switch (newStepCounter) {
      case 0:
        setCurrentStep('name');
        break;
      case 1:
        setCurrentStep('name');
        break;
      case 2:
        setCurrentStep('adress');
        break;
      case 3:
        setCurrentStep('fiscal_address');
        break;
      case 4:
        setCurrentStep('payment')
        break;
      default:
        setCurrentStep('name');
    }
    
  };

  const handleGoBackSteps = () => {
    let newStepCounter = stepCounter - 1;
    setStepCounter(newStepCounter);
  
    switch (newStepCounter) {
      case 0:
        setCurrentStep('name');
        break;
      case 1:
        setCurrentStep('name');
        break;
      case 2:
        setCurrentStep('adress');
        break;
      case 3:
        setCurrentStep('fiscal_address');
        break;
      case 4:
        setCurrentStep('payment')
        break;
      default:
        setCurrentStep('name');
    }
  };

  function checkEmptyStates() {
    let emptyStates = [];
  
    if (restaurantName === '') {
      emptyStates.push('Nome do restaurante');
    }
    if (address === '') {
      emptyStates.push('Morada');
    }
    if (fiscalAdressId === '') {
      emptyStates.push('Cada tempo');
    }
    if (!isPhoneValid) {
      emptyStates.push('Numero invalido.');
    }
    if (emptyStates.length > 0) {
      return 'Os seguintes campos não estão corretamente preenchidos: ' + emptyStates.join(', ');
      
    } else {
      return 'ok_status';
    }
  }

  function checkForErrorsAndCreateRestaurant() {
    console.log('hereee in checkForErrorsAndCreateRestaurant')
    let status_error = checkEmptyStates();
    setErrorMessage(status_error);
    if (status_error === 'ok_status') {
      setShowError(false);
      const updatedRestaurantParams = {
        restaurant_name: restaurantName,
        phone_number:phoneWithoutCountryCode,
        country_code: countryCodeNumber,
        postal_code:postal_code,
        latitude:latitudeField,
        longitude:longitudeField,
        adress: address,
        country:country,
        postal_code:postal_code,
        fiscal_information:fiscalAdressId,
        timezone:timezone,
      };
      dispatch(createRestaurant(updatedRestaurantParams));
      setIsCreateRestaurant(true);
    } else {
    }
}

useEffect(() => {
  console.log('myCustomCreateRestaurant: ', myCustomCreateRestaurant)
  if (myCustomCreateRestaurant && isCreateRestaurant === true) {
    navigate(`/choose_plan_and_pay/${myCustomCreateRestaurant.id}`);
  }
},[myCustomCreateRestaurant, isLoadingCreateRestaurant]);
  const handleCloseStepsModal = () => {
    setShowStepsModal(false);
  };

  const handleOpenStepsModal = () => {
    setShowStepsModal(true);
  };

  const handleAddressChange = (addressValue) => {
    setAddress(addressValue);
  };
  
  const handleCountryChange = (countryValue) => {
    setCountry(countryValue);
  };
  
  const handlePostalCodeChange = (postalCodeValue) => {
    setPostal_code(postalCodeValue);
  };
  
  const handlePlaceSelected = (place) => {
    setSelectedPlace(place);
  };
  
  const handleTimeZoneSelected = (tz) => {
    setTimezone(tz);
  };

  return (
    <Container>
      <SubContainer>
      <TutorialBox>
      {currentStep === 'name'?
      <>
          <TutorialBoxText>
            Qual o nome do seu restaurante?
          </TutorialBoxText>
          <StyledInput type='text' style={{ display: 'none' }} placeholder="Nome do restaurante"/>
          <StyledInput type='text' value={restaurantName} placeholder="Nome do restaurante" onChange={(e) => setRestaurantName(e.target.value)} />
          <PhoneField
            countryCodeNumber={countryCodeNumber}
            setCountryCodeNumber={setCountryCodeNumber}
            phoneWithoutCountryCode={phoneWithoutCountryCode}
            setPhoneWithoutCountryCode={setPhoneWithoutCountryCode}
            isPhoneValid={isPhoneValid}
            setIsPhoneValid={setIsPhoneValid}
          />
          
      </>
      :currentStep === 'adress'?
      <>
        <TutorialBoxText>
          Preencher morada do restaurante:
        </TutorialBoxText>
        <StyledInput type='text' value={postal_code} onChange={(e) => setPostal_code(e.target.value)} placeholder="código postal" />
        {longitudeField && 
        <>
        {isGoogleLoaded &&
                    <GoogleMap
                    mapContainerClassName="map-container"
                    onClick={onMapClick}
                    onLoad={handleOnLoad}
                    center={{ lat: latitudeField, lng: longitudeField }}
                    zoom={18}
                    options={{
                      streetViewControl: false, 
                    }}
                    >
                      <Marker 
                        position={{ lat: marker.lat, lng: marker.lng }}
                        draggable={true}
                        onDragEnd={onMapClick}
                      />
                    </GoogleMap>
                  
        }
        <StyledInput type='text' value={country} onChange={(e) => setCountry(e.target.value)} placeholder="País" />
        <StyledInput type='text' ref={addressInputRef}  value={address} onChange={(e) => setAddress(e.target.value)} placeholder="Morada" autoComplete="nope"  />

        </>
        }
      </>
      :currentStep === 'fiscal_address'?
        <>
        <TutorialBoxText>
          Escolher endereço fiscal:
        </TutorialBoxText>
        <FiscalAdress
          isItSelectable={true}
          currentSelected={fiscalAdressId}
          setSelected={(value) => setFiscalAdressId(value)}
        />
        </>
      : currentStep === 'payment' ?
        <>
        <TutorialBoxText>
          {packageName}
          <div style={{position:'absolute', top:'32px',right:'20px', color:'white'}}>
            {price}€
          </div>
        </TutorialBoxText>
        <div style={{width:'100%', height:'100%'}}>
          <StripePay
            restaurant_id={'1'}
            subscription_number={1}
            payment_method={'stripe'}
          />
        </div>
          
        </>
      :
        <Loader/>
      }
      {errorMessage !== '' && <Row style={{width:'100%'}}><Col xs={12}><p>{errorMessage}</p></Col></Row>}

      {showBackButton && <StyledArrowLeft icon={faCircleArrowLeft} onClick={handleGoBackSteps}/>}
      {showNextButton && <StyledArrowRight icon={faCircleArrowRight} onClick={handleNextSteps}/>}
      {stepCounter === 3 && 
        <CreateButtom onClick={checkForErrorsAndCreateRestaurant}> 
          create 
          <FontAwesomeIcon size='md' icon={faPlus} style={{marginLeft:'5px'}}/>
        </CreateButtom>
      }
      
        <TutorialBoxMiddleSteps>
            Passo {stepCounter} / {maxSteps}
        </TutorialBoxMiddleSteps>
      </TutorialBox>
      </SubContainer>
    
      <TutorialSteps
      showModal={showStepsModal}
      step={currentStep}
      preSelectedTitle={preSelectedTitle}
      setShowModalFalse={handleCloseStepsModal} 
      />
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Esolher endereço</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}

export default TutorialScreen