// PaymentForm.js
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {confirmSubscription, resetConfirmPaymentIntent} from '../actions/restaurantActions'

const stripePromise = loadStripe('pk_test_51OHTqdGwmqMQdn7MI69vIxvTMioVBA1EdYU78d6peZcznJt6r0X2Ck0cId43DCnOsX3whaGRGreLy6IhQsn7seGE00yaWQTNDf');

const CheckoutForm = ({subscription_number, restaurant_id}) => {
  const stripe = useStripe();
  const dispatch = useDispatch();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable form submission in this case.
      return;
    }
    const cardElement = elements.getElement(CardElement);
    const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });

    if (error) {
      console.error(error.message);
      setErrorMessage(error.message);
    } else {
      // Send the token to the server
      dispatch(confirmSubscription(restaurant_id, subscription_number, paymentMethod.id, true));
    }
  };

  const sendTokenToServer = async (token) => {
    try {
      const response = await fetch('http://yourbackend.com/create-payment-method/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: token.id,
        }),
      });

      const data = await response.json();

      if (data.payment_method_id) {
        // Payment method created successfully
        console.log('Payment Method ID:', data.payment_method_id);
      } else {
        console.error('Error creating payment method:', data.error);
        setErrorMessage(data.error);
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('An error occurred. Please try again.');
    }
  };
  const cardElementOptions = {
    style: {
      base: {
        fontSize: '17px',
        fontFamily: '"Kanit", sans-serif',
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };

  return (
    <form onSubmit={handleSubmit} style={{ maxWidth: '600px', margin: 'auto', padding: '20px', backgroundColor: '#f5f5f5', borderRadius: '10px', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
        <div  className='mb-3' style={{fontSize:'25px', fontFamily:'\'Kanit\', sans-serif',fontWeight:'bold' }}>StripePay</div>
      <CardElement  options={cardElementOptions} />
      <button
        type="submit"
        disabled={!stripe}
        
        className='btn btn-success mt-3'
      >
        Guardar
      </button>
      {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
    </form>
  );
};

const StripeCreatePayMethod = ({subscription_number, restaurant_id}) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm 
    restaurant_id={restaurant_id}
    subscription_number={subscription_number}
    />
  </Elements>
);

export default StripeCreatePayMethod;
