import React, { useEffect, useState } from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSubscriptionPackages } from '../actions/userActions';
import { Link, redirect, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import StripePay from '../components/StripePay';
import StripeCreatePayMethod from '../components/StripeCreatePayMethod'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleLeft } from '@fortawesome/free-solid-svg-icons';
import {resetConfirmSubscriptiontIntent, resetCreatePaymentIntent, resetConfirmSubscription} from '../actions/restaurantActions'
import Loader from '../components/Loader';
import LoaderComponent from '../components/LoaderComponent';

const Container = styled.div`
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  width: 100%;
  margin: 0 auto;
  padding:0;
`;

const CardTop = styled.div`
  height:auto;
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  width: 100%;
  background-color:#57b57b;
  padding:0px;
  color:white;
  font-size:30px;
`;

const ButtonPlans = styled.div`
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  height:auto;
  width:auto;
  padding:5px;
  padding-right:10px;
  padding-left:10px;
  background-color:${props => props.isSelected ? '#57b57b' : "white"};
  border:${props => props.isSelected ? '1px solid black' : "1px solid gray"};
  color:${props => props.isSelected ? 'white' : "gray"};
  cursor:pointer;
  border-radius:8px;
  font-size:20px;
  &:hover {
    background-color: #57b57b;
    color:white;
  }
`;

const StyledCard = styled(Card)`
  justify-content:center;
  border-radius:10px;
  border:1px solid black;
  padding:10px;
  text-align:center;
  overflow:hidden;
  padding:0;
`;

const MessagesSeparator = styled.div`
  display:flex;
  justify-content:center;
  border-top:1px solid gray;
  width:100%;
  padding-top:5px;
  text-align:center;
  margin-top:5px;
  font-size:18px;
  font-weight:bold;
`;

const ValueDiv = styled.div`
  position:relative;
  right:-36%;
  bottom:20px;
  display:flex;
  justify-content:center;
  text-align:center;
  background-color:#57b57b;
  color:white;
  width:100%;
  text-align:center;
  margin-top:5px;
  font-size:22px;
  transform: rotate(-36deg);
`;



function ChoosePlanAndPay() {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const subpacks = useSelector(state => state.subscriptionPackagesStore);
  const {
    getSubscriptionPackages,
    isLoadingGetSubscriptionPackages
  } = subpacks;

  const paymentIntentInfo = useSelector(state => state.paymentIntent)
  const { isLoadingConfirmSubscription ,confirmSubscription, paymentIntent, isLoadingPaymentIntent,  paymentIntentError} = paymentIntentInfo

  const confirmPay = useSelector(state => state.paymentIntent)
  const {  confirmPayment, isLoadingConfirmPayment,  confirmPaymentError} = confirmPay


  const [monthlySubscriptions, setMonthlySubscriptions] = useState([]);
  const [yearlySubscriptions, setYearlySubscriptions] = useState([]);
  const [showMonthly, setShowMonthly] = useState(true);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [step, setStep] = useState('choose_subscription');


  useEffect(() => {
    if (confirmSubscription && confirmSubscription.message === 'success') {
      navigate(`/reservationfromrestaurant/${params.restaurant_id}/null/all`);
    }
  },[confirmSubscription])

  useEffect(() => {
    if (step === 'choose_subscription') {
      dispatch(resetCreatePaymentIntent());
    }
  },[step]);

  useEffect(() => {
    dispatch(fetchSubscriptionPackages());
    dispatch(resetConfirmSubscription());
  }, [dispatch]);



  useEffect(() => {
    if (getSubscriptionPackages) {
      const monthly = getSubscriptionPackages
        .filter(sub => sub.number_of_days === 30)
        .sort((a, b) => a.subscription_number - b.subscription_number); // Sorting monthly subscriptions
      const yearly = getSubscriptionPackages
        .filter(sub => sub.number_of_days === 365)
        .sort((a, b) => a.subscription_number - b.subscription_number); // Sorting yearly subscriptions
  
      setMonthlySubscriptions(monthly);
      setYearlySubscriptions(yearly);
    }
  }, [getSubscriptionPackages]);

  const renderOffers = (offers) => {
    return offers.split(',').map((offer, index) => (
      <li style={{fontSize:'18px',fontFamily:'\'kanit\', sans-serif'}} key={index}>{offer.trim()}</li>
    ));
  };

  const renderPacks = (subscription) => {
    return  (
      <Col sm={12} md={4} key={subscription.subscription_number}>
              <StyledCard className="mb-3">
              <CardTop style={{fontSize:'30px', fontFamily:'\'Kanit\', sans-serif',fontWeight:'bold'}}>
                    {subscription.name}
                  </CardTop>
                <Card.Body>
                  <Card.Text>
                    {subscription.offers && <ul>{renderOffers(subscription.offers)}</ul>}
                  </Card.Text>
                  <MessagesSeparator>
                    Mensagems: {subscription.number_of_messages_per_month}
                  </MessagesSeparator>
                  <MessagesSeparator>
                    Notificações Whatsapp: {subscription.number_of_whatsapp_messages_per_month}
                  </MessagesSeparator>
                  <MessagesSeparator>
                  {subscription.number_of_days} Dias 
                  </MessagesSeparator>
                  <MessagesSeparator style={{fontSize:'50px', fontFamily:'\'Kanit\', sans-serif',fontWeight:'bold'}}>
                  {subscription.monthly_value}€ <div style={{fontSize:'25px', fontFamily:'\'Kanit\', sans-serif',fontWeight:'bold',paddingTop:'25px'}}>/mês</div>
                  </MessagesSeparator>
                </Card.Body>
                
                <Card.Body>
                <Button style={{backgroundColor:'#57b57b', margin:'0'}} variant="success" className='' onClick={() => handleSelectSubscription(subscription)}>
                  Comprar
                </Button>
                
                
                </Card.Body>
                {subscription.number_of_days === 365 &&
                  <ValueDiv style={{fontSize:'30px', fontFamily:'\'Kanit\', sans-serif',fontWeight:'bold'}}>
                    20% off
                  </ValueDiv>
                }
                
                
              </StyledCard>
            </Col>
    );
  };

  const handleSelectSubscription = (subscription) => {
    setSelectedSubscription(subscription);
    setStep('no');
  };

  return (
    <Container>
      {step === 'choose_subscription' ?
        <Row>
          <Col xs={12} style={{display:'flex', justifyContent:'center', fontSize:'25px'}}>
          Subscrições
          </Col>
          <Col Col xs={12} style={{display:'flex', justifyContent:'center', fontSize:'25px', marginBottom:'20px', marginTop:'15px', gap:'5px'}}>
          <ButtonPlans isSelected={showMonthly} onClick={() => setShowMonthly(true)}>Mensal</ButtonPlans>
          <ButtonPlans isSelected={!showMonthly} onClick={() => setShowMonthly(false)}>Anual{'<'}20%OFF</ButtonPlans>
          </Col>
        
        
          <Col xs={12}>
          <Row>
            {showMonthly ? monthlySubscriptions.map(subscription => (
              <>{renderPacks(subscription)}</>
          )) : yearlySubscriptions.map(subscription => (
              <>{renderPacks(subscription)}</>
          ))}
          </Row>
          </Col>
          
        </Row>
      : !isLoadingConfirmSubscription ?
        <Row>
          <Col xs={12} style={{display:'flex', justifyContent:'center', fontSize:'25px'}}> 
            <div style={{width:'100%', height:'100%'}}>
              {/*<StripePay
                restaurant_id={params.restaurant_id}
                subscription_number={selectedSubscription.subscription_number}
                payment_method={'stripe'}
              />*/}
              <StripeCreatePayMethod
                subscription_number={selectedSubscription.subscription_number}
                restaurant_id={params.restaurant_id}
              />
            </div>
          </Col>
          <Col xs={12}>
          <p>Todas as subscrições são renovadas automaticamente, podendo sempre cancelar a qualquer momento.</p>
          <StyledCard style={{marginTop:'10px'}}>
            <CardTop style={{fontSize:'20px'}}>A comprar: </CardTop>
            <Row>
              <Col xs={8} style={{fontSize:'25px', fontFamily:'\'Kanit\', sans-serif',fontWeight:'bold' }}>
              Plano {selectedSubscription.number_of_days === 365 ? 'anual' : 'mensal'} {selectedSubscription.name} 
              </Col>
              <Col xs={4} style={{borderLeft:'1px solid gray',fontSize:'25px', fontFamily:'\'Kanit\', sans-serif' }}>
              Total: {selectedSubscription.total_value}€ {selectedSubscription.number_of_days === 365 && selectedSubscription.monthly_value + '€ /mês'}
              </Col>
            </Row>
              
          </StyledCard>
          </Col>
          <Col>
            <Button className='btn btn-success' onClick={() => setStep('choose_subscription')} style={{marginTop:'10px'}}> 
            <FontAwesomeIcon size='lg' icon={faCircleLeft}  /> Voltar atrás
            </Button>
          </Col>
        </Row>
        :
        <LoaderComponent activate={isLoadingConfirmSubscription}>
        </LoaderComponent>
      }
    </Container>
  );
}

export default ChoosePlanAndPay;