import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Form, Button, Row, Col, Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fetchReservationCustomerMenu, fetchEditReservation } from '../actions/reservationsActions'
import {
  fetchReservationBlockedDays,
  fetchReservationBlockedWeekdays,
  fetchReservationShift,
  fetchOpenTime,
  fetchCreateReservation,
} from '../actions/reservationsActions';

const ReservationScreen = () => {
  const dispatch = useDispatch();

  const id = useParams()
  const [startDate, setStartDate] = useState(new Date());
  const [shifts, setShift] = useState('');
  const [numPeople, setNumPeople] = useState(0);
  const [babyChairs, setBabyChairs] = useState(false);
  const [numBabyChairs, setNumBabyChairs] = useState(0);
  const [time, setTime] = useState('');
  const [message, setMessage] = useState('');
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  useEffect(() => {
    dispatch(fetchReservationCustomerMenu(id.token));
  }, [id.token]);

  const reservation_info = useSelector(state => state.reservationCustomersMenu)
  const {  reservation ,isLoading, error } = reservation_info


  useEffect(() => {
    if (reservation) {
      dispatch(fetchReservationBlockedDays(reservation.restaurant));
      dispatch(fetchReservationBlockedWeekdays(reservation.restaurant));
      dispatch(fetchReservationShift(reservation.restaurant, startDate));
      dispatch(fetchOpenTime(reservation.restaurant, startDate, numPeople, shifts));
    }
  }, [dispatch, reservation]);

  



  const [currentStep, setCurrentStep] = useState(1);

  const reservationBlockedDays = useSelector((state) => state.reservationBlockedDays);
  const { blockedDays, isLoading: isLoadingBlockedDays, error: errorBlockedDays } = reservationBlockedDays;

  const reservationBlockedWeekDays = useSelector((state) => state.reservationBlockedWeekDays);
  const { blockedWeekdays, isLoading: isLoadingBlockedWeekdays, error: errorBlockedWeekdays } = reservationBlockedWeekDays;

  const reservationShift = useSelector((state) => state.reservationShift);
  const { shift, isLoading: isLoadingShift, error: errorShift } = reservationShift;

  const openTimes = useSelector((state) => state.openTime);
  const { openTime, isLoading: isLoadingOpenTime, error: errorOpenTime } = openTimes;



  const isBlocked = (date) => {
    const day = date.getDay();
    const blockedWeekdayss = blockedWeekdays
      .split(',')
      .map((str) => parseInt(str.trim()))
      .filter((num) => !isNaN(num));

    const isBlockedDay = Array.isArray(blockedDays) && blockedDays.some(
      (blockedDate) => blockedDate.getTime() === date.getTime()
    );
    const isBlockedWeekday = blockedWeekdayss.includes(day);

    return isBlockedDay || isBlockedWeekday;
  };

  const goForward = () => {
    if (currentStep === 1) {
      dispatch(fetchReservationShift(reservation.restaurant, startDate));
    } else if (currentStep === 2) {
      dispatch(fetchOpenTime(reservation.restaurant, startDate, numPeople, shifts));
      
    }
    setCurrentStep(currentStep + 1);
  };

  const goBack = () => {
    setCurrentStep(currentStep - 1);
  };

  

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(fetchEditReservation(id.token, reservation.restaurant, startDate, numPeople, shifts, time, fullName, email, phone, numBabyChairs, message));
  };

  useEffect(() => {
    if (shift && shift.length > 0) {
      setShift(shift[0]);
    }
  }, [shift]);

  return (
    <Container>
      <h1>Asas</h1>
      <Card>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            {currentStep === 1 && (
              <>
                <Form.Group>
                  <Form.Label>Date</Form.Label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    filterDate={(date) => !isBlocked(date)}
                    minDate={new Date()}
                  />
                </Form.Group>
                <Button className='mt-3' onClick={goForward}>Next</Button>
              </>
            )}

            {currentStep === 2 && (
              <>
                <Form.Group>
                  <Form.Label>Shift</Form.Label>
                  <Form.Control as="select" value={shifts} onChange={(e) => setShift(e.target.value)}>
                    {shift.map(shifts => (
                      <option key={shifts.id} value={shifts.id}>
                        {shifts}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Number of People</Form.Label>
                  <Form.Control type="number" value={numPeople} onChange={(e) => setNumPeople(e.target.value)} />
                </Form.Group>

                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    label="Baby chairs needed"
                    checked={babyChairs}
                    onChange={(e) => setBabyChairs(e.target.checked)}
                  />
                  <Form.Label>Number of baby chairs</Form.Label>
                  <Form.Control type="number" value={numBabyChairs} onChange={(e) => setNumBabyChairs(e.target.value)} />
                </Form.Group>

                <Button className='mt-3' onClick={goBack}>Back</Button>
                <Button className='mt-3' onClick={goForward}>Next</Button>
              </>
            )}

            {currentStep === 3 && (
              <>
                <Form.Group>
                  <Form.Label>Time</Form.Label>
                  <Form.Control as="select" value={time} onChange={(e) => setTime(e.target.value)}>
                    <option value="">Select Time</option>
                    {openTime.map(time => (
                      <option key={time.id} value={time.id}>
                        {time}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows={3} value={message} onChange={(e) => setMessage(e.target.value)} />
              </Form.Group>

              <Button className='mt-3' onClick={goBack}>Back</Button>
              <Button className='mt-3' onClick={goForward}>Next</Button>
            </>

          )}

          {currentStep === 4 && (
            <>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control type="text" value={fullName} onChange={(e) => setFullName(e.target.value)} />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} />
                  </Form.Group>
                </Col>
              </Row>

              <Button className='mt-3' onClick={goBack}>Back</Button>
              <Button className='mt-3' type="submit">Submit</Button>
            </>
          )}
        </Form>
      </Card.Body>
    </Card>
  </Container>
);

};

export default ReservationScreen;