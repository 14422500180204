
import * as React from "react";
import './index.css';
import './bootstrap.min.css';
import { Provider }  from 'react-redux'
import  App  from './App';
import { store } from './store';
import { createRoot } from "react-dom/client";
import { HashRouter as BrowserRouter } from "react-router-dom";


const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)

