import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { api } from '../App';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';

const Button = styled.button`
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-color: #65DDB1;
  border: 1px solid #65DDB1;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 4px;
  margin-top: 10px;
  margin-right: 3px;
  &:hover {
    background-color: #47A281;
    border-color: #47A281;
  }
`;

const ConnectedTablesContainer = styled.div`
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
  margin: 10px 0;
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
  margin-bottom: 20px;
`;

const ConnectedTable = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 0.9rem;
  gap:10px;
  margin-bottom: 5px;
  margin-right:3px;
`;

const Input = styled.input`
  width: 80px;
  padding: 5px;
  font-size: 0.9rem;
  border-radius: 4px;
  border: 1px solid #ced4da;
  justify-content: center;
  text-align:center;
  margin-left:10px;
`;


function ConnectedTablesBox({ connectedTablesArray, restaurant_id }) {
  const dispatch = useDispatch();
  const params = useParams();
  const [restaurantId, setRestaurantId] = useState(restaurant_id ? restaurant_id : params.id);
  const [inputValues, setInputValues] = useState({});
  useEffect(() => {
    const initialValues = connectedTablesArray.reduce((acc, connectedTable) => {
      acc[connectedTable.id] = connectedTable.number_of_chairs;
      return acc;
    }, {});
    setInputValues(initialValues);
  }, [connectedTablesArray]);

  const handleNumberOfChairsChange = async () => {
    for (const id in inputValues) {
      const data = {
        number_of_chairs: inputValues[id],
      };
      try {
        const response = await api.patch(
          `/restaurants/${restaurant_id}/numberofpeopleperconnectedtable/${id}/`,
          data
        );
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <ConnectedTablesContainer>

      {Array.isArray(connectedTablesArray) ? (
        connectedTablesArray.map((connectedTable) => (
          <ConnectedTable key={connectedTable.id}>
            <Label>
               {connectedTable.number_of_tables}    mesas
              <span>
                tirar ou juntar cadeiras: 
                <Input
                  type="number"
                  value={inputValues[connectedTable.id] || 0}
                  onChange={(event) =>
                    setInputValues({
                      ...inputValues,
                      [connectedTable.id]: parseInt(event.target.value, 10),
                    })
                  }
                />
              </span>
            </Label>
          </ConnectedTable>
        ))
      ) : (
        <p>No connected tables data available.</p>
      )}
      <Button onClick={handleNumberOfChairsChange}> Guardar <FontAwesomeIcon icon={faPenToSquare} style={{color: "#ffffff",}} /></Button>
    </ConnectedTablesContainer>
  );
}

export default ConnectedTablesBox;