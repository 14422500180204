import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

function TermsAndConditions({showTermsAndConditions, setSetShowTermsAndConditions}) {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(showTermsAndConditions);
  },[showTermsAndConditions])

  const closeModal = () => {
    setSetShowTermsAndConditions(false);
  };

  return (
    <Modal show={showModal} onHide={closeModal} scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Acordo dos termos e condições</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: '400px', overflowY: 'auto' }}>
        <p>Por favor, leia cuidadosamente os seguintes Termos e Condições ("Acordo") antes de usar os serviços fornecidos pela Soup ("Empresa"). Ao usar nossos serviços, você concorda em cumprir estes termos e condições. Se você não concorda com qualquer parte deste Acordo, por favor, abstenha-se de usar nossos serviços.</p>
        
        <h2>Visão Geral</h2>
        <p>1.1 A Empresa, Soup, está comprometida em proteger a privacidade e a segurança das informações pessoais de nossos clientes. Este Acordo descreve como coletamos, armazenamos e utilizamos os dados fornecidos por nossos clientes para uso interno e publicidade interna.</p>
        
        <h2>Coleta e Uso de Dados</h2>
        <p>2.1 A Empresa irá coletar e armazenar as seguintes informações dos clientes: endereço de e-mail, número de telefone e nome ("Dados do Cliente").</p>
        <p>2.2 Os Dados do Cliente serão coletados durante o processo de registro ou compra, bem como através das interações com nosso site ou serviços.</p>
        <p>2.3 A Empresa utilizará os Dados do Cliente apenas para fins internos, como melhorar nossos serviços, conduzir pesquisas e análises e fornecer experiências personalizadas aos nossos clientes.</p>
        <p>2.4 A Soup poderá utilizar os Dados do Cliente para enviar ocasionais anúncios internos, materiais promocionais ou atualizações importantes relacionadas aos nossos serviços, a menos que o cliente opte explicitamente por não receber tais comunicações.</p>
        
        <h2>Segurança dos Dados</h2>
        <p>3.1 A Empresa leva a segurança dos dados a sério e implementa medidas razoáveis para proteger os Dados do Cliente contra acesso, divulgação, alteração ou destruição não autorizados.</p>
        <p>3.2 A Soup não venderá, trocará, alugará ou transferirá de outra forma os Dados do Cliente a terceiros sem o consentimento explícito do cliente, exceto quando exigido por lei ou necessário para fornecer os serviços.</p>
        <p>3.3 A Empresa poderá compartilhar dados agregados e anonimizados com terceiros para fins de marketing, publicidade, pesquisa ou outros fins.</p>
        
        <h2>Retenção de Dados</h2>
        <p>4.1 A Empresa manterá os Dados do Cliente pelo tempo necessário para cumprir os objetivos descritos neste Acordo, a menos que um período de retenção mais longo seja exigido ou permitido por lei.</p>
        <p>4.2 Após o término da conta do cliente ou solicitação de exclusão de dados, a Empresa excluirá ou anonimizará os Dados do Cliente de forma segura, exceto quando exigido por lei ou necessário para manter registros comerciais.</p>
        
        <h2>Conformidade com as Leis</h2>
        <p>5.1 A Soup cumprirá as leis e regulamentos aplicáveis de proteção de dados e privacidade na jurisdição em que opera.</p>
        <p>5.2 O cliente reconhece e concorda em cumprir todas as leis, regulamentos e direitos de terceiros aplicáveis em relação ao uso de nossos serviços e aos Dados do Cliente.</p>
        
        <h2>Limitação de Responsabilidade</h2>
        <p>6.1 A Empresa não será responsável por quaisquer danos diretos, indiretos, incidentais, consequenciais ou especiais decorrentes do uso de nossos serviços ou dos Dados do Cliente, mesmo que tenha sido informada da possibilidade de tais danos.</p>
        
        <h2>Modificações no Acordo</h2>
        <p>7.1 A Soup reserva-se o direito de modificar ou atualizar este Acordo a qualquer momento. A versão atualizada será publicada em nosso site, e é responsabilidade do cliente revisar o Acordo periodicamente. O uso contínuo de nossos serviços após quaisquer modificações constitui a aceitação do Acordo revisado.</p>
        
        <h2>Informações de Contato</h2>
        <p>8.1 Se você tiver alguma dúvida ou preocupação sobre este Acordo ou a privacidade de seus dados, entre em contato conosco em 915468551.</p>
        
        <p>
          Ao utilizar nossos serviços, você reconhece que leu, compreendeu e concordou com os termos e condições
          descritos neste Acordo.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TermsAndConditions;