import React, { useState, useEffect } from 'react';
import styled from 'styled-components';


// custom shift select 


const CustomSelectOption = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 5px;
  font-size: 1rem;
  border: 1px solid ${({ isShiftSelected }) => (isShiftSelected ? '#88F1D3' : 'black')};
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  background-color: ${({ isShiftSelected }) => (isShiftSelected ? '#88F1D3' : 'transparent')};
  color: ${({ isShiftSelected }) => (isShiftSelected ? 'white' : 'black')};
  transition: all 0.2s ease-in-out;
  margin-right: 10px;

  &:hover {
    border-color: #88F1D3;
  }
`;
const CustomSelectText = styled.span`
  margin-right: 5px;
`;

const CustomSelectCheckWrapper = styled.span`
  z-index: 1050;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid black;
  border-radius: 50%;
  background-color: white;
  margin-left: 5px;
  position: absolute;
`;

const CustomSelectCheck = styled.span`
  position: absolute;
  z-index: 0;
  opacity: ${({ isShiftSelected }) => (isShiftSelected ? 1 : 0)};
  color: black;
`;

function ShiftSelect({ option, onShiftChange, selectedShift, setShift }) {
    const [selectedOption, setSelectedOption] = useState(selectedShift);
  
    const handleOptionClick = (option) => {
      setSelectedOption(selectedOption === option ? null : option);
      onShiftChange(selectedOption === option ? null : option);
      setShift(option);
    };
  
    useEffect(() => {
      setSelectedOption(selectedShift);
    }, [selectedShift]);
  
    return (
      <CustomSelectOption
        key={option.id}
        isShiftSelected={selectedShift === option}
        onClick={() => handleOptionClick(option)}
      >
        <CustomSelectText>{option}</CustomSelectText>
        <CustomSelectCheckWrapper>
          <CustomSelectCheck isShiftSelected={selectedShift === option}>&#10003;</CustomSelectCheck>
        </CustomSelectCheckWrapper>
      </CustomSelectOption>
    );
  }
  
  export default ShiftSelect;