import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import styled, { keyframes, css } from 'styled-components';

const showMessageAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const MessageWrapper = styled(Alert)`
  margin: 5px;
  ${({ show }) =>
    show &&
    css`
      animation: ${showMessageAnimation} 0.5s ease-in-out;
    `}
`;

function Messages({ variant, children }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  return <MessageWrapper variant={variant} show={show}>{children}</MessageWrapper>;
}

export default Messages;