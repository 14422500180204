import { api } from '../App'
import { crudManagerPermissionsSlice } from '../reducers/managersReducers'


const { registerManagerRequest, registerManagerSuccess, registerManagerFail } = crudManagerPermissionsSlice.actions

export const registerManager = (name, email, permission, 
                                restaurants, getEmail, country_code, 
                                phone_number, whatsapp_notification_token,
                                getWhatsappNotifications
                                ) => async (dispatch, getState) => {
    
    try {
        dispatch(registerManagerRequest(),)

        const {
            userLogin: { userInfo }
        } = getState()

        const config  = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            }
        }

        const  response  = await api.post(`/users/registerManagerAccount/`,
        { 'name': name, 'email': email, 
        'permission': permission, 'restaurants': restaurants, 
        'getEmail': getEmail, 'country_code':country_code, 
        'phone_number':phone_number, 'whatsapp_notification_token':whatsapp_notification_token,
        'whatsapp_notification_token': whatsapp_notification_token,
        'getWhatsappNotifications':getWhatsappNotifications,
     },
        config
        )
        dispatch(registerManagerSuccess(response.data))
        dispatch(getManagers());
    }
    catch (error) {
      dispatch(registerManagerFail(error.response && error.response.data.detail 
                                    ? error.response.data.detail
                                    : error.message));
    }
}



const { getManagersRequest, getManagersSuccess, getManagersFail } = crudManagerPermissionsSlice.actions

export const getManagers = () => async (dispatch, getState) => {
    
    try {
        dispatch(getManagersRequest(),)

        const {
            userLogin: { userInfo }
        } = getState()

        const config  = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            }
        }

        const  response  = await api.get(`/users/getManagers/`,
        config
        )
        dispatch(getManagersSuccess(response.data));
        
    }
    catch (error) {
      dispatch(getManagersFail(error.response && error.response.data.detail 
                                    ? error.response.data.detail
                                    : error.message));
    }
}



const { getManagerRequest, getManagerSuccess, getManagerFail } = crudManagerPermissionsSlice.actions

export const getManager = () => async (dispatch, getState) => {
    
    try {
        dispatch(getManagerRequest())

        const {
            userLogin: { userInfo }
        } = getState()

        const config  = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            }
        }
        console.log('config: ', config)
        const response = await api.get(
            '/users/getManagerAccountPermissions/',
            config
        )
        localStorage.setItem("manager_info", JSON.stringify(response.data))
        dispatch(getManagerSuccess(response.data))
        
    }
    catch (error) {
        dispatch(
            getManagerFail(
                error.response && error.response.data.detail 
                ? error.response.data.detail
                : error.message
            )
        );
    }
}



const { updateManagerRequest, updateManagerSuccess, updateManagerFail } = crudManagerPermissionsSlice.actions

export const updateManager = (permission, restaurants, password, 
                              manager_pk, getEmails='NO', country_code, 
                              phone_number, whatsapp_notification_token,
                              getWhatsappNotifications) => async (dispatch, getState) => {
    const params = {}
    if (permission) params.permissions = permission;
    if (restaurants) params.restaurants = restaurants;
    if (password) params.password = password;
    if (manager_pk) params.manager_pk = manager_pk;
    if (getEmails != 'NO') params.getEmails = getEmails;
    if (country_code) params.country_code = country_code;
    if (phone_number) params.phone_number = phone_number;
    if (whatsapp_notification_token) params.whatsapp_notification_token = whatsapp_notification_token;
    if (getWhatsappNotifications) params.getWhatsappNotifications = getWhatsappNotifications;

    console.log('params:  ',params)
    
    try {
        dispatch(updateManagerRequest(),)

        const {
            userLogin: { userInfo }
        } = getState()

        const config  = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            }
        }

        const  response  = await api.put(`/users/updateManagerAccountPermissions/`,
        params,
        config
        )
        dispatch(updateManagerSuccess(response.data))
        dispatch(getManagers());
        dispatch(getManager());
    }
    catch (error) {
      dispatch(updateManagerFail(error.response && error.response.data.detail 
                                    ? error.response.data.detail
                                    : error.message));
    }
}


const { deleteManagesrRequest, deleteManagersSuccess, deleteManagersFail } = crudManagerPermissionsSlice.actions

export const deleteManager = (manager_pk) => async (dispatch, getState) => {
    const params = {}
    if (manager_pk) params.manager_pk = manager_pk;
    
    try {
        dispatch(deleteManagesrRequest(),)

        const {
            userLogin: { userInfo }
        } = getState()

        const config  = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            }
        }
        const  response  = await api.post(`/users/deleteManagerAccount/`,
        params,
        config
        )
        dispatch(deleteManagersSuccess(response.data))
        dispatch(getManagers());
    }
    catch (error) {
      dispatch(deleteManagersFail(error.response && error.response.data.detail 
                                    ? error.response.data.detail
                                    : error.message));
    }
}
