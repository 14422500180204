import { memo, useEffect } from 'react'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { Item } from './Item.js'
import { ItemTypes } from './ItemTypes.js'
function getStyles(left, top, isDragging, isPlaced) {
  // const transform = `translate3d(${left}%, ${top}%, 0)`
  return {
    border: '1px solid #27a888',
    borderRadius: '4px',
    padding: '10px',
    marginBottom: '5px',
    cursor: 'pointer',
    height: '39px',
    padding: '4px',
    margin: '4px',
    flexShrink: '0',
    // transform,
    display: isPlaced === true ? 'none' : '',
    // WebkitTransform: transform,
    // IE fallback: hide the real node using CSS when dragging
    // because IE will ignore our custom "empty image" drag preview.
    opacity: isDragging ? 0 : 1,
  }
}
function DraggableItem({ id, table_name, left, top, isPlaced, moveTable, leftDistance, topDistance, selectItem, item })  {
  const state = 'draggingFromMenu'
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: ItemTypes.TABLE,
      item: { id, left, top, table_name, state },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, left, top, table_name],
  )

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, []);

  const handleClick = (e) => {
    // This is where we're getting the initial position of the mouse when the div is first clicked.
    const boundingRect = e.currentTarget.getBoundingClientRect();
    const left = boundingRect.left;
    const top = e.clientY;
    console.log('left inside: ', left)
    console.log('top inside: ', top)
    moveTable(id, left - leftDistance , top - topDistance - 22);
    selectItem(item);
  }

  return (
    <div
      ref={drag}
      style={getStyles(left, top, isDragging, isPlaced)}
      role="DraggableItem"
      onMouseDown={handleClick}
    >
      {table_name}
    </div>
  )
};

export default DraggableItem
