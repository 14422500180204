import { useCallback, useState, useRef, useEffect } from 'react'
import { useDrop } from 'react-dnd'
import styled from 'styled-components';
import DraggableItem from './DraggableItem';
import { ItemTypes } from './ItemTypes.js'
import { snapToGrid as doSnapToGrid } from './snapToGrid.js'


function GridItems({items, 
                    moveTable, 
                    topDistance, 
                    setUpdateTopDistance, 
                    selectItem, 
                    selectedPlace, 
                    selectedSeparator,
                    objects}) {
    const [leftDistanceBetweenDivAndPage, setLeftDistanceBetweenDivAndPage] = useState('');
    const divRef = useRef(null);

    const [, drop] = useDrop(() => ({
      accept: ItemTypes.TABLE,
      drop(item, monitor) {

        moveTable(item.id, item.left, item.top, 'tableOff');
        return undefined;
      },
    }),
    [moveTable]);

    useEffect(() => {
      if (divRef.current) {
        const resizeObserver = new ResizeObserver(entries => {
          for (let entry of entries) {
            const rect = entry.target.getBoundingClientRect();
            const left = rect.left + window.scrollX;
            console.log('Distance from left side of page to div: ', left);
            setLeftDistanceBetweenDivAndPage(left);

          }
        });
  
        resizeObserver.observe(divRef.current);
        
        return () => {
          if (divRef.current) {
          resizeObserver.unobserve(divRef.current);
          }
        }; 
      };
    }, []);
    
    const moveIt = (id, left, top) => {
      moveTable(id, left, top, 'reset');
    };

    useEffect(() => {
        console.log('items: ', items)
    },[items]);

    const setBothRefs = (el) => {
      divRef.current = el;
      drop(el);
    };

  return (
    <div ref={setBothRefs} className='item-list'>
      {selectedSeparator === 'Tables' ?
      <>
      {Object.keys(items)
          .filter(key => items[key].place_of_table === selectedPlace) // Filter keys based on the condition
          .map((key) => (
            <DraggableItem key={key} 
                          id={key} 
                          {...items[key]} 
                          topDistance={topDistance} 
                          leftDistance={leftDistanceBetweenDivAndPage} 
                          moveTable={(id, left, top) => moveIt(id, left, top)}
                          selectItem={selectItem} 
                          item={items[key]}/>
          ))}
      </>
          :
          <>
            {Object.keys(objects)
              .filter(key => items[key].place_of_table === selectedPlace) // Filter keys based on the condition
              .map((key) => (
                <DraggableItem key={key} 
                              id={key} 
                              {...items[key]} 
                              topDistance={topDistance} 
                              leftDistance={leftDistanceBetweenDivAndPage} 
                              moveTable={(id, left, top) => moveIt(id, left, top)}
                              selectItem={selectItem} 
                              item={items[key]}/>
              ))}
          </>
      }
    </div>
  )
}

export default GridItems;
