import React from 'react'
import styled from 'styled-components';
import Messages from './Messages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCircleXmark, faUserPen } from '@fortawesome/free-solid-svg-icons'

const AttendanceButtonsContainer = styled.div`
  display: flex;
  align-items:center;
  min-width:200px;
  height:35px;
  font-size: .9rem; 
  border: 1px solid gray;
  border-radius:5px;
  overflow:hidden;
  padding:0;
`;

const ContainerLeft = styled.div`
  padding: 0;
  display: flex;
  color:white;
  align-items:center;
  background-color:#58c49f;
  height:100%;
  width:100px;
  text-align:center;
  border-right: 1px solid gray; 
  padding: 2px;
`;
const ContainerRight = styled.div`
  display: flex;  
  align-items: center;  
  font-size: 12px;
  color: gray;
  text-align:center;
  width:130px;
  height:100%;
`;

const Container50 = styled.div`
  display: flex;  
  align-items: center;
  justify-content:center;
  font-size: 12px;
  color: gray;
  text-align: center;
  width:50%;
  height: 100%;
  border-left: 1px solid gray; 
`;

function AttendanceButtons({reservation, handleAttended, handleNotAttended, handleAttendedReset}) {
  return (
    <AttendanceButtonsContainer>
      <ContainerLeft>
        Compareceu
      </ContainerLeft>
      <ContainerRight>
      {reservation.status === 'Accepted' && reservation.attended_or_not === 'waiting'?
        <>
          <Container50>
            <FontAwesomeIcon 
              icon={faCircleCheck} style={{color: "#10d580", cursor: 'pointer'}}  
              onClick={() => handleAttended(reservation.token)}
              size='2xl'
            />
          </Container50>
          <Container50>
            <FontAwesomeIcon 
              icon={faCircleXmark} style={{color: "#c95422", cursor: 'pointer'}}  
              onClick={() => handleNotAttended(reservation.token)} 
              size='2xl'
              disabled={reservation.status === 'cancelled'}
            />
          </Container50>  
        </>
        : reservation.attended_or_not === 'attended'? 
        <>
        <Container50>
         <FontAwesomeIcon 
            icon={faCircleCheck} style={{color: "#10d580",}}  
            size='2xl'
          />
        </Container50>
        <Container50>
          <FontAwesomeIcon 
            icon={faUserPen} style={{color: "#b5bab6", cursor: 'pointer'}}
            onClick={() => handleAttendedReset(reservation.token)}
            size='2xl'
          /> 
        </Container50>
        </>
        :
        <>
        <Container50>
         <FontAwesomeIcon 
            icon={faCircleXmark} style={{color: "#c95422",}}
            size='2xl'
          />
        </Container50>
        <Container50>
          <FontAwesomeIcon 
            icon={faUserPen} style={{color: "#b5bab6", cursor: 'pointer'}}  
            onClick={() => handleAttendedReset(reservation.token)}
            size='2xl'
          /> 
        </Container50>
        </>
        }
      </ContainerRight>
         
    </AttendanceButtonsContainer>
  )
}

export default AttendanceButtons