import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Modal,  Form, Row, Col  } from 'react-bootstrap';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import CustomSelectArray from '../components/CustomSelectArray';
import SelectablePopUpOptions from '../components/SelectablePopUpOptions'
import axios from 'axios';
import Loader from './Loader';
import { api } from '../App';
import {
  fetchClosedExceptions,
  createClosedExceptions,
  fetchAllShifts,
  fetchPlaceOfTable,
} from '../actions/restaurantActions';
import styled from 'styled-components';
import DropDownButton from '../components/DropDownButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCalendar, faMapLocation, faCircleXmark, faClock, faUser, faSun } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const ModernButton = styled.button`
  padding: 5px 8px;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0056b3;
  }
`;

const StyledDateRangePicker = styled(DateRangePicker)`
  width: auto;
  margin: 0 auto;
  border: 1px solid black;
  border-radius: 10px;

  .rdrDefinedRangesWrapper {
    display: none;
  }
`;

const Button = styled.button`
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-color: #65DDB1;
  border: 1px solid #65DDB1;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 4px;
  margin-top:10px;
  &:hover {
    background-color: #47A281;
    border-color: #47A281;
  }
`;


const DatepickerModalBox = styled.div`
  width: 100%;
  text-align:center;
  justify-content: center;
  margin-bottom:10px;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 10px;
  margin-top:10px;
`;

const HorizontalItemBox = styled.div`
background-color: #f8f9fa;
position:relative;
padding: 20px;
border-radius: 8px;
box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
margin-bottom: 20px;
`;

const Wrapper = styled.div`
  position: relative;
  margin: 10px 0;
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
  border-radius: 5px;
  color: black;
  border: 1px solid #e8e6e1;
`;

const WrapperTop = styled.div`
  width:100%;
  color:white;
  height:60px;
  background-color:#8FEBC5;
  border-radius: 5px 5px 0 0; 
  margin-bottom:10px;
`;

const WrapperBot = styled.div`
  width:100%;
  height:400px;
  padding:10px;
  overflow:scroll;
  // Custom scrollbar styles
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
`;


const Title = styled.h6`
  padding: 20px;
  color: white;
`;

const CreateButton = styled(ModernButton)`
  position: absolute;
  top: 11px;
  right: 11px;
  background-color: #59d9d4;
  &:hover {
    background-color: #228a86;
  }
`;

const B2 = styled.div`
  background-color: #f8f9fa;
  position:relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`;

const B2Top = styled.div`
  background-color: #58c49f;
  height:30px;
  color:white;
  width:100%;
`;

const B2Bot = styled.div`
  padding: 10px;
  max-height: 250px;
  width: 100%;
  font-size:14px;
`;

const ZoneTitle = styled.h2`
  color:white;
  font-size:18px;
  padding: 5px;
`;

const DangerButton = styled(Button)`
  background-color: #ff704d;
  border-color: #ff704d;

  &:hover {
    background-color: #ff471a;
    border-color: #ff471a;
  }
`;

const RemoveButton = styled(DangerButton)`
  position: absolute;
  top: 30%;
  right: 1px;
  width: 20px;
  height: 20px;
  padding: 3px;
  font-size: 18px;
  text-align: center;
  line-height: 50%;
  transform: translateY(-50%);
`;

const StyledInput = styled.input`
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    margin: 5px 0;
    width: 100%; // or any other width you prefer

    // Add any other styles you desire
    &:focus {
        border-color: #007BFF; // for example, change border color on focus
        outline: none;
    }
`;

const EditButton = styled(ModernButton)`
  margin-left: 10px;
  background-color:#0fbd76;
  &:hover {
    background-color: #21d98e;
  }
`;

const LargeIconPlus = styled(FontAwesomeIcon)`
  margin-left: 7px; /* adjust this value as needed */
`;

function formatDate(date) {
  const d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  return [year, month.padStart(2, '0'), day.padStart(2, '0')].join('-');
}


const ClosedExceptions = ({restaurant_id}) => {
    const dispatch = useDispatch();
    const params = useParams();
    useEffect(() => {
      if (restaurant_id) {
        dispatch(fetchClosedExceptions(restaurant_id));
        dispatch(fetchAllShifts(restaurant_id));
        dispatch(fetchPlaceOfTable(restaurant_id));
      }
    }, [dispatch, restaurant_id]);

const restaurantZones = useSelector(state => state.restaurantZones);
const { zones = []} = restaurantZones;

// Get the current date and time
const now = new Date();

// Specify the time zone as 'Europe/Lisbon' or 'Portugal'
const options = { timeZone: 'Europe/Lisbon' };

// Get the day in Lisbon time
const currentDay = now.toLocaleString('en-US', options);
  const allShiftss = useSelector((state) => state.allExistingShifts);
  const { allShifts, isLoadingShifts } = allShiftss
  const [selectedItems, setSelectedItems] = useState(['Breakfast','Lunch','Dinner']);
  const closedExceptions = useSelector((state) => state.closedExceptions);
  const { exceptions } = closedExceptions
  const [showModal, setShowModal] = useState(false);
  const [shifts, setShifts] = useState(['Breakfast','Lunch','Dinner']);
  const [zone_list, setZone_list] = useState([]);
  const [selectedZones, setSelectedZones] = useState([]);
  const [numberOfAcceptance, setNumberOfAcceptance] = useState(0);
  const [showExceptionDaysDeleteModal, setShowExceptionDaysDeleteModal] = useState(false);
  const [exception_id, setException_id] = useState(false);
  const [numbersList, setNumbersList] = useState(() => {
    const list = [];
    for (let i = 0; i <= 100; i++) {
      list.push(i);
    }
    return list;
  });

  useEffect(() => {
    const placeOfTables = zones.map(zone => zone.place_of_table);
    setZone_list(placeOfTables);
    setSelectedZones(placeOfTables);
    const joinedString = placeOfTables.join(',');
    setFormData({ ...formData, ['list_of_zones']: String(joinedString) });
  }, [zones]);

  const [formData, setFormData] = useState({
    restaurant: parseInt(restaurant_id),
    closed_days_start: formatDate(currentDay),
    closed_days_end: formatDate(currentDay),
    shifts:'Breakfast,Lunch,Dinner',
    list_of_zones:'',
    number_of_acceptance: '0',
  });

  useEffect(() => {
    if (allShifts) {
      setShifts(allShifts)
    }
  }, [dispatch, allShifts]);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleNumberOfAcceptance = (value) => {
    setFormData({ ...formData, ['number_of_acceptance']: String(value) });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('formData: ', formData)
    // Replace with your API URL
    dispatch(createClosedExceptions(restaurant_id, formData));
    setShowModal(false);
  };

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(), 
      key: 'selection',
    },
  ]);

  const handleDateRangeChange = (ranges) => {
    setDateRange([{
      ...ranges.selection,
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
    }]);
  
    setFormData({
      ...formData,
      closed_days_start: ranges.selection.startDate
        ? formatDate(ranges.selection.startDate)
        : '',
      closed_days_end: ranges.selection.endDate
        ? formatDate(ranges.selection.endDate)
        : '',
    });
  };


  const handleSelectChange = (newSelectedItems) => {
    console.log('handleSelectChange called with:', newSelectedItems);
    
    // Define all shifts
    const theShifts = ['Breakfast', 'Lunch', 'Dinner'];
  
    // Add the shifts from theShifts that are not in allShifts to newSelectedItems
    for (let i = 0; i < theShifts.length; i++) {
      if (!allShifts.includes(theShifts[i]) && !newSelectedItems.includes(theShifts[i])) {
        newSelectedItems.push(theShifts[i]);
      }
    }
  
    const joinedString = newSelectedItems.join(', ');
    setFormData({ ...formData, ['shifts']: joinedString });
    setSelectedItems(newSelectedItems);
  };

  const handleSelectZones = (newSelectedItems) => {
    console.log('handleSelectChange called with:', newSelectedItems);
    const joinedString = newSelectedItems.join(',');
    setFormData({ ...formData, ['list_of_zones']: joinedString });
    setSelectedZones(newSelectedItems);
  };

  const handleExceptionElimination = async (value) => {
    try {
      const response = await api.delete(
        `/restaurants/${restaurant_id}/closedexceptions/${value}`
      );
  
      // Handle the response here (e.g., update your component's state)
      console.log(response.data);
    } catch (error) {
      // Handle the error here (e.g., display an error message)
      console.error(error);
    }
    setShowExceptionDaysDeleteModal(false);
    dispatch(fetchClosedExceptions(restaurant_id));
  };

  const handleModalClose = () => {
    setShowExceptionDaysDeleteModal(false);
  }
  const handleModalOpen = (value) => {
    setShowExceptionDaysDeleteModal(true);
    setException_id(value)
  }

  const translateShift = (shift) => {
    const translations = {
      "Breakfast": "Pequeno Almoço",
      "Dinner": "Jantar",
      "Lunch": "Almoço"
    };
  
    return translations[shift.trim()] || shift;
  }
  
  return (
    <Wrapper>
      <WrapperTop>
        <Title>Exceções ao horário de funcionamento</Title>
      </WrapperTop>
      <WrapperBot>
      <B2>
        <B2Bot >
          <Row style={{width:'100%', display:'flex', flexWrap:'nowrap', padding:'0', margin:'0'}}>
            <Col xs={6} md={3} style={{display:'flex',flexWrap:'wrap', justifyContent:'center'}}>
              <FontAwesomeIcon size='lg' icon={faCalendar} style={{color:'#5cb590'}}/>
            </Col>
            <Col xs={6} md={3} style={{display:'flex', flexWrap:'wrap', justifyContent:'center'}}>
              <FontAwesomeIcon size='lg' icon={faSun} style={{color:'#5cb590'}}/>
            </Col>
            <Col sm={0} md={4} style={{display:'flex',flexWrap:'wrap', justifyContent:'center'}}>
             <FontAwesomeIcon size='lg' icon={faMapLocation} style={{color:'#5cb590'}}/>
            </Col>
            <Col sm={0} md={2} style={{display:'flex',flexWrap:'wrap', justifyContent:'center'}}>
              <FontAwesomeIcon size='lg' icon={faUser} style={{color:'#5cb590'}}/>
            </Col>
          </Row>
        </B2Bot>
      </B2> 
      {Array.isArray(exceptions)?
      
        <div> 
          
            {
              exceptions.map((exception) => {
              const translatedShifts = exception.shifts
                .split(',')
                .map(translateShift)
                .join(', ');

              return (
                <B2>
                <B2Bot key={exception.id}>
                  <Row style={{width:'100%', display:'flex', flexWrap:'nowrap', padding:'0', margin:'0'}}>
                    <Col xs={6} md={3} style={{display:'flex',flexWrap:'wrap', justifyContent:'center'}}>
                      {exception.closed_days_start} {exception.closed_days_start !== exception.closed_days_end  && <> / {exception.closed_days_end}</>}
                    </Col>
                    <Col xs={6} md={3} style={{display:'flex',flexWrap:'wrap', justifyContent:'center'}}>
                      {translatedShifts}
                    </Col>
                    <Col sm={0}  md={4} style={{display:'flex',flexWrap:'wrap', justifyContent:'center'}}>
                      {exception.list_of_zones.replace(/,/g, ', ')}
                    </Col>
                    <Col sm={0} md={2} style={{display:'flex',flexWrap:'wrap', justifyContent:'center'}}>
                      {exception.number_of_acceptance}
                    </Col>
                  </Row>
                    <RemoveButton onClick={() => handleModalOpen(exception.id)} style={{marginRight: '10px'}}>x</RemoveButton>
                </B2Bot>
                </B2> 
              );
            })
          }
        </div>
       
      :
      <Loader></Loader>
      }
        
      
      <CreateButton variant="primary" onClick={handleShow}>
        Adicionar <LargeIconPlus size="xl" icon={faPlus} />
      </CreateButton>

      </WrapperBot>
    
      

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Nova exceção ao horário de funcionamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            {/* Add date range picker */}
            <DatepickerModalBox>
              <StyledDateRangePicker
                ranges={dateRange}
                onChange={handleDateRangeChange}
                minDate={new Date()}
                showDateDisplay={false}
                months={1}
                direction="horizontal"
                rangeColors={['#3d91ff', '#3d91ff', 'rgba(61, 145, 255, 0.1)']}
              />
            </DatepickerModalBox>
            <label htmlFor="turnos">Turnos</label>
            <CustomSelectArray
              items={shifts}
              onSelectChange={handleSelectChange}
              selectedItems={selectedItems}
            />

            <>
              <label htmlFor="Salas">Salas</label>
              <CustomSelectArray
                items={zone_list}
                onSelectChange={handleSelectZones}
                selectedItems={selectedZones}
              />
            </>
            
            <label htmlFor="selectable">Limite de clientes a cada x minutos. (tempo definido nos settings)</label>
            <DropDownButton
                options={numbersList}
                handleOptionChange={handleNumberOfAcceptance}
                initialOption={numberOfAcceptance}
            />
            {/* ... other form fields ...*/}
            <ActionButtons>
            <Button variant="primary" type="submit">
              Guardar
            </Button>
            </ActionButtons>
            
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={showExceptionDaysDeleteModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Apagar turno.</Modal.Title>
        </Modal.Header>
      <Modal.Body>De certeza que quer apagar o turno?</Modal.Body>
      <Modal.Footer>
        <Button onClick={handleModalClose}>
          fechar
        </Button>
          <Button  onClick={() => handleExceptionElimination(exception_id)}>
            Apagar
          </Button>     
      </Modal.Footer>
      </Modal>
    </Wrapper>
  );
};

export default ClosedExceptions;