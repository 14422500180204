import { useCallback, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import  { Grid } from '../place_design/Grid.js'
import  { CustomDragLayer }  from '../place_design/CustomDragLayer.js'
import { TouchBackend } from 'react-dnd-touch-backend';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import SubMenu from '../components/SubMenu';
import {
  fetchPlaceOfTable,
  fetchTables,
  patchPlaceOfTable,
  patchTables,
  createPlaceOfTable,
  createTables,
  fetchConnectTablesConfig,
} from '../actions/restaurantActions';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

export const PlaceDesignScreen = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const restaurantTables = useSelector(state => state.restaurantTables);
  const { tables } = restaurantTables;

  const [snapToGridAfterDrop, setSnapToGridAfterDrop] = useState(false)
  const [snapToGridWhileDragging, setSnapToGridWhileDragging] = useState(false)

  const [selectedItem, setSelectedItem] = useState(null);

  const [draggingItem, setDraggingItem] = useState(null);

  const [width, setWidth] = useState(1);
  const [height, setHeight] = useState(1);



  useEffect(() => {
    dispatch(fetchTables(params.restaurant_id));
    dispatch(fetchPlaceOfTable(params.restaurant_id));
  }, [dispatch, params.restaurant_id]);

  const handleSnapToGridAfterDropChange = useCallback(() => {
    setSnapToGridAfterDrop(!snapToGridAfterDrop)
  }, [snapToGridAfterDrop])
  const handleSnapToGridWhileDraggingChange = useCallback(() => {
    setSnapToGridWhileDragging(!snapToGridWhileDragging)
  }, [snapToGridWhileDragging])
  return (
    <Container>
      <Row> 
      <Col sm={0} md={0} xl={4}>
        <SubMenu restaurant_id={params.restaurant_id} />
      </Col>
      <Col sm={12} md={12} xl={8}>
        <DndProvider backend={TouchBackend} options={{enableMouseEvents: true}}>
            <CustomDragLayer 
              snapToGrid={snapToGridWhileDragging} 
              selectedItem={selectedItem} 
              width={width} 
              height={height}
            />
            <Grid 
              snapToGrid={snapToGridAfterDrop} 
              selectedItem={selectedItem} 
              setSelectedItem={setSelectedItem} 
              setWidth={setWidth} 
              setHeight={setHeight}
              all_tables={tables}
              setDraggingItem={setDraggingItem}
              draggingItem={draggingItem}
            />
        </DndProvider>
        <p>
          <label htmlFor="snapToGridWhileDragging">
            <input
              id="snapToGridWhileDragging"
              type="checkbox"
              checked={snapToGridWhileDragging}
              onChange={handleSnapToGridWhileDraggingChange}
            />
            <small>Snap to grid while dragging</small>
          </label>
          <br />
          <label htmlFor="snapToGridAfterDrop">
            <input
              id="snapToGridAfterDrop"
              type="checkbox"
              checked={snapToGridAfterDrop}
              onChange={handleSnapToGridAfterDropChange}
            />
            <small>Snap to grid after drop</small>
          </label>
        </p>
      </Col>
        
      </Row>
        
    </Container>
  )
}

export default PlaceDesignScreen