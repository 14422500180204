import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import SubMenu from '../components/SubMenu';
import styled from 'styled-components';
import {getRestaurantClients} from '../actions/userActions'
import { Card, Button, Row, Col, Modal, CardText, InputGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPhone, faCopy, faGear, faEye } from '@fortawesome/free-solid-svg-icons';
import ShowCustomerInfo from '../components/ShowCustomerInfo';

import { FiCopy } from 'react-icons/fi';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const SearchBar = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid grey;
`;

const SearchIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 10px;
  top: 15px;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledCard = styled(Card)`
  margin: 10px 0;
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
  border-radius: 5px;
  overflow: hidden;
`;

const CardTop = styled.div`
  padding: 10px;
  background-color: white;
  justify-content:center;
`;

const CardBottom = styled.div`
  padding: 10px;
  background-color: #8FEBC5;
`;

const CardBottomClients = styled.div`
  padding: 3px;
  background-color: #8FEBC5;
`;
const ShowInfo = styled.div`
  padding: 4px;
  margin:2px;
  font-size: 10px;
  background-color: ${props => props.bgColor || '#8FEBC5'};
  color:white;
  border-radius:3px;
`;

const StyledRow = styled(Row)`
  height: 100%;
  align-items: center;
  width: 100%;
  position:relative;
  margin-left:0;
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justify ? props.justify : "start"};
`;

const StyledColEnd = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const Styledh6 = styled.h6`
  font-size: 1rem;
  margin-left:3px;
  padding:2px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Smallh6 = styled.h6`
  font-size: 0.8rem;
  margin: 0;
`;

const CopyConfirm = styled.span`
    position: absolute;
    bottom: 0;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    &.show {
        bottom: 10px;
        opacity: 1;
    }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const ITEMS_PER_PAGE = 25;

function RestaurantClients() {
    const params = useParams();
    const dispatch = useDispatch();
    const restaurant_id = params.id

    const [showCopyConfirm, setShowCopyConfirm] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const clients = useSelector(state => state.restaurant_clients);
    const { restaurantClients , isLoadingGetRestaurantClients, errorGetRestaurantClients} = clients;

    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);

    const [isShowCustomerInfoModalActive, setIsShowCustomerInfoModalActive] = useState(false);
    const [completePhone, setCompletePhone] = useState('');

    useEffect(()=> {
        dispatch(getRestaurantClients(restaurant_id));
    },[dispatch])

    useEffect(() => {
        if (restaurantClients) {
            const results = restaurantClients.filter(client =>
                client.client.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
                client.client.phone_number.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setSearchResults(results);
        }
        
    }, [searchTerm, restaurantClients]);

    const handleChange = event => {
        setSearchTerm(event.target.value);
    };

    const handlePrevPage = () => {
        setCurrentPage(prev => prev - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(prev => prev + 1);
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        toast('Numero de telemovel copiado', { autoClose: 1000 }) // hides after 2 seconds
    };

    const paginatedResults = searchResults.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);

    const hasPrevPage = currentPage > 1;
    const hasNextPage = currentPage < Math.ceil(searchResults.length / ITEMS_PER_PAGE);

    useEffect(()=> {
        if (paginatedResults) {
            setCurrentPage(1);
        }
    },[paginatedResults]);

    const showCustomerInfo = (phone_number) => {
        setCompletePhone(phone_number);
        setIsShowCustomerInfoModalActive(true);
        console.log('phone_number', phone_number)
    };

  return (
    <Container>
      <ToastContainer />
    <Row>
      <Col sm={0} md={4}>
       <SubMenu restaurant_id={restaurant_id} />
      </Col>
      <Col sm={12} md={8}>
      
    <StyledCard className="p-0">
      <>
      <CardTop>
        <StyledRow>
        <StyledCol xs={6} md={6}>
            <SearchBar>
            <SearchInput
                type="text"
                placeholder="Procurar"
                value={searchTerm}
                onChange={handleChange}
            />
            <SearchIcon icon={faSearch} />
            </SearchBar>
        </StyledCol>
        <StyledColEnd xs={6} md={6}>
            <p>Página {currentPage}/{Math.ceil(searchResults.length / ITEMS_PER_PAGE)}</p>
        </StyledColEnd>

        </StyledRow>
      </CardTop>
      <CardBottom>
      </CardBottom>
      </>
    </StyledCard>

    {searchResults ?
            paginatedResults.map((client) => (
            <StyledCard key={client.id}>
                <StyledRow>
                    <StyledCol xs={4} md={4}>
                        <Styledh6 
                          style={{cursor:'pointer'}}
                          onClick={() => showCustomerInfo(client.client.phone_number)}
                        >
                        <FontAwesomeIcon 
                          size='lg' 
                          icon={faEye} 
                          style={{ paddingRight:'5px'}}
                        />
                        {client.client.name}
                        </Styledh6>
                        
                    </StyledCol>
                    <StyledCol xs={8} md={4}>
                        <Styledh6>
                        {client.client.phone_number} {" "}
                        <a href={`tel:${client.client.phone_number}`}>
                            <FontAwesomeIcon icon={faPhone} style={{ marginRight: "10px", cursor: "pointer" }} />
                        </a>
                        <FontAwesomeIcon icon={faCopy} style={{ cursor: "pointer" }} onClick={() => copyToClipboard(client.client.phone_number)} />
                        <CopyConfirm className={showCopyConfirm ? "show" : ""}>Copiado!</CopyConfirm>
                        </Styledh6>
                    </StyledCol>
                    <StyledCol xs={8} md={4}>
                        <ShowInfo bgColor="#44bd6a">Reservas: {client.number_of_reservations} </ShowInfo>
                        <ShowInfo bgColor="#FF0000">No show {client.did_not_show_up_times} </ShowInfo>
                        <FontAwesomeIcon
                          size='lg'
                          onClick={() => showCustomerInfo(client.client.phone_number)} 
                          icon={faGear} 
                          style={{cursor:'pointer'}}
                        /> 
                    </StyledCol>
                        

                </StyledRow>
               
                <CardBottomClients></CardBottomClients>
            </StyledCard>
            ))
         
        :
        <></>
        }
        <div>
            <button className='btn' onClick={handlePrevPage} disabled={!hasPrevPage}>Anterior</button>
            <button className='btn' onClick={handleNextPage} disabled={!hasNextPage}>Próxima </button>
        </div>
      </Col>
    </Row>
    <ShowCustomerInfo
      phone_number={completePhone}
      restaurant_id={restaurant_id}
      isModalActive={isShowCustomerInfoModalActive}
      setIsModalActive={setIsShowCustomerInfoModalActive}
    />
    
    </Container>
    
  )
}

export default RestaurantClients