import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Row, Col, Card, Modal, Form, Button } from 'react-bootstrap';
import EstimatedTimePage from '../components/EstimatedTimeCustomersSpend';
import styled from 'styled-components';
import SubMenu from '../components/SubMenu';
import Separator from '../components/Separator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faChair, faGear, faCircleXmark, faEnvelope, faSquarePhoneFlip, faKey, faMessage  } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import {getDialCode, getCountryCode} from '../components/CountryCode';
import WaitCheckMark  from '../components/WaitCheckMark';
import SwitchOnAndOff  from '../components/SwitchOnAndOff'
import { getRestaurantNotificationsList,
         createRestaurantNotificationsList,
         updateRestaurantNotificationsList,
         fetchRestaurantDetails
         } from '../actions/restaurantActions';
import { api } from '../App';

import 'react-phone-input-2/lib/style.css'; // Import the default CSS for react-phone-input-2
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css'; // Import the Bootstrap CSS for additional styling

const InputOverlay = styled.input`
  position: absolute;
  top: 33px;
  font-size:16px;
  left: 105px; 
  height: 36px;
  border: none;
  border-radius: 0 3px 0 0;
  background: none;
  outline: none;
  background-color:none;
  padding-bottom:4px;
`;



const StyledPhoneInput = styled(PhoneInput)`
  .form-control {
    max-width: 100%;
    box-sizing: border-box;
    overflow:hidden;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const StyledModal = styled(Modal)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -42%);
`;

const ModernButton = styled.button`
  padding: 5px 8px;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0056b3;
  }
`;

const Wrapper = styled.div`
  position: relative;
  margin: 10px 0;
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
  border-radius: 5px;
  color: black;
  border: 1px solid #e8e6e1;
`;

const WrapperTop = styled.div`
  width:100%;
  color:white;
  height:60px;
  background-color:#8FEBC5;
  border-radius: 5px 5px 0 0; 
  margin-bottom:10px;
`;

const WrapperBot = styled.div`
  width:100%;
  height:auto;
  padding:10px;
`;


const Title = styled.h6`
  padding: 20px;
  color: white;
`;

const CreateButton = styled(ModernButton)`
  position: absolute;
  top: 11px;
  right: 11px;
  background-color: #59d9d4;
  &:hover {
    background-color: #228a86;
  }
`;

const B2 = styled.div`
  background-color: #f8f9fa;
  position:relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`;

const B2Top = styled.div`
  background-color: #58c49f;
  height:30px;
  color:white;
  width:100%;
`;

const B2Bot = styled.div`
  padding: 10px;
  max-height: 250px;
  width: 100%;
  overflow: auto;
  
  /* this is the default color of the scrollbar */
  scrollbar-color: #888 #f2f2f2;
  
  /* this is the width of the scrollbar */
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    /* this is the width of the scrollbar */
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    /* this is the color of the track, where the scrollbar moves */
    background: #f2f2f2;
  }

  &::-webkit-scrollbar-thumb {
    /* this is the color of the scrollbar itself */
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    /* this is the color of the scrollbar when hovered */
    background: #555;
  }
`;

const ZoneTitle = styled.h2`
  color:white;
  font-size:18px;
  padding: 5px;
`;

const TopLeft = styled.div`
    position:absolute;
    top:5px;
    left:5px;
    color:white;
    font-size:18px;
    padding: 5px;
`;

const DangerButton = styled(Button)`
  background-color: #ff704d;
  border-color: #ff704d;

  &:hover {
    background-color: #ff471a;
    border-color: #ff471a;
  }
`;

const RemoveButton = styled(DangerButton)`
  position: absolute;
  top: -4px;
  right: 1px;
  width: 20px;
  height: 20px;
  padding: 3px;
  font-size: 18px;
  text-align: center;
  line-height: 5px;
  
`;

const AbsoluteKeySquare = styled.div`
  position: absolute;
  display:flex;
  justify-content:center;
  align-items:center;
  border-radius: 4px 0 0 4px;
  background-color: #f2f2f2;
  border: solid 1px #cfcfcf;
  top: 0px;
  left: 0px;
  width: 50px;
  height: 38px;
  padding: 1px;
  font-size: 18px;
  text-align: center;
  &:hover {
    background-color: #e8e8e8;
    border-color: #76d6c5;
  }
`;

const InputOverlayForToken = styled.input`
  position: absolute;
  top: 0px;
  font-size:16px;
  left: 49px;
  width:85%;
  height: 37px;
  border: none;
  border-radius: 0 3px 0 0;
  background: none;
  outline: none;
  background-color:none;
  padding-bottom:4px;
  padding-left:10px;
`;

const CheckboxInput = styled.input`
  margin-left: 10px;
  padding-top: 10px;
  cursor: pointer;
`;

function RestaurantNotifications() {

    const dispatch = useDispatch();
    const params = useParams();
    const restaurant_id = params.restaurant_id
    const [itemId, setItemId] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [emailWM, setEmailWM] = useState(false);
    const [phoneWM, setPhoneWM] = useState(false);
    const [email_or_whatsapp, setEmail_or_whatsapp] = useState('');
    const [update_or_create, setUpdate_or_create] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [isWaiting, setIsWaiting] = useState(false);

    // comunication types
    const [useEmail, setUseEmail] = useState(false);
    const [useSms, setUseSms] = useState(false);
    const [useWhatsapp, setUseWhatsapp] = useState(false);

    const [isCheckedSendAcceptance, setIsCheckedSendAcceptance] = useState(false);
    const [isCheckedSendCancel, setIsCheckedSendCancel] = useState(false);
    const [isCheckedSendCodeToConfirm, setIsCheckedSendCodeToConfirm] = useState(false);
    const [isCheckedSendReminder, setIsCheckedSendReminder] = useState(false);    

    const [countryCode, setCountryCode] = useState("us");
    const [countryCodeNumber, setCountryCodeNumber] = useState('351');
    const [phone, setPhone] = useState('');
    const [phoneWithoutCountryCode, setPhoneWithoutCountryCode] = useState('');
    const [whatSappToken, setWhatSappToken] = useState('');

    const [emailNotifications, setEmailNotifications] = useState({});
    const [WhatsappNotifications, setWhatsappNotifications] = useState({});

    const notifications_list = useSelector(state => state.restaurantNotifications)
    const {  restaurantNotifications ,isLoadingRestaurantNotifications, restaurantNotificationsError } = notifications_list

    const restaurant_info = useSelector(state => state.restaurantDetails)
    const {  restaurants } = restaurant_info
    
    useEffect(() =>{
        dispatch(getRestaurantNotificationsList(restaurant_id));
        dispatch(fetchRestaurantDetails(restaurant_id));
    },[])

    useEffect(() => {
        const timer = setInterval(() => {
            dispatch(getRestaurantNotificationsList(restaurant_id));
        }, 15000);
        return () => clearInterval(timer);
      }, [dispatch, restaurant_id]);

    useEffect(() => {
        if (restaurantNotifications) {
            const whatsappFiltered = restaurantNotifications.filter(
                (notification) => notification.phone_number !== ""
            );
            const emailFiltered = restaurantNotifications.filter(
                (notification) => notification.email !== ""
            );
            setWhatsappNotifications(whatsappFiltered);
            setEmailNotifications(emailFiltered);
        }
    }, [restaurantNotifications]);


    useEffect(() => {
        if (countryCodeNumber === '') {
          const detectedCountryCode = detectCountryCode();
          setCountryCode(detectedCountryCode);
          setCountryCodeNumber(getDialCode(detectedCountryCode));
        } else {
          setCountryCode(getCountryCode(countryCodeNumber));
        }
        
      }, [countryCodeNumber]);
  
      const detectCountryCode = () => {
        const userCountryCode = navigator.language.split("-")[1];
        const formattedCountryCode = userCountryCode ? userCountryCode.toLowerCase() : "us";
        // Add more specific checks if needed based on the origin or location detection mechanism
        return formattedCountryCode; // Default to 'PT' if the country code is not detected
      };
  
      useEffect(() => {
        // Check if countryCode and phone are not null or undefined before concatenating
        if (countryCodeNumber && phoneWithoutCountryCode) {
          setPhone(String(countryCodeNumber) + String(phoneWithoutCountryCode));
        }
      }, [phoneWithoutCountryCode, countryCodeNumber]);
  
      const MAX_CHARACTER_LIMIT = 10; 
      
      const handleInputPhoneChange = (e) => {
        const inputValue = e.target.value;
    
        if (inputValue.length <= MAX_CHARACTER_LIMIT) {
          setPhoneWithoutCountryCode(inputValue);
        }
      };

      const handleUpdate = () => {
        setUpdate_or_create('update')
        if (phone !== ''){
          let phoneWithoutCountryCoda;
            if (phone.length > 5) {
              const countryCodeLength = countryCodeNumber.length;
              let phoneNumber = phone;
          
              // Remove leading plus if it exists
              if (phoneNumber[0] === '+') {
                phoneNumber = phoneNumber.slice(1);
              }
          
              // Remove leading country code number
              phoneWithoutCountryCoda = phoneNumber.slice(countryCodeLength);
            }
          setIsWaiting(true);
          setShowModal(false);
          setTimeout(() => {
            setIsWaiting(false);
          }, 1500);
        }
    };
      
    const handleCreate = () => {
        setIsWaiting(true);
        console.log('restaurant_id: ', restaurant_id)
        console.log('email: ', email)
        console.log('countryCodeNumber: ', countryCodeNumber)
        console.log('phoneWithoutCountryCode: ', phoneWithoutCountryCode)
        console.log('countryCode: ', countryCode)
        console.log('whatSappToken: ', whatSappToken)
        let params;
        let advanceIfNoErrors = true;
        if (email_or_whatsapp === 'email'){
            if (email === '') {
                setEmailWM(true);
                advanceIfNoErrors = false;
            } else{
                setEmailWM(false);
            }
            if (advanceIfNoErrors) {
                params = {
                    'restaurant':  parseInt(restaurant_id, 10),
                    'email': email,
                    'phone_number': '',
                    'country_code': '',
                    'getEmails': true,
                    'getWhatsappNotifications': false,
                    'whatsapp_notification_token': '',
                    'isItConfirmed': false,
                }
                dispatch(createRestaurantNotificationsList(restaurant_id, params))
                setShowModal(false);
                setIsWaiting(true);
                    setTimeout(() => {
                    setIsWaiting(false);
                    setShowWarningModal(true);
                }, 1500);
            }
            
        } else if (email_or_whatsapp === 'whatsapp') {
            if (phone === '') {
                setPhoneWM(true);
                advanceIfNoErrors = false;
            } else{
                setPhoneWM(false);
            }

            if (advanceIfNoErrors) {
                params = {
                    'restaurant':  parseInt(restaurant_id, 10),
                    'email': '',
                    'phone_number': phoneWithoutCountryCode,
                    'country_code': countryCodeNumber,
                    'getEmails': false,
                    'getWhatsappNotifications': true,
                    'whatsapp_notification_token': whatSappToken,
                    'isItConfirmed': false,
                }
                console.log('whatsapp dispatch activated')
                dispatch(createRestaurantNotificationsList(restaurant_id, params));
                setShowModal(false);
                setTimeout(() => {
                    setIsWaiting(false);
                    setShowWarningModal(true);
                }, 1500);
            }
            
        }
    };

    const handleShowModal = (create_what, create_or_update, 
                             item_id, item_phone,
                             item_email, item_whatsapp_token, ) => {
        if (create_or_update === 'create'){
            setItemId('');
            setPhone('');
            setPhoneWithoutCountryCode('');
            setEmail('');
            setWhatSappToken('');
            setUpdate_or_create('create')
        } else {
            setItemId(item_id);
            setPhone(item_phone);
            setPhoneWithoutCountryCode(item_phone);
            setEmail(item_email);
            setWhatSappToken(item_whatsapp_token);
            setUpdate_or_create('update')
        }
        if (create_what === 'whatsapp') {
            setEmail_or_whatsapp('whatsapp');
        } else {
            setEmail_or_whatsapp('email');
        }
        setShowModal(true);
    };

    const handleDeleteModalOpen = (value) => {

        console.log('value: ',value )
        setShowDeleteModal(true);
        setItemId(value)
      }

    const handleClose = () => setShowModal(false);

    const handleExceptionElimination = async () => {
        setIsWaiting(true);
        setTimeout(() => {
            setIsWaiting(false);
        }, 1500);
        try {
          const response = await api.delete(
            `/restaurants/${restaurant_id}/restaurant_notifications/${itemId}/`
          );
          console.log(response.data);
        } catch (error) {
          console.error(error);
        }
        setShowDeleteModal(false);
        dispatch(getRestaurantNotificationsList(restaurant_id));
      };

      const handleUseEmail = () => setUseEmail(prevState => !prevState);
      const handleUseSms = () => setUseSms(prevState => !prevState);
      const handleUseWhatsapp = () => setUseWhatsapp(prevState => !prevState);

      useEffect(() => {
        console.log('useEmail: ', useEmail)
        console.log('useWhatsapp: ', useWhatsapp)
        console.log('useSms: ', useSms)
      },[useEmail, useWhatsapp, useSms])
  
  return (
    <Container>
        <Row>
            <Col sm={0} md={4}>
            <SubMenu restaurant_id={restaurant_id} />
            </Col>
            <Col  sm={12} md={8}>
              {restaurants &&
                  <Separator
                  step={restaurants.restaurant_name}
                  stepName={restaurants.restaurant_name}
                />
              }
                
                <div style={{borderBottom:'1px solid black', fontSize:'20px'}}>Notificações para clientes</div>
                <div style={{display:'flex', flexWrap:'nowrap', marginTop:'10px'}}>
                  <div style={{paddingRight:'10px'}}>
                    Email
                  </div>
                    <SwitchOnAndOff 
                      isOn={useEmail} 
                      handleToggle={() => setUseEmail(!useEmail)}
                      id='1'/>
                </div>
                <div style={{display:'flex', flexWrap:'nowrap', marginTop:'10px'}}>
                  <div style={{paddingRight:'10px'}}>
                    SMS
                  </div>
                  <SwitchOnAndOff 
                    isOn={useSms} 
                    handleToggle={() => setUseSms(!useSms)}
                    id='2'/>
                </div>
                <div style={{display:'flex', flexWrap:'nowrap', marginTop:'10px'}}>
                  <div style={{paddingRight:'10px'}}>
                    Whatsapp
                  </div>
                  <SwitchOnAndOff 
                    isOn={useWhatsapp} 
                    handleToggle={() => setUseWhatsapp(!useWhatsapp)}
                    id='3'/>
                </div>
                <Wrapper>
                    <TopLeft>
                        <FontAwesomeIcon size="xl" icon={faMessage} style={{color: "#ffffff",}} /> Mensagens
                    </TopLeft>
                    <WrapperTop>
                      
                    </WrapperTop>
                    <WrapperBot>
                    <div>
                      Confirmação de contacto
                      <CheckboxInput
                        type="checkbox"
                        checked={isCheckedSendCodeToConfirm}
                        onChange={() => setIsCheckedSendCodeToConfirm(!isCheckedSendCodeToConfirm)}
                      />
                    </div>
                    <div>
                      Aceitação
                      <CheckboxInput
                        type="checkbox"
                        checked={isCheckedSendAcceptance}
                        onChange={() => setIsCheckedSendAcceptance(!isCheckedSendAcceptance)}
                      />
                    </div>
                    <div>
                      Cancelamento
                      <CheckboxInput
                        type="checkbox"
                        checked={isCheckedSendCancel}
                        onChange={() => setIsCheckedSendCancel(!isCheckedSendCancel)}
                      />
                    </div>
                    
                    <div>
                      Mensagem de reconfirmação de reserva
                      <CheckboxInput
                        type="checkbox"
                        checked={isCheckedSendReminder}
                        onChange={() => setIsCheckedSendReminder(!isCheckedSendReminder)}
                      />
                    </div>

                    </WrapperBot>
                </Wrapper>
                <div style={{borderBottom:'1px solid black', fontSize:'20px'}}>Notificações para o restaurante</div>
                <Wrapper>
                    <TopLeft>
                        <FontAwesomeIcon size="xl" icon={faWhatsapp} style={{color: "#ffffff",}} /> Whatsapp
                    </TopLeft>
                    <CreateButton onClick={() => handleShowModal('whatsapp', 'create')}>
                        <FontAwesomeIcon size="xl" icon={faPlus} />
                    </CreateButton>
                    <WrapperTop>
                        
                    </WrapperTop>
                    <WrapperBot>
                    {WhatsappNotifications.length > 0 ?
                        WhatsappNotifications.map((notification) => (
                            <B2 key={notification.id}>
                                <B2Top>
                                    <ZoneTitle>
                                        {notification.phone_number}
                                    </ZoneTitle>
                                </B2Top>
                                <B2Bot>
                                    <RemoveButton onClick={() => handleDeleteModalOpen(notification.id)} style={{marginRight: '10px', marginTop:'8px'}}>x</RemoveButton>
                                    <h5 style={{fontSize:'16px', display:'flex', flexWrap:'wrap'}}>Status: {notification.isItConfirmed === true ? 
                                    <div style={{color:'green', width:'auto',paddingLeft:'10px'}}>Confirmado</div> : 
                                    <div  style={{color:'#b5af04', width:'auto',paddingLeft:'10px'}}>Aguarda confirmação</div>}</h5>
                                </B2Bot>
                            </B2>
                        ))
                        :
                        <> Nenhum whatsapp adicionado </>
                    }
                    </WrapperBot>
                </Wrapper>

                {/* Email Notifications */}
                <Wrapper>
                    <TopLeft>
                        <FontAwesomeIcon size="xl" icon={faEnvelope} style={{color: "#ffffff",}} /> Email
                    </TopLeft>
                    <CreateButton onClick={() => handleShowModal('email', 'create')}>
                        <FontAwesomeIcon size="xl" icon={faPlus} />
                    </CreateButton>
                    <WrapperTop>
                        
                    </WrapperTop>
                    <WrapperBot>
                        {emailNotifications.length > 0 ?
                            emailNotifications.map((notification) => (
                                <B2 key={notification.id}>
                                    <B2Top>
                                        <ZoneTitle>
                                            {notification.email}
                                        </ZoneTitle>
                                    </B2Top>
                                    <B2Bot>
                                        <RemoveButton onClick={() => handleDeleteModalOpen(notification.id)} style={{marginRight: '10px', marginTop:'8px'}}>x</RemoveButton>
                                        <h5 style={{fontSize:'16px', display:'flex', flexWrap:'wrap'}}>Status: {notification.isItConfirmed === true ? 
                                        <div style={{color:'green', width:'auto',paddingLeft:'10px'}}>Confirmado</div> : 
                                        <div  style={{color:'#b5af04', width:'auto',paddingLeft:'10px'}}>Aguarda confirmação</div>}</h5>
                                    </B2Bot>
                                </B2>
                            ))
                            :
                            <> Nenhum email adicionado</>
                        }
                    </WrapperBot>
                </Wrapper>
                
                 
            </Col>
        </Row>

        <StyledModal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title style={{marginLeft: '10px'}}>
                {email_or_whatsapp === 'whatsapp' ?
                    <>Notificação Whasapp </>
                :   
                    <>Notificação Email</>
                }
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                <Form>
                {email_or_whatsapp === 'email' ?
                    <Form.Group className="mb-3" controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <div style={{ position: 'relative' }}>
                            <Form.Control required type="email" value={email} onChange={(e) => setEmail(e.target.value)} style={{paddingLeft:'59px'}}/>
                            <AbsoluteKeySquare>
                                <FontAwesomeIcon size='lg' icon={faEnvelope} style={{color: "#78d9b8",}} />
                            </AbsoluteKeySquare>
                        </div>
                        {emailWM && <p style={{color:'red'}}>Favor preencher Email</p>}
                    </Form.Group>
                :
                <>
                    <Form.Group>
                        <div style={{ position: 'relative' }}>
                            <Form.Label>Telemóvel</Form.Label>
                            <StyledPhoneInput
                            country={countryCode}
                            onChange={setCountryCodeNumber}
                            inputClass="form-control"
                            placeholder="Número de telemóvel"
                            inputProps={{
                            name: 'phone',
                            required: true,
                            readOnly: true, // make the input field read-only
                            position:'relative',
                            }}
                            > 
                            </StyledPhoneInput>
                            <InputOverlay maxlength={10} value={phoneWithoutCountryCode} onChange={handleInputPhoneChange}/>
                            <FontAwesomeIcon size='xl' icon={faSquarePhoneFlip} style={{color: "#48c778", position: 'absolute', top: '39px', left: '270px'}} />
                        </div>
                        {phoneWM && <p style={{color:'red'}}>Favor preencher telemóvel</p>}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="forWhatsappToken">
                        <Form.Label>Token</Form.Label>
                        <div style={{ position: 'relative' }}>
                            <Form.Control 
                                inputProps={{
                                    name: 'phone',
                                    required: true,
                                    readOnly: true, // make the input field read-only
                                    position:'relative',
                                    }}
                            />
                            <InputOverlayForToken value={whatSappToken} onChange={(e) => setWhatSappToken(e.target.value)}/>
                            <AbsoluteKeySquare>
                                <FontAwesomeIcon icon={faKey} style={{color: "#78d9b8",}} />
                            </AbsoluteKeySquare>
                        </div>
                        <p>Precisa de um token para registrar o seu whatsapp, pode encontrar esse token em https://user.ultramsg.com/</p>
                        
                        
                    </Form.Group>
                </>
                    
                
                }
                </Form>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Fechar
                </Button>
                {update_or_create === 'create' ? 
                <Button variant="primary" onClick={handleCreate}>
                   Adicionar <FontAwesomeIcon size="lg" icon={faPlus} style={{marginLeft:'5px'}}/>
                </Button>
                :
                <Button variant="primary" onClick={handleUpdate}>
                    Guardar
                </Button>
              }
                
            </Modal.Footer>
            </StyledModal>

            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(!showDeleteModal)}>
                <Modal.Header closeButton>
                <Modal.Title>Apagar Notificação</Modal.Title>
                </Modal.Header>
            <Modal.Body>De certeza que quer apagar a Notificação para este contacto?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowDeleteModal(!showDeleteModal)}>
                fechar
                </Button>
                <Button variant="danger" onClick={() => handleExceptionElimination()}>
                    Apagar
                </Button>     
            </Modal.Footer>
            </Modal>

            <Modal show={showWarningModal} onHide={() => setShowWarningModal(!showWarningModal)}>
                <Modal.Header closeButton>
                <Modal.Title>Apagar Notificação</Modal.Title>
                </Modal.Header>
            <Modal.Body>
                Vai receber um link para confirmar:
                <p> 
                      {email_or_whatsapp === 'whatsapp' ?
                      <>
                        <FontAwesomeIcon size='xl' icon={faSquarePhoneFlip} style={{color: "#48c778",marginRight:'10px'}} />
                        {phone}
                      </>
                      :
                      <>
                        <FontAwesomeIcon size='lg' icon={faEnvelope} style={{color: "#78d9b8",marginRight:'10px'}} />
                        {email}
                      </>}  
                </p>
                <p>{'(caso o seu contacto esteja errado por favor tente novamente.)'}</p>
            </Modal.Body>
            <Modal.Footer>
                <div  style={{width:'100%' , display:'flex', justifyContent:'center'}}>
                <Button variant="success" onClick={() => setShowWarningModal(!showWarningModal)}>
                    ok
                </Button> 
                </div>
                  
            </Modal.Footer>
            </Modal>

            {isWaiting && <WaitCheckMark/>}
    </Container>
  )
}

export default RestaurantNotifications