import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col  } from 'react-bootstrap';
import styled from 'styled-components';
import SubMenu from '../components/SubMenu';
import {
    fetchConnectTablesConfig,
  } from '../actions/restaurantActions';
import ConnectedTablesBox from '../components/ConnectedTablesBox'; 
import Loader from '../components/Loader'

// Styled components
const Wrapper = styled.div`
  position: relative;
  margin: 10px 0;
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
  border-radius: 5px;
  color: black;
  border: 1px solid #e8e6e1;
`;

const WrapperTop = styled.div`
  width:100%;
  color:white;
  height:60px;
  background-color:#8FEBC5;
  border-radius: 5px 5px 0 0; 
  margin-bottom:10px;
`;

const WrapperBot = styled.div`
  width:100%;
  height:auto;
  padding:10px;
`;

const Title = styled.h6`
  padding: 20px;
  color: white;
`;

function RestaurantConnectedTables({restaurant_id=false, currentStep=false}) {
    const dispatch = useDispatch();
    const params = useParams();
    const [restaurantId, setRestaurantId] = useState(restaurant_id ? restaurant_id : params.restaurant_id);

    const connectTables = useSelector(state => state.connectTablesConfig);
    const { connectTablesConfig = [] } = connectTables;
  
    useEffect(() => {
      dispatch(fetchConnectTablesConfig(restaurantId));
    }, [dispatch, restaurantId]);


  return (
    <>
    <Row>
      <Col sm={0} md={0} xl={4}>
        {!currentStep && <SubMenu restaurant_id={restaurantId} />}
      </Col>
      <Col sm={12} md={12} xl={8}>
      {currentStep === 'connect_tables_rules' || currentStep === false ?
            <Wrapper>
            <WrapperTop>
            <Title>Conexão de mesas</Title>
            </WrapperTop>
            <WrapperBot>
                {Array.isArray(connectTablesConfig) ? (
                <ConnectedTablesBox 
                connectedTablesArray={connectTablesConfig}
                restaurant_id={restaurantId} 
                />
                ) : (
                <Loader></Loader>
                )}
            </WrapperBot>
            
            </Wrapper>
        :
            <></>
        }
      </Col>
    </Row>
       
    </>
    
  )
}

export default RestaurantConnectedTables