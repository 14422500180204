import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import Loader from '../components/Loader'
import { fetchRestaurantDetails } from '../actions/restaurantActions'
import Messages from '../components/Messages'

function RestaurantFromOwnerScreen({ }) {
  const  restaurantID  = useParams();
  const restaurantDetails = useSelector(state => state.restaurantDetails)
  const {  restaurants ,isLoading, error } = restaurantDetails
  const dispatch = useDispatch()

  useEffect(()=> {
      dispatch(fetchRestaurantDetails(restaurantID.id));
  }, [dispatch, restaurantID.id])
  
  return (
    <div>
      RestaurantFromOwner
      {isLoading ? 
          <h2><Loader></Loader></h2>
      :restaurants? 
        <Row>
        {restaurants.map(restaurant => (
          <Col key={restaurant.id} sm={12}>
              <h1>{restaurant.restaurant_name}</h1>
          </Col>
        ))}
        </Row>
      : error  ? 
      <h2><Messages variant='danger'>{error}</Messages></h2>
      :
      <h2>notting to be found</h2>
      }
    </div>
  )
}

export default RestaurantFromOwnerScreen