import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Form, Button, Row, Col, Card } from 'react-bootstrap';
import styled from 'styled-components';
import { faCalendar, faUserGroup, 
         faSun, faMap, faClock, faPlus, 
         faMinus, faMessage, faBabyCarriage, 
         faWheelchair, faFilePen, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import ChooseTime from '../components/ChooseTime'

const TableBox = styled.div`
  display: inline-block;
  margin: 1px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  background-color: #3ECF95;
  color: white;
  border: 1px solid gray;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  box-sizing: border-box; 
`;

const AlertBox = styled.div`
  padding: 5px;
  font-size:13px;
  color:black;
  border-radius:5px;
  background-color:#ffef5c;
  border: solid 1px black;
  width:180px;
  margin:5px;
`;

function StepTwoReservations({
        startDate, DivFlexStart, DateButton, handleOpenDateModal,
        DropDownButton, limitPeopleBaby, handlePeopleChange,
        numPeople, openTime, CustomSelect, handleShiftChange,
        shifts, setShift,placeOfTable, setPlaceOfTable, SelectablePopUpOptions,
        handlePlaceOfTable, handleTimeChange, time, setTime, Loader, fullRestaurant,
        DivFlexMiddle, Messages, ActionButtons, goBack, goForward, buttonsFalse, 
        true_create_false_edit, editingStartFalse, setEditingStartFalse, from_restaurant,
        tablesUsed, token, handleShowTablesModal, ignoreLimitations, setIgnoreLimitations,
        LimitationsExtras,  placeOfTableWS, timeWS, reservation, update, setUpdate
}) { 
    const all_tables = useSelector(state => state.restaurantTables.tables);
    const [numbersList, setNumbersList] = useState([...Array(300).keys()].map(n => n + 1));
  return (
    <>
        <Row style={{width:'100%'}}>
            <Col xs={12} md={5}>
                <DivFlexStart>
                <FontAwesomeIcon icon={faCalendar} size='xl' style={{ marginRight: '12px',}}/>
                <DateButton 
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} 
                    onClick={handleOpenDateModal}
                    >
                    {startDate !== null ?
                        <>
                        {`${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth()+1).toString().padStart(2, '0')}/${startDate.getFullYear()}`}
                        </>
                        :
                        <div style={{
                            backgroundColor: '#dbc500', 
                            color: 'white', 
                            flex: 1, 
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                            height:'110%',
                            fontSize:'18px'
                        }}>
                        Escolher data
                        </div>
                    }
                    </DateButton>
                </DivFlexStart>
            </Col>
            {startDate !== null &&
                <Col xs={12} >
                <Row>
                    <Col xs={12}  className='mt-3'>
                        <DivFlexStart>
                            <Row>
                                <Col xs={12} style={{display:'flex' ,flexWrap:'wrap'}}>
                                    <FontAwesomeIcon size='xl' icon={faUserGroup} style={{ marginRight: '8px',paddingTop:'5px'}} />
                                    {limitPeopleBaby && 
                                        <DropDownButton
                                        options={from_restaurant ? numbersList :limitPeopleBaby.reservation_numbers}
                                        handleOptionChange={handlePeopleChange}
                                        initialOption={numPeople}
                                        isPeople={true}
                                        setUpdate={setUpdate}
                                        update={update}
                                    />
                                    }
                                </Col>
                            </Row>
                           
                            
                            
                        </DivFlexStart>
                    </Col>
                    {from_restaurant &&
                        <Col xs={12}>
                        <LimitationsExtras ignoreLimitations={ignoreLimitations} onClick={() => setIgnoreLimitations(!ignoreLimitations)}>
                            Ignorar todas as limitações <FontAwesomeIcon size="xl" icon={faTriangleExclamation} style={ ignoreLimitations ? {color: "#ffffff",} : {color: "#62e3b4",}}/>
                        </LimitationsExtras>
                        <p style={{fontSize: '12px'}}>{'(Caso opte por ignorar todas as limitações tem de escolher as mesas manualmente)'}</p>
                        </Col>
                    }
                    
                    {openTime && Array.isArray(openTime.shifts) && Array.isArray(openTime.zones) ?
                    <>
                        {/*
                            <Col xs={12}  className='mt-3'>
                                <DivFlexStart>
                                <FontAwesomeIcon size='xl' icon={faSun} style={{ marginRight: '10px', color:'#f5bf42'}} />
                                
                                <div>
                                <CustomSelect
                                    items={openTime.shifts}
                                    onSelectChange={handleShiftChange}
                                    selectedItem={shifts}
                                    setItem={setShift}
                                />
                                </div>
                                </DivFlexStart>
                            </Col> */}
                            {numPeople !== 0 &&
                                <Col Col xs={12}  className='mt-3'>
                                    <DivFlexStart>
                                    <FontAwesomeIcon size='xl' icon={faMap} style={{ marginRight: '5px',}} />
                                    <div>
                                    <CustomSelect
                                        items={openTime.zones}
                                        onSelectChange={handlePlaceOfTable}
                                        selectedItem={placeOfTable}
                                        setUpdate={setUpdate}
                                        update={update}
                                    />
                                    </div>
                                    </DivFlexStart>
                                    {placeOfTableWS && <p style={{color: 'red'}}> Selecionar Zona</p>}
                                </Col>
                            }
                            <Col xs={12}  className='mt-3'>
                            <DivFlexStart>
                                    <ChooseTime
                                        options={openTime.opentime_per_zone} 
                                        onTimeChange={handleTimeChange} 
                                        time={time} 
                                        setTime={setTime}
                                        enableFullFlag={true}
                                        currentShift={shifts}
                                        currentZone={placeOfTable}
                                        editingStartFalse={editingStartFalse}
                                        setShift={setShift}
                                        reservation={reservation}
                                        update={update}
                                        from_restaurant={from_restaurant}
                                        true_create_false_edit={true_create_false_edit}
                                    />
                                </DivFlexStart>
                                {timeWS && <p style={{color: 'red'}}> Selecionar hora</p>}
                            </Col>
                            
                        {numPeople !== 0 && 
                            <>
                                {((token != '' && from_restaurant && Array.isArray(tablesUsed)) || ignoreLimitations)  && 
                                <Col xs={12}  className='mt-3 d-flex' style={{gap: '3px', flexWrap:'wrap'}}>
                                    {ignoreLimitations ? 'Escolher mesas: ' : 'Mesas: '} 
                                    <>
                                    {(Array.isArray(tablesUsed) && tablesUsed[0] != '' && tablesUsed.length > 0) ?
                                        <>
                                            {tablesUsed
                                                .map(tableNumber => parseInt(tableNumber, 10))  // Convert to integers
                                                .sort((a, b) => a - b)  // Sort in ascending order
                                                .map((tableNumber, index) => {
                                                    const tableObj = all_tables.find(table => table.id === tableNumber);
                                                    return (
                                                        <TableBox key={index} tableNumber={tableNumber}>
                                                            {tableObj ? tableObj.table_name : `Table ${tableNumber} not found`}
                                                        </TableBox>
                                                    );
                                            })}
                                        </>
                                    :
                                        <div style={{color: 'red'}}>
                                            Não tem mesas selecionadas
                                        </div>
                                    }
                                    </>
                                    <FontAwesomeIcon size='xl' icon={faFilePen} style={{color: "gray", cursor: 'pointer'}} onClick={handleShowTablesModal}/>
                                </Col>
                                }
                            </>
                         }
                        
    
                    </>
                    :
                    <Col className='mt-4' xs={12}>
                        <DivFlexMiddle>
                        <Loader/>
                        </DivFlexMiddle>
                    </Col>
                    }  
            </Row>
    
            </Col>
            }
            
        </Row>
        {fullRestaurant?
        <div>
            <Messages variant='danger'>
            <h6 style={{color: 'white'}}>Completamente Lotado</h6> 
            <p>{'(O restaurante encontra-se cheio para o numero de pessoas indicado)'}</p>
            </Messages>
        </div>
            : <div></div>}
            {!buttonsFalse &&  
            <>
                <ActionButtons>
                    <Button className='mt-3 mr-3' onClick={goBack}>Voltar</Button>
                    <Button className='mt-3 ml-3' onClick={goForward}>Próximo</Button>
                </ActionButtons>
            </>
            }
        
    
    </>
  )
}

export default StepTwoReservations