import React, { useState, useEffect } from 'react';
import { Modal, Carousel, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getTutorialSteps } from '../actions/restaurantActions'; 
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft, faCircleArrowRight, faCircleChevronLeft, faCircleChevronRight } from '@fortawesome/free-solid-svg-icons';

const CenteredTitle = styled.h5`
    text-align: center;
`;

const CenteredText = styled.p`
    text-align: center;
`;

const WhiteImage = styled.div`
    padding: 10px;
    background-color: white;
    width: 80%; // Adjusted width
    margin: 0 auto; // Centered text
`;

// Create custom arrow components using FontAwesome
const CustomNextIcon = () => <div style={{
  padding: '10px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  opacity: '0.8'
}}><FontAwesomeIcon icon={faCircleChevronRight} size="2xl" style={{color: "#1be4c3",}} /></div>;

const CustomPrevIcon = () => <div style={{
  padding: '10px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  opacity: '0.8'
}}><FontAwesomeIcon icon={faCircleChevronLeft} size="2xl" style={{color: "#1be4c3",}} /></div>;

const TutorialSteps = ({ step, preSelectedTitle, showModal, setShowModalFalse }) => {
    const dispatch = useDispatch();

    const tutorialStep = useSelector(state => state.tutorial_steps);
    const { tutorialSteps, isLoadingTutorialSteps, tutorialStepsError } = tutorialStep
    const [filteredSteps, setFilteredSteps] = useState(false);
    const [stepa, setStepa] = useState(false);

    useEffect(() => {
        if (step === 'settings_patch') {
            setStepa('settings');
        } else {
            setStepa(step);
        }
    },[step])

    useEffect(() => {
        if (tutorialSteps) {
            setFilteredSteps(tutorialSteps.filter(s => s.step === stepa && (!preSelectedTitle || s.title === preSelectedTitle)));
        }
    },[tutorialSteps]);

    useEffect(() => {
        dispatch(getTutorialSteps());
    }, [dispatch]);

    const handleCloseModal = () => {
        setShowModalFalse();
    };

    const handleClose = () => setShowModalFalse();

    return (
        <Modal show={showModal} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Tutorial</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {filteredSteps && 
                    <Carousel 
                      nextIcon={<CustomNextIcon />} 
                      prevIcon={<CustomPrevIcon />}
                    >
                        {filteredSteps.map((s, idx) => (
                            <Carousel.Item key={idx}>
                                <WhiteImage>
                                    <CenteredTitle>{s.title}</CenteredTitle>
                                    <CenteredText>{s.text}</CenteredText>
                                </WhiteImage> 
                                
                                {s.img && <img style={{width: "100%", height: "auto"}} src={s.img} alt={s.title} />}
                                
                            </Carousel.Item>
                        ))}
                    </Carousel>
                }
                <Modal.Footer>
                <Button variant="danger" onClick={handleCloseModal}>
                    Fechar
                </Button>
                </Modal.Footer>
            </Modal.Body>
        </Modal>
    );
}

export default TutorialSteps;
