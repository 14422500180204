import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Row, Col, Container, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { createFiscalAddress, getFiscalAddressList, updateFiscalAddress, deleteFiscalAddress } from '../actions/userActions';


const Title = styled.div`
  font-size:20px;
`;

const Box = styled.div`
  width: 100%;
  padding: 30px;
  height: 200px;
  position: relative;
  background-color: white;
  color:black;
  border: ${({ isSelected }) => (isSelected ? '2px solid #15a259' : '1px solid gray')} ;
  border-radius: 5px;
  cursor: ${({ isItSelectable }) => (isItSelectable ? 'pointer' : 'normal')};
`;

const CustomSelectCheckWrapper = styled.div`
  position:absolute;
  width: 25px;
  height:25px;
  left:8px;
  top:8px;
  background-color:white;
  border: 1px solid gray;
  border-radius:50%;
  opacity: ${({ isItSelectable }) => (isItSelectable ? 1 : 0)};
  z-index: 1;
`;

const CustomSelectCheck = styled(FontAwesomeIcon)`
  position:absolute;
  font-size:25px;
  left:8px;
  top:8px;
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};
  z-index: 2;
`;


const CustomPlus = styled(FontAwesomeIcon)`
  position: absolute;
  font-size: 100px;
  left: 50%; /* Set left to 50% to center horizontally */
  top: 50%; /* Set top to 50% to center vertically */
  transform: translate(-50%, -50%); /* Translate icon to center */
  z-index: 2;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  color:white;
`;

const Smallh6 = styled.h6`
  font-size: 0.8rem;
  color:white;
  margin:0;
`;

const FiscalAddressForm = ({ user_id, createFiscalAddress, dispatch, getFiscalAddressList }) => {
    const [formData, setFormData] = useState({
      user:user_id,
      name: '',
      address: '',
      postal_code: '',
      country: '',
      floor_number: '',
      fiscal_number: '',
    });
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      dispatch(createFiscalAddress(user_id, formData));
      setFormData({
        name: '',
        address: '',
        postal_code: '',
        country: '',
        floor_number: '',
        fiscal_number: '',
      });
      dispatch(getFiscalAddressList(user_id));
    };
  
    return (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Nome:</label>
            <input
              type="text"
              className="form-control"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Morada:</label>
            <input
              type="text"
              className="form-control"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Código Postal:</label>
            <input
              type="text"
              className="form-control"
              name="postal_code"
              value={formData.postal_code}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>País:</label>
            <input
              type="text"
              className="form-control"
              name="country"
              value={formData.country}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Numero/andar/lote:</label>
            <input
              type="text"
              className="form-control"
              name="floor_number"
              value={formData.floor_number}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Numero fiscal:</label>
            <input
              type="text"
              className="form-control"
              name="fiscal_number"
              value={formData.fiscal_number}
              onChange={handleInputChange}
            />
          </div>
          <button type="submit" className="btn btn-primary mt-3">
            Criar endereço
          </button>
        </form>
      );
    };

function FiscalAdress({isItSelectable=false, currentSelected=null, setSelected}) {
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const fiscalAddress = useSelector((state) => state.fiscalAddressStore);
    const { getFiscalAdress } = fiscalAddress;

    useEffect(() => {
        dispatch(getFiscalAddressList(userInfo.id));
    }, [dispatch]);

    const handlePlusIconClick = () => {
        setShowModal(true);
      };

    const handleSelection = (value) => {
        setSelected(value);
    };
      

  return (
    <Container>
        <Row style={{display:'flex' , justifyContent: 'start'}}>
          {isItSelectable === false &&
            <Col xs={12} style={{borderBottom: '1px solid black'}}>
            <Row>
                <Col xs={6}>
                    <Title>Endereços fiscais</Title>
                </Col>
                <Col xs={6}>
                    <ActionButtons>
                        <Button variant="info" size="sm" className="mr-2" onClick={handlePlusIconClick}> 
                            <Smallh6>Endereço <FontAwesomeIcon size='lg' icon={faPlus} className="ml-2"/> </Smallh6>
                        </Button>
                    </ActionButtons>
                </Col>
            </Row> 
          </Col>
          }
            <Col xs={12} sm={12} md={6} xl={6} style={{marginTop:'10px'}}>
                <Box  style={{cursor:'pointer'}} onClick={handlePlusIconClick}>
                    <CustomPlus icon={faPlus} style={{color:'gray'}}></CustomPlus>
                </Box>
            </Col>
            {getFiscalAdress && getFiscalAdress.map((address, index) => (
                <Col key={index} xs={12} sm={12} md={6} xl={6} style={{marginTop:'10px'}}>
                    <Box isItSelectable={isItSelectable} isSelected={currentSelected === address.id} onClick={() => handleSelection(address.id)}>
                        <CustomSelectCheck style={{color: "#15a259",}} icon={faCircleCheck} isSelected={currentSelected === address.id}></CustomSelectCheck>
                        <CustomSelectCheckWrapper isItSelectable={isItSelectable}/>
                        <div style={{marginBottom:'10px', borderBottom:'1px solid gray'}}>{address.name}</div>
                        <div>{address.address}</div>
                        <div>{address.postal_code}</div>
                        <div>{address.country}</div>
                        <div>{address.fiscal_number}</div>
                    </Box>
                </Col>
            ))}
            
        </Row>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Criar endereço fiscal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Form for creating a fiscal address */}
          {/* You can create a separate component for this form */}
          <FiscalAddressForm
            user_id={userInfo.id}
            createFiscalAddress={createFiscalAddress}
            dispatch={dispatch}
            getFiscalAddressList={getFiscalAddressList}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}

export default FiscalAdress