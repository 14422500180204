import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const CustomSelectOption = styled.div`
  display: inline-flex;
  align-items: center;
  position:relative;
  padding: 5px;
  font-size: 1rem;
  border: 1px solid ${({ isSelected }) => isSelected ? 'black' : 'white'};
  border-radius: 5px;
  margin-bottom: 5px;
  background-color: ${({ isSelected, isF }) => (isF ? '#ed5d1a' : isSelected ? '#e3cc00' : '#e3cc00')};
  color: ${({ isSelected, isF }) => (isF ? 'white' : isSelected ? 'black' : 'white')};
  cursor:pointer;
  transition: all 0.2s ease-in-out;
  margin-right: 10px;

  &:hover {
    border-color: black;
  }
`;

const WarningMessage = styled.span`
  color: red;
  position:absolute;
  top: 31px;
  font-size: 13px;
  left: 0;
`;

const CustomSelectText = styled.span`
  margin-right: 5px;
`;

const CustomSelectCheckWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid black;
  border-radius: 50%;
  background-color: white;
  margin-left: 5px;
  position: relative;
`;

const CustomSelectCheck = styled.span`
  position: absolute;
  z-index: 1;
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};
  color: black;
`;

function TableInPlace({ items, handleArrangedTable, reservation}) {
    const [selectedItem, setSelectedItem] = useState('');

  const handleItemClick = (item) => {
    if (selectedItem == 'Mesa posta') {
        handleArrangedTable(reservation.token, false)
    } else {
        handleArrangedTable(reservation.token, true)
    }
  };
  useEffect(() => {
    if (reservation.inTable === true) {
        setSelectedItem('Mesa posta')
    } else if (reservation.inTable === false) {
        setSelectedItem('none')
    } 
  },[reservation])

  return (
    <div>
      {items && 
      <>
      {items.map((item, index) => (
        <>
         <CustomSelectOption
            key={item}
            id={`custom-select-option-${index}`}
            isSelected={selectedItem === item}
            onClick={() => handleItemClick(item)}
            isF={false}
            borderColor={'yellow'}
          >
            <CustomSelectText>{item === 'Lunch'  || item === 'Lunch/f' ? 'Almoço' 
                              : item === 'Dinner' || item === 'Lunch/f' ? 'Jantar' 
                              : item === 'Breakfast' || item === 'Lunch/f' ? 'Pequeno Almoço' 
                              : item.endsWith('/f') ? item.slice(0, -2) : item}
            </CustomSelectText>
            <CustomSelectCheckWrapper>
              <CustomSelectCheck isSelected={selectedItem === item}>&#10003;</CustomSelectCheck>
            </CustomSelectCheckWrapper>
            <WarningMessage>
              { item.endsWith('/f') && 'Lotado *'}
            </WarningMessage>
          </CustomSelectOption>

        </>   
        ))}
      </>
        
      }
      
    </div>
  );
}

export default TableInPlace;