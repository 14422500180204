import React from 'react';
import styled from 'styled-components';
import AnimatedCheck from './CheckMarkAnimation';

const BackgroundContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Slightly black background with opacity */
  z-index: 10000; /* Set a high z-index value */
`;

const CheckContainer = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WaitCheckMark = () => {
  return (
    <BackgroundContainer>
      <CheckContainer>
        <AnimatedCheck />
      </CheckContainer>
    </BackgroundContainer>
  );
};

export default WaitCheckMark;