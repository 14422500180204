import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import styled from 'styled-components';
import SubMenu from '../components/SubMenu';
import Separator from '../components/Separator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { confirmNotification } from '../actions/restaurantActions'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display:flex;
  justify-content:center;
  align-items:center;
`;


const Box = styled.div`
  height:300px;
  width:400px;
  display:flex;
  flex-wrap: wrap;
  justify-content:center;
  align-items:center;
  color: white;
  border-radius:5px;
  background-color:#1bcf8d;
  padding:25px;
`;

function ConfirmNotification() {
    const dispatch = useDispatch();
    const params = useParams();
    const token = params.token;
    const email_phone = params.email_phone;
    const [restaurant_id, setRestaurant_id] = useState(params.restaurant_id);

    useEffect(() => {
        if (token != '' && token!= null) {
            dispatch(confirmNotification(token));
        }
    },[restaurant_id, token])


  return (
    <Container>
        <Row>
            <Col  sm={12}>
                <Box>
                    <h5 style={{color: 'white'}}><i className="fas fa-smile-beam" style={{marginRight: "10px", color: 'white'}}></i>As notificações foram ativadas para o contacto:</h5>
                    <div style={{borderTop: '1px solid white', width:'90%', padding:'0', margin:'0'}}/>
                    <p style={{fontSize: "22px", color: 'white'}}>
                    {isNaN(email_phone) ?
                        <FontAwesomeIcon size='xl' icon={faEnvelope} style={{color: "white", marginRight: '10px'}} />
                    :
                        <FontAwesomeIcon size='xl' icon={faWhatsapp} style={{color: "white", marginRight: '10px'}} />
                    }
                        
                        {email_phone}
                    </p>
                </Box>

            </Col>
        </Row>
    </Container>
  )
}

export default ConfirmNotification