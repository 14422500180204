import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey,  faEye, faEyeSlash, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { actionChangePasswordRecovery, verifyPasswordRecoveryCode} from '../actions/userActions'
import LoaderComponent from '../components/LoaderComponent'

const Title = styled.h3`
  margin: 20px;
  font-weight: bold;
  text-transform: uppercase;
`;

const AbsoluteKeySquare = styled.div`
  position: absolute;
  display:flex;
  justify-content:center;
  align-items:center;
  border-radius: 4px 0 0 4px;
  background-color: #f2f2f2;
  border: solid 1px #cfcfcf;
  top: 0px;
  left: 0px;
  width: 50px;
  height: 38px;
  padding: 1px;
  font-size: 18px;
  text-align: center;
  &:hover {
    background-color: #e8e8e8;
    border-color: #76d6c5;
  }
`;

const StyledCard = styled.div`
  border-radius:5px;
  max-width: 500px;
  padding:15px;
  border: 1px solid #dbdbdb;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width:100%;
`;

function PasswordRecoveryChange() {
    const params = useParams()
    const dispatch = useDispatch();

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [disableButton, setdisableButton] = useState(true);
    const [showPasswords, setShowPasswords] = useState(true);
    const [passwordChanged, setPasswordChanged] = useState(false);

    const [activateLoader, setActivateLoader] = useState(false);


    const userUpdate = useSelector(state => state.userUpdate)
    const { verifyPasswordRecovery, loadingVerifyPasswordRecovery, passwordVerifyRecoveryError,
            changePasswordRecovery, loadingChangePasswordRecovery, passwordChangeRecoveryError} = userUpdate

    const handleSubmit = (event) => {
        event.preventDefault();
        // code to submit form goes here
        dispatch(actionChangePasswordRecovery(params.recovery_code, password))
        };

    useEffect(() => {
        dispatch(verifyPasswordRecoveryCode(params.recovery_code))
    },[params.recovery_code])

    useEffect(()=> {
        if (password !== confirmPassword || password == '') {
            setPasswordsMatch(false);
            setdisableButton(true);
        } else {
            setPasswordsMatch(true);
            setdisableButton(false);
        }
    },[password, confirmPassword]);
    
    const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    };
    
    const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    };

    useEffect(() => {
        if (loadingVerifyPasswordRecovery || loadingChangePasswordRecovery) {
            setActivateLoader(true);
        } else {
            setActivateLoader(false);
        }
    },[loadingVerifyPasswordRecovery, loadingChangePasswordRecovery])

    useEffect(() => {
        if (changePasswordRecovery && changePasswordRecovery.includes('password_changed')) {
            setPasswordChanged(true);
        } else {
            setPasswordChanged(false);
        }
    },[changePasswordRecovery])
    
    
  return (
    <Container>
    <Row className="justify-content-md-center">
      <Col md={6}>
        <StyledCard border="light" className="rounded-lg p-3">
        {
            verifyPasswordRecovery && verifyPasswordRecovery.includes('still_valid') &&  !passwordChanged?
            <Form onSubmit={handleSubmit}>
          <Title>Alterar palavra passe</Title>

          <Form.Group controlId="formBasicPassword">
              <div style={{ position: 'relative' }}>
              <Form.Control required type={showPasswords ? 'password' : 'text'} placeholder="palavra passe" value={password} onChange={handlePasswordChange} style={{ paddingLeft: '60px' }} />
                  <AbsoluteKeySquare style={{cursor: 'pointer'}} onClick={() => setShowPasswords(!showPasswords)}>
                  {showPasswords ?
                    <FontAwesomeIcon size='lg' icon={faEyeSlash} style={{color: "#00ad74"}}/>
                  :
                    <FontAwesomeIcon size='lg' icon={faEye} style={{color: "#00ad74"}}/>
                  }
                  </AbsoluteKeySquare>
              </div>
          </Form.Group>
          <Form.Group controlId="formBasicConfirmPassword">
              <Form.Label>Confirmar palavra passe</Form.Label>
               <div style={{ position: 'relative' }}>
               <Form.Control
               type={showPasswords ? 'password' : 'text'}
               placeholder="confirmar palavra passe" 
               value={confirmPassword} 
               required
               onChange={handleConfirmPasswordChange}
               style={{ paddingLeft: '60px' }}/>
                  <AbsoluteKeySquare style={{cursor: 'pointer'}} onClick={() => setShowPasswords(!showPasswords)}>
                  {showPasswords ?
                    <FontAwesomeIcon size='lg' icon={faEyeSlash} style={{color: "#00ad74"}}/>
                  :
                    <FontAwesomeIcon size='lg' icon={faEye} style={{color: "#00ad74"}}/>
                  }
                  </AbsoluteKeySquare>
              </div>
              {!passwordsMatch && <Form.Text className="text-danger">Passwords do not match</Form.Text>}
          </Form.Group>

          <Button
            className="mt-3" 
            variant="primary" 
            type="submit"
            disabled={disableButton}
          >
              Alterar
          </Button>
          </Form>
          : !passwordChanged ?
          <div>
            O link está expirado 
          </div>
          :
          <></>
        }
        {passwordChanged &&
            <div>
                <FontAwesomeIcon size='lg' icon={faCircleCheck} style={{color: "#3dc2a7", marginRight:'10px'}} />  
                A sua password foi alterada com sucesso.
                <p>Clique no seguinte botão para entrar na sua conta:</p>
                <Link to={`/login/${'null'}/${'null'}`} style={{ textDecoration: 'none' }}>
                    <Button
                        className="mt-3" 
                        variant="primary" 
                    >
                        Entrar na conta
                    </Button>
                </Link>
                
            </div>
        }
        </StyledCard>
      </Col>
    </Row>
    
    <LoaderComponent
        activate={activateLoader}
        theSize={'o'}
    />
    
    

  </Container>
  )
}

export default PasswordRecoveryChange