import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

function CompareChanges({before, after}) {
    const [changes, setChanges] = useState(null);

    const reservationShift = useSelector((state) => state.reservationShift);
    const { shift, isLoading: isLoadingShift, error: errorShift } = reservationShift;

    const fieldsToShow = [
        "full_name",
        "phone_number",
        "date",
        "time",
        "number_of_people",
        "message",
        "table_place_preference"
    ];
    const calculateChanges = (current, previous) => {
        const changes = {};
        fieldsToShow.forEach(key => {
            if (key === 'table_place_preference') {
                const currentPlaceId = current[key]?.id; // Use optional chaining
                const previousPlaceId = previous[key]; // Assuming previous directly stores the id
    
                // Use optional chaining and provide fallback values
                const currentPlaceName = shift?.zones_with_id?.[currentPlaceId] ?? 'Unknown Place';
                const previousPlaceName = shift?.zones_with_id?.[previousPlaceId] ?? 'Unknown Place';
    
                if (currentPlaceName !== previousPlaceName) {
                    changes[key] = {
                        from: previousPlaceName,
                        to: currentPlaceName
                    };
                }
            } else {
                if (current[key] !== previous[key]) {
                    changes[key] = {
                        from: previous[key],
                        to: current[key]
                    };
                }
            }
        });
        return changes;
    };
    const renderValue = (value) => {
        if (typeof value === 'object' && value !== null) {
            if (value.place_of_table) {
                return JSON.stringify(value.place_of_table);
            }
            // Here you can customize how you want to display the object
            // For example, displaying keys and values or just converting it to a string
            return JSON.stringify(value);
        }
        return value;
    };

    const codeToText = (value) => {
        if (value === true) {
            return 'sim';
        } else if (value === false) {
            return 'não';
        } else if (value === 'Pending_from_customer') {
            return 'Reserva em curso';
        } else if (value === 'Pending_from_Restaurant') {
            return 'Pendente de aceitação';
        } else if (value === 'Edited') {
            return 'Editada por cliente';
        } else if (value === 'Edited') {
            return 'Editada por cliente';
        } else if (value === 'Waiting_List') {
            return 'Lista de espera';
        } else if (value === 'Accepted') {
            return 'Aceite sem mesa';
        } else if (value === 'Accepted_Without_Table') {
            return 'Aceite sem mesa';
        } else if (value === 'Accepted_With_Table') {
            return 'Aceite com mesa';
        } else if (value === 'Attended') {
            return 'Completa';
        } else if (value === 'Did_Not_Attend') {
            return 'Não compareceu!';
        } else if (value === 'Cancelled_By_Customer') {
            return 'Cancelada por cliente';
        } else if (value === 'Cancelled_By_Customer_Warning') {
            return 'Cancelada por cliente, recentemente.';
        } else if (value === 'Cancelled_By_Restaurant') {
            return 'Cancelada por restaurante.';
        } else if (value === 'Cancelled_By_Expired_Code') {
            return 'Cancelada por tempo expirado.';
        } else if (value === 'time') {
            return 'Horas';
        } else if (value === 'number_of_people') {
            return 'Numero de pessoas';
        } else if (value === 'table_place_preference') {
            return 'Espaço';
        } else if (value === 'full_name') {
            return 'Nome';
        } else if (value === 'date') {
            return 'Data';
        } else if (value === 'message') {
            return 'Mensagem';
        } else if (value === 'phone_number') {
            return 'Telemóvel';
        }
    return value;
    };

    useEffect(() => {
        console.log('before changes')
        console.log('before: ', before)
        console.log('after: ', after)
        if (before && after){
            setChanges(calculateChanges(after,before));
        }
        
    },[before,after])

  return (
    <div>
            {changes ? Object.keys(changes).map(key => (
                <div key={key} style={{color:'black', fontSize:'14px', display:'flex',flexWrap:'wrap'}}> 
                    {codeToText(key)+': '} 
                    <div style={{color:'red', fontSize:'14px'}}> {renderValue(codeToText(changes[key].from))} </div> → 
                    <div style={{color:'green', fontSize:'14px'}}> {renderValue(codeToText(changes[key].to))} </div>
                </div>
            )) 
            :
                <></>
            }
    </div>
  )
}

export default CompareChanges