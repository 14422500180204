import React from 'react'
import  FormContainer  from '../components/FormContainer'
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';


function LoginScreen() {
  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={6}>
          <Card border="light" className="rounded-lg p-3">
            <FormContainer />
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default LoginScreen