import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCircleArrowUp, faNewspaper, faSquareXmark } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { format, utcToZonedTime } from 'date-fns-tz';

const Modal = styled.div`
  position: fixed;
  display: flex;
  flex-wrap:wrap;
  justify-content: center;
  height: 80%;
  width: 90%;
  max-width: 500px;
  background-color: white;
  border-radius: 6px;
  border: 1px solid gray;
  z-index: 20;
  top: 45%;
  left: 50%;
  padding:0;
  transform: translate(-50%, -50%);
  margin:0;
`;

const ContentWrapperForX = styled.div`
    position: relative;
    width: 100%; 
    height: 35px;
    display:flex;
    justify-content: end;
    background-color:#4ec7a5;
`;

const ChangesBox = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap:wrap;
    height: auto;
    width: 90%;
    max-width: 500px;
    background-color: white;
    border-radius: 10px;
    border: 1px solid gray;
    margin-top:20px;
    overflow:hidden;
`;

const ChangesBoxTop = styled.div`
    display: flex;
    justify-content: center;
    height: 38px;
    width: 100%;
    max-width: 500px;
    background-color: #4ec7a5;
    color:white;
    padding:5px;
    font-size:22px;
`;
const ChangesBoxBottom = styled.div`
    display: flex;
    justify-content: start;
    height: 112px;
    width: 100%;
    max-width: 500px;
    color:white;
    padding:5px;
    font-size:22px;
    padding:8px;
`;

const ChangesBoxBall = styled.div`
    position:absolute;
    left:0;
    top:0;
    display: flex;
    justify-content: center;
    height: 50px;
    width: 50px;
    background-color: #82deaa;
    color:white;
    padding:5px;
    font-size:25px;
    border-radius: 50%;
    border:1px solid gray;
`;

const ReservationBox = styled.div`
  display: flex;
  justify-content: center;
  height: 400px;
  width: 90%;
  max-width: 500px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid gray;
  padding:8px;
  margin:0;
  margin-top:8px;
  overflow:auto;
`;

const ShowModalBall = styled.div`
    position:relative;
    display: flex;
    justify-content: center;
    align-items:center;
    height: 30px;
    width: 30px;
    background-color: white;
    color:white;
    font-size:20px;
    border-radius: 50%;
    border:1px solid gray;
    bottom:5px;
    left:5px;
    cursor:pointer;
`;

const CloseModal = styled.div`
    display: flex;
    justify-content: center;
    height: 20px;
    width: 20px;
    color:white;
    font-size:20px;
    cursor:pointer;
    padding-right:10px;
    padding-top:5px;
`;

function ReservationHistory({ reservationHistory, timezone, all_tables, zones_with_id }) {
    const [showModal, setShowModal] = useState(false);
    const fieldsToShow = [
        "full_name",
        "phone_number",
        "date",
        "time",
        "number_of_people",
        "status",
        "message",
        "cancelled_warning",
        
        // Fields to show
    ];
    const calculateChanges = (current, previous) => {
        const changes = {};
        fieldsToShow.forEach(key => {
            if (current[key] !== previous[key]) {
                changes[key] = {
                    from: previous[key],
                    to: current[key]
                };
            }
        });
        return changes;
    };

    const renderValue = (value) => {
        if (typeof value === 'object' && value !== null) {
            // Here you can customize how you want to display the object
            // For example, displaying keys and values or just converting it to a string
            return JSON.stringify(value);
        }
        return value;
    };

    const codeToText = (value) => {
        if (value === true) {
            return 'sim';
        } else if (value === false) {
            return 'não';
        } else if (value === 'Pending_from_customer') {
            return 'Reserva em curso';
        } else if (value === 'Pending_from_Restaurant') {
            return 'Pendente de aceitação';
        } else if (value === 'Edited') {
            return 'Editada por cliente';
        } else if (value === 'Edited') {
            return 'Editada por cliente';
        } else if (value === 'Waiting_List') {
            return 'Lista de espera';
        } else if (value === 'Accepted') {
            return 'Aceite sem mesa';
        } else if (value === 'Accepted_Without_Table') {
            return 'Aceite sem mesa';
        } else if (value === 'Accepted_With_Table') {
            return 'Aceite com mesa';
        } else if (value === 'Attended') {
            return 'Completa';
        } else if (value === 'Did_Not_Attend') {
            return 'Não compareceu!';
        } else if (value === 'Cancelled_By_Customer') {
            return 'Cancelada por cliente';
        } else if (value === 'Cancelled_By_Customer_Warning') {
            return 'Cancelada por cliente, recentemente.';
        } else if (value === 'Cancelled_By_Restaurant') {
            return 'Cancelada por restaurante.';
        } else if (value === 'Cancelled_By_Expired_Code') {
            return 'Cancelada por tempo expirado.';
        } else if (value === 'Lunch') {
            return 'Almoço';
        } else if (value === 'Breakfast') {
            return 'Pequeno almoço';
        } else if (value === 'Dinner') {
            return 'Jantar';
        }
    return value;
    };

    const renderFieldWithChanges = (fieldName, currentValue, previousValue) => {
        return (
            <div style={{display:'flex',flexWrap:'nowrap'}}>
                {fieldName}: {codeToText(currentValue === previousValue ? renderValue(currentValue) : renderSingleChange(previousValue, currentValue))}
            </div>
        );
    };

    const getTableNamesByIds = (tableIdsString) => {
        const tableIds = tableIdsString.split(','); // Split the string into an array of IDs
        const tableNames = tableIds.map(id => {
            const table = all_tables.find(table => table.id.toString() === id.trim()); // Find the table object by ID
            return table ? table.table_name : `ID: ${id}`; // Return table name or ID if not found
        });
        return tableNames.join(', '); // Join the table names back into a string
    };

    const renderReservationDetails = (previousReservation, reservation) => {
        return (
            <div>
                <div>{renderFieldWithChanges('Status', reservation.status, previousReservation ? previousReservation.status : '')}</div>
                <div>{renderFieldWithChanges('Nome', reservation.full_name, previousReservation ? previousReservation.full_name : '')}</div>
                <div>{renderFieldWithChanges('Telemóvel', reservation.phone_number, previousReservation ? previousReservation.phone_number : '')}</div>
                <div>{renderFieldWithChanges('Data', reservation.date, previousReservation ? previousReservation.date : '')}</div>
                <div>{renderFieldWithChanges('Turno', reservation.shift, previousReservation ? previousReservation.shift : '')}</div>
                <div>{renderFieldWithChanges('Horário', reservation.time, previousReservation ? previousReservation.time : '')}</div>
                <div>{renderFieldWithChanges('Tempo Estimado', reservation.estimatedtime, previousReservation ? previousReservation.estimatedtime : '')}</div>
                <div>{renderFieldWithChanges('Zona', zones_with_id[reservation.table_place_preference], previousReservation ? zones_with_id[previousReservation.table_place_preference] : '')}</div>
                <div>
                    {renderFieldWithChanges(
                        'Mesas Utilizadas',
                        reservation.tablesused ? getTableNamesByIds(reservation.tablesused) : '',
                        previousReservation && previousReservation.tablesused ? getTableNamesByIds(previousReservation.tablesused) : ''
                    )}
                </div>
                <div>{renderFieldWithChanges('Número de Pessoas', reservation.number_of_people, previousReservation ? previousReservation.number_of_people : '')}</div>
                <div>{reservation.baby_chair > 0 && renderFieldWithChanges('Cadeira de Bebê', reservation.baby_chair, previousReservation ? previousReservation.baby_chair : '')}</div>
                <div>{reservation.accessability !== 'not_needed' && renderFieldWithChanges('Acessibilidade', reservation.accessability, previousReservation ? previousReservation.accessability : '')}</div>
                <div>{reservation.client_note !== '' && renderFieldWithChanges('Nota do Cliente', reservation.client_note, previousReservation ? previousReservation.client_note : '')}</div>
                <div>{reservation.restaurant_note !== '' && renderFieldWithChanges('Nota do Restaurante', reservation.restaurant_note, previousReservation ? previousReservation.restaurant_note : '')}</div>
                <div>{renderFieldWithChanges('Telemovel confirmado', reservation.is_phone_confirmed, previousReservation ? previousReservation.is_phone_confirmed : '')}</div>
            </div>
        );
    };

    const renderChanges = (changes) => {
        return (
            <div>
                <h4>Mudanças:</h4>
                {Object.keys(changes).map(key => (
                    <div key={key} style={{color:'black', fontSize:'14px', display:'flex',flexWrap:'wrap'}}> 
                        {key}: 
                        <div style={{color:'red', fontSize:'14px'}}> {renderValue(codeToText(changes[key].from))} </div> → 
                        <div style={{color:'green', fontSize:'14px'}}> {renderValue(codeToText(changes[key].to))} </div>
                    </div>
                ))}
            </div>
        );
    };

    const renderSingleChange = (from, to) => {
        return (
            <div style={{color:'black', fontSize:'14px', display:'flex',flexWrap:'wrap'}}>
                <div style={{color:'red', fontSize:'14px'}}> {renderValue(codeToText(from))} </div> → 
                <div style={{color:'green', fontSize:'14px'}}> {renderValue(codeToText(to))} </div>
            </div>
        );
    };

    const formatInTimeZone = (dateString, timeZone, fmt = 'dd-MM HH:mm:ss') => {
        console.log('timezone: ', timezone)
        if (!dateString) {
            console.error('Invalid date string:', dateString);
            return 'Invalid date';
        }
    
        try {
            const date = new Date(dateString);
            if (isNaN(date.getTime())) {
                throw new Error('Invalid date');
            }
    
            const zonedDate = utcToZonedTime(date, timeZone);
            return format(zonedDate, fmt, { timeZone });
        } catch (error) {
            console.error('Error formatting date:', error);
            return 'Invalid date';
        }
    };

    return (
        <>
            <ShowModalBall>
                <FontAwesomeIcon icon={faNewspaper} style={{color: "#82deaa",}}  onClick={() => setShowModal(!showModal)}/>
            </ShowModalBall>
            {showModal &&
                <Modal>
                    <ContentWrapperForX>
                        <CloseModal onClick={() => setShowModal(!showModal)}>
                            <FontAwesomeIcon icon={faSquareXmark} style={{color: "#d35555", fontSize:'24px'}} />
                        </CloseModal>
                    </ContentWrapperForX>
                    <div style={{overflow:'auto', width:'100%', height:'95%'}}>
                    {reservationHistory.map((reservation, index) => {
                        console.log('index: ', index)
                        console.log('reservation : ', reservation)
                        console.log('reservationHistory[index+ 1] : ', reservationHistory[index + 1])
                        
                        
                        const previousReservation = index + 1 > 0 ? reservationHistory[index + 1] : null;
                        const changes = previousReservation ? calculateChanges(reservation, previousReservation) : {};

                        return (
                            <div key={reservation.history_id} style={{margin:'0', padding:'0', height:'auto', width:'100%', display:'flex', justifyContent:'center', flexWrap:'wrap'}}>
                                <ChangesBox>
                                    <ChangesBoxTop>
                                        <Row style={{width:'100%'}}>
                                            <Col xs={1} style={{fontSize:'20px'}}>
                                                {reservationHistory.length - index}
                                            </Col>
                                            <Col xs={5} style={{fontSize:'20px'}}>
                                                {reservation.changes_made_by === null ? 'Sistema' : 
                                                reservation.changes_made_by === 'customer' ? 'cliente' : 
                                                reservation.changes_made_by === 'system' ? 'Sistema':
                                                reservation.changes_made_by}
                                            </Col>
                                            <Col xs={6} style={{fontSize:'15px',display:'flex',flexWrap:'nowrap', justifyContent:'center', paddingTop:'4px'}}>
                                                {formatInTimeZone(reservation.history_date, timezone)}
                                            </Col>
                                        </Row>
                                    </ChangesBoxTop>
                                    <div style={{height:'100%', width:'100%', overflow:'auto', padding:'10px'}}>
                                        {renderReservationDetails(previousReservation, reservation)}
                                    </div>
                                </ChangesBox>
                                <FontAwesomeIcon icon={faCircleArrowUp} style={{ color: "#4ec7a5", width: '100%', fontSize: '80px', margin: '10px' }} />
                            </div>
                        );
                    })}
                        <div  style={{margin:'0', padding:'0', height:'auto', width:'100%', display:'flex', justifyContent:'center', flexWrap:'wrap'}}>
                            <ChangesBox style={{height:'auto'}}>
                                <ChangesBoxTop style={{height:'100%'}}>
                                    Inicio 
                                </ChangesBoxTop>
                            </ChangesBox>
                        </div>
                    </div>
                </Modal>
            }
        </>
    );
}

export default ReservationHistory;