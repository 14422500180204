import React, {useState, useEffect} from 'react'
import { Form, Button, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { login } from '../actions/userActions'
import Messages from './Messages'
import Loader from './Loader'
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey,  faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const AbsoluteKeySquare = styled.div`
  position: absolute;
  display:flex;
  justify-content:center;
  align-items:center;
  border-radius: 4px 0 0 4px;
  background-color: #f2f2f2;
  border: solid 1px #cfcfcf;
  top: 0px;
  left: 0px;
  width: 50px;
  height: 38px;
  padding: 1px;
  font-size: 18px;
  text-align: center;
  &:hover {
    background-color: #e8e8e8;
    border-color: #76d6c5;
  }
`;

const StyledCard = styled.div`
  border-radius:5px;
  max-width: 500px;
  padding:15px;
  border: 1px solid #dbdbdb;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width:100%;
`;

function FormContainer({history}) {
    const params = useParams()
    const [email, setEmail] = useState(params.username !== 'null' ? params.username: '')
    const [password, setPassword] = useState(params.password !== 'null' ? params.password: '')
    const [showPasswords, setShowPasswords] = useState(true);

    const dispatch = useDispatch()

    const location = useLocation() 
    const navigate = useNavigate()
    const redirect = location.search ? location.search.split('=')[1] : '/'

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo, isLoading, error} = userLogin
    const user_login_info = useSelector(state => state.userUpdate)
    const { login_info } = user_login_info

    useEffect(() => {
        if(userInfo) {
            navigate(redirect)
        }
    }, [navigate, userInfo, redirect])

    useEffect(() => {
      if (params.email !=='null' && params.password !== 'null' && email !== '' && password !== '') {
        dispatch(login(email, password));
      }
    },[email, password])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(login(email, password));
    }

    useEffect(() => {
      if (login_info && params.username !== 'null') {
        dispatch(login(params.username, params.password));
      }
    },[login_info, params])

  return (
    <StyledCard>
      <Form onSubmit={submitHandler}>
        {error && <Messages variant='danger'>{error}</Messages>}
        {isLoading && <Loader />}
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Endereço de e-mail</Form.Label>
                <Form.Control 
                type="email" 
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                />
                <Form.Text className="text-muted">
                  palavra-passe
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <div style={{ position: 'relative' }}>
                <Form.Control required 
                    type={showPasswords ? 'password' : 'text'} 
                    placeholder="Palavra passe" value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                    style={{ paddingLeft: '60px' }} 
                />
                    <AbsoluteKeySquare style={{cursor: 'pointer'}} onClick={() => setShowPasswords(!showPasswords)}>
                    {showPasswords ?
                      <FontAwesomeIcon size='lg' icon={faEyeSlash} style={{color: "#00ad74"}}/>
                    :
                      <FontAwesomeIcon size='lg' icon={faEye} style={{color: "#00ad74"}}/>
                    }
                    </AbsoluteKeySquare>
                </div>
            </Form.Group>

              <Form.Group controlId="formBasicCheckbox">
                <Form.Check type="checkbox" label="Remember me" />
              </Form.Group>

              <Button variant="primary" type="submit">
                Entrar
              </Button>
              <Card.Text className="mt-3 text-center">
                Não tem uma conta? 
                <Link to={'/register'}>&nbsp;Criar conta</Link>
              </Card.Text>
              <Card.Text className="mt-3 text-center">
                <Link to={'/passwordRecovery'}>Recuperar palavra-passe</Link>
              </Card.Text>
            </Form>

    </StyledCard>
    
  )
}

export default FormContainer