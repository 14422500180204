import React, {useState, useEffect} from 'react'
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { login } from '../actions/userActions'
import Messages from '../components/Messages'
import Loader from '../components/Loader'
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import LoaderComponent from '../components/LoaderComponent'
import {sendPasswordRecoveryEmail} from '../actions/userActions'

const Title = styled.h3`
  margin: 20px;
  font-weight: bold;
  text-transform: uppercase;
`;

const StyledCard = styled.div`
  border-radius:5px;
  max-width: 500px;
  padding:15px;
  border: 1px solid #dbdbdb;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width:100%;
`;


function AccountRecovery() {
    const [email, setEmail] = useState('');
    const [recoveryEmailSent, setRecoveryEmailSent] = useState(false);
    const [activateLoader, setActivateLoader] = useState(false);
    const [emailWS, setEmailWS] = useState(false);

    const userUpdate = useSelector(state => state.userUpdate)
    const { passwordRecovery, loadingPasswordRecovery, passwordRecoveryError} = userUpdate

    const dispatch = useDispatch()

    const handleSubmit = (event) => {
        event.preventDefault();
        let verifyFields = true;
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          if (!emailRegex.test(email)){
            setEmailWS(true);
            verifyFields = false;
          } else {
            setEmailWS(false);
          }
        if (verifyFields === true) {
            dispatch(sendPasswordRecoveryEmail(email));
        }
        };
    
    useEffect(() => {
        if (loadingPasswordRecovery === true) {
            setActivateLoader(true);
        } else {
            setActivateLoader(false);
        }
    },[loadingPasswordRecovery])

    useEffect(() => {
        if (passwordRecovery && passwordRecovery.includes('username_exists')) {
            setRecoveryEmailSent(true);
        } 
    },[passwordRecovery])

  return (
    <Container>
    <Row className="justify-content-md-center">
      <Col md={6}>
        {!recoveryEmailSent ?
            <StyledCard border="light" className="rounded-lg p-3">
            <Form onSubmit={handleSubmit}>
              {/* error && <Messages variant='danger'>{error}</Messages> */}
              {/*isLoading && <Loader />*/}
              <Title>Recuperar senha</Title>
              <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                   type="email"
                   placeholder="Enter email" 
                   value={email}
                   required
                   onChange={(e) => setEmail(e.target.value)}
                  />
                  <Form.Text className="text-muted">
                  Inserir o email da sua conta.
                  </Form.Text>
                  {passwordRecoveryError && passwordRecoveryError.includes('username_does_not_exist') && 
                    <div style={{color: 'red'}}>
                        O email que inseriu não existe.
                    </div>
                  }
                  {emailWS && 
                    <div style={{color: 'red'}}>
                        O email que inseriu é inválido.
                    </div>
                  }
              </Form.Group>
              <Button  
              className="mt-3" 
              variant="primary" 
              type="submit"
              >
                  Recuperar senha
              </Button>
              </Form>
            </StyledCard>
        :
            <StyledCard border="light" className="rounded-lg p-3">
            <Form>
            <Title>Email enviado</Title>
                <div>
                <FontAwesomeIcon size='lg' icon={faCircleCheck} style={{color: "#3dc2a7", marginRight:'10px'}} />  
                    Enviamos um email de recuperação de senha para {email}.
                    Por favor aceder email para trocar a senha.
                </div>
            </Form>
            </StyledCard>
        }
        
      </Col>
    </Row>
    <LoaderComponent
      activate={activateLoader}
      theSize={'o'}
    />
  </Container>
  )
}

export default AccountRecovery