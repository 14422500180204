import React from 'react';
import Loader from './Loader';
import styled from 'styled-components';

const Container = styled.div`
  position: fixed; /* This makes it cover the full screen */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(143, 143, 143, 0.4); /* Make background transparent */
  z-index: 9999; /* Make it sit on top of everything else */
  display: flex;
  align-items: center;
  justify-content: center;
`;

function LoaderComponent({ activate, theSize='n' }) { // Make sure to destructure activate from props
  return (
    <>
      {
        activate && 
          <Container>
            <Loader 
                theSize={theSize}
            />
          </Container>
      }
    </>
  );
}

export default LoaderComponent;
