
import { restaurantListSlice } from '../reducers/restaurantReducers'
import { restaurantDetailsSlice, reservationsFromRestaurantSlice, reservationAcceptSlice, 
        availabeTablesToChangeSlice, changeTablesSlice, restaurantOpenDaysOfTheWeekSlice, 
        estimatedTimeCustomersSpendSlice, closedExceptionsSlice, placeOfTableSlice, tablesSlice,
        fetchHoursEachTimeSlice, fetchConnectTablesSlice, create_confirmationSlice, fetchAllExistingShiftsSlice,
        getReservationSumsSlice, customerAttendUpdateSlice, getTutorialStepsSlice, createRestaurantSlice,
        destroyItemSlice, restaurantNotificationsSlice, restaurantCreatePaymentIntentSlice, filtersSlice} from '../reducers/restaurantReducers'
import { fetchReservationCustomerMenu } from '../actions/reservationsActions'
import { api } from '../App'
import qs from 'qs';


const { startCreateConfirmation, createSuccess, createError } = create_confirmationSlice.actions;
  
  const { listTheRestaurants, startListingRestaurants, errorlistRestaurants } = restaurantListSlice.actions
  
  export const fetchRestaurantList = () => async (dispatch, getState) => {
      
      try {
          dispatch(startListingRestaurants(),)
          const {
            userLogin: { userInfo }
          } = getState()

          let config;
          if (userInfo) {
              config = {
                headers: {
                  'Content-type': 'application/json',
                  Authorization: `Bearer ${userInfo.token}`,
                },
              };
            } else {
              config = {
                headers: {
                  'nothing': 'nothing',
                },
              };
            }
  
          await api.get('/restaurants/restaurantsFromUser', config)
              .then((response) => dispatch(listTheRestaurants(response.data)))
      }
      catch (error) {
        dispatch(errorlistRestaurants(error.response && error.response.data.detail 
                                      ? error.response.data.detail
                                      : error.message));
      }
  }

  const { startGetRestaurant, getRestaurant, errorGetRestaurants, RestaurantDetailsReset } = restaurantDetailsSlice.actions
  
  export const fetchRestaurantDetails = (id, reset=false) => async (dispatch, getState) => {
    if (reset){
      dispatch(RestaurantDetailsReset());
    } else {
      const {
        userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
      config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
    } else {
      config = {
        headers: {
          'nothing': 'nothing',
        },
      };
    }
      try {
        dispatch(startGetRestaurant());
        await api.get(`/restaurants/restaurantfromuser/${id}`, config)
          .then((response) => dispatch(getRestaurant(response.data)))
      } catch (error) {
        dispatch(errorGetRestaurants(error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message));
      }
    }
    
  };

  export const updateRestaurant = (id, params) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
      config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
    } else {
      config = {
        headers: {
          'nothing': 'nothing',
        },
      };
    }
      dispatch(startGetRestaurant());
      await api.patch(`/restaurants/restaurantfromuser/${id}`, params, config)
        .then((response) => dispatch(getRestaurant(response.data)))
    } catch (error) {
      dispatch(errorGetRestaurants(error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message));
    }
  };

  // reducers exports
export const { getReservationsFromRestaurantRequest, 
               getReservationsFromRestaurantSucess,
               getReservationsFromRestaurantError, 
               getReservationsFromRestaurantNottingToChange,
               getAllReservationsFromRestaurantRequest,
               getAllReservationsFromRestaurantSucess,
               getAllReservationsFromRestaurantError } = reservationsFromRestaurantSlice.actions




export const fetchReservations = (restaurant_id, dateFilter, statusFilter, autocheck = false) => async (dispatch, getState) => {
  try {
    console.log('ayyy')
    console.log('dateFilter: ', dateFilter,
                'restaurant_id: ', restaurant_id,
                'statusFilter: ', statusFilter)
    const {
        userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    const params = {};
    if (dateFilter) params.dateFilter = dateFilter;
    if (statusFilter && statusFilter !== 'Todos') params.status = statusFilter;
    else if (statusFilter && statusFilter === 'all_pending_reservations') params.status = statusFilter;

    if (!autocheck) {
      dispatch(getReservationsFromRestaurantRequest());
    }
    console.log('called for reservations')
    const { data } = await api.get(`/restaurants/${restaurant_id}/reservations/`, {
      params: params
    }, config);
    console.log('got reservations')
    const existingData = getState().reservationsFromRestaurant.reservations; // Access existing data from Redux state
    if (JSON.stringify(data) !== JSON.stringify(existingData)) {
      dispatch(getReservationsFromRestaurantSucess(data));
    } else {
      dispatch(getReservationsFromRestaurantNottingToChange())
    }
  } catch (error) {
    dispatch(getReservationsFromRestaurantError(error.response && error.response.data.detail 
      ? error.response.data.detail
      : error.message));
  }
};

export const fetchAllReservations = (restaurant_id, dateFilter, shift, placeOfTable, statusFilter) => async (dispatch, getState) => {
  try {
    const {
        userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    const params = {};
    if (dateFilter) params.dateFilter = dateFilter;
    if (shift && shift != 'Todos') params.shift = shift;
    if (placeOfTable && placeOfTable != 'Todos') params.place_of_table = placeOfTable;
    if (statusFilter && statusFilter != 'Todos') params.status = statusFilter; else if (statusFilter && statusFilter == 'all_pending_reservations') params.status = statusFilter
    dispatch(getAllReservationsFromRestaurantRequest());
    const { data } = await api.get(`/restaurants/${restaurant_id}/reservations/`,{
      params: params,
    }, config);
    const existingData = getState().reservationsFromRestaurant.AllReservations; // Access existing data from Redux state
    if (JSON.stringify(data) !== JSON.stringify(existingData)) {
      dispatch(getAllReservationsFromRestaurantSucess(data));
    } else {
      dispatch(getReservationsFromRestaurantNottingToChange())
    }
  } catch (error) {
    dispatch(getAllReservationsFromRestaurantError(error.response && error.response.data.detail 
      ? error.response.data.detail
      : error.message));
  }
}


export const fetchReservation = (reservation_id) => async (dispatch, getState) => {
  try {
      const {
          userLogin: { userInfo }
      } = getState()
      let config;
      if (userInfo) {
          config = {
            headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${userInfo.token}`,
            },
          };
        } else {
          config = {
            headers: {
              'nothing': 'nothing',
            },
          };
        }
      dispatch(getReservationsFromRestaurantRequest(),)

      await api.get(`/restaurants/${reservation_id}/reservations/`, config)
          .then((response) => dispatch(listTheRestaurants(response.data)))
  }
  catch (error) {
    dispatch(errorlistRestaurants(error.response && error.response.data.detail 
                                  ? error.response.data.detail
                                  : error.message));
  }
}

export const { reservationAcceptRequest, reservationAcceptSucess, reservationAcceptError } = reservationAcceptSlice.actions

export const postReservationAccept = (token,
                                      multiple_reservations=false, 
                                      restaurant_id, dateFilter, 
                                      shiftFilter, placeFilter, 
                                      statusFilter, 
                                      rejectEdition=false) => async (dispatch, getState) => {
      
  try {
      const {
        userLogin: { userInfo }
      } = getState()
      let config;
      if (userInfo) {
          config = {
            headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${userInfo.token}`,
            },
          };
        } else {
          config = {
            headers: {
              'nothing': 'nothing',
            },
          };
        }
      dispatch(reservationAcceptRequest(),)
      await api.post(`/restaurants/accept_reservation_from_restaurant/`, {'token':token, 'multiple_reservations': multiple_reservations ? 1 : 0,
                                                                          'rejectEdition':rejectEdition ? 1 : 0}, config)
          .then((response) => dispatch(reservationAcceptSucess(response.data)))
      if (restaurant_id !== false) {
        dispatch(getReservationSums(restaurant_id));
      }
  }
  catch (error) {
    dispatch(reservationAcceptError(error.response && error.response.data.detail 
                                  ? error.response.data.detail
                                  : error.message));
  }
}


export const { availabeTablesToChangeRequest, availabeTablesToChangeSucess, availabeTablesToChangeError } = availabeTablesToChangeSlice.actions

export const tablesToChange = (id, date, time, zone, usedTables, numPeople) => async (dispatch, getState) => {
      
  try {
      const {
        userLogin: { userInfo }
      } = getState()
      let config;
      if (userInfo) {
          config = {
            headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${userInfo.token}`,
            },
          };
        } else {
          config = {
            headers: {
              'nothing': 'nothing',
            },
          };
        }
      dispatch(availabeTablesToChangeRequest(),)

      await api.get(`/restaurants/available_tables/${id}/${date}/${time}/${zone}/${usedTables}/${numPeople}`, config)
          .then((response) => dispatch(availabeTablesToChangeSucess(response.data)))
  }
  catch (error) {
    dispatch(availabeTablesToChangeError(error.response && error.response.data.detail 
                                  ? error.response.data.detail
                                  : error.message));
  }
}

const { changeTableRequest, changeTableSucess, changeTableError } = changeTablesSlice.actions
  
export const changeTables = (token, list, id) => async (dispatch, getState) => {
    try {
        const {
          userLogin: { userInfo }
        } = getState()
        let config;
        if (userInfo) {
            config = {
              headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
              },
            };
          } else {
            config = {
              headers: {
                'nothing': 'nothing',
              },
            };
          }
        dispatch(changeTableRequest(),)
        const data = await api.post(`/restaurants/update_reservation_tables/`,
        { 'token':token ,'list':list, 'id':id},
        config
        )
        dispatch(changeTableSucess(data));
        dispatch(fetchReservationCustomerMenu(token, 'no'));
}
    catch (error) {
    dispatch(changeTableError(error.response && error.response.data.detail 
                                    ? error.response.data.detail
                                    : error.message));
    }
}

                    /* time management */
const { startFetchingOpenDays, fetchOpenDaysSuccess, fetchOpenDaysError } = restaurantOpenDaysOfTheWeekSlice.actions;

export const fetchRestaurantOpenDays = (restaurant_id) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    dispatch(startFetchingOpenDays());
    await api.get(`/restaurants/${restaurant_id}/opentimes`, config)
      .then((response) => dispatch(fetchOpenDaysSuccess(response.data)));
  } catch (error) {
    dispatch(fetchOpenDaysError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};

const { startFetchingEstimatedTime, fetchEstimatedTimeSuccess, fetchEstimatedTimeError } = estimatedTimeCustomersSpendSlice.actions;

export const fetchEstimatedTimeCustomersSpend = (restaurant_id) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    dispatch(startFetchingEstimatedTime());
    await api.get(`/restaurants/${restaurant_id}/estimatedtime`, config)
      .then((response) => dispatch(fetchEstimatedTimeSuccess(response.data)));
  } catch (error) {
    dispatch(fetchEstimatedTimeError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};

const { startFetchingClosedExceptions, fetchClosedExceptionsSuccess, fetchClosedExceptionsError } = closedExceptionsSlice.actions;

export const fetchClosedExceptions = (restaurant_id) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    dispatch(startFetchingClosedExceptions());
    await api.get(`/restaurants/${restaurant_id}/closedexceptions`, config)
      .then((response) => dispatch(fetchClosedExceptionsSuccess(response.data)));
  } catch (error) {
    dispatch(fetchClosedExceptionsError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};
                  /* time management  patch*/
export const patchRestaurantOpenDays = (restaurant_id, pk ,params) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    dispatch(startFetchingOpenDays());
    await api.patch(`/restaurants/${restaurant_id}/opentimes/${pk}/`, params, config)
      .then((response) => dispatch(fetchOpenDaysSuccess(response.data)));
    dispatch(fetchRestaurantOpenDays(restaurant_id));
  } catch (error) {
    dispatch(fetchOpenDaysError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};

export const patchEstimatedTimeCustomersSpend = (restaurant_id, pk ,params) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    dispatch(startFetchingEstimatedTime());
    await api.patch(`/restaurants/${restaurant_id}/estimatedtime/${pk}/`, params, config)
      .then((response) => dispatch(fetchEstimatedTimeSuccess(response.data)));
      dispatch(fetchEstimatedTimeCustomersSpend(restaurant_id));
  } catch (error) {
    dispatch(fetchEstimatedTimeError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};

export const patchClosedExceptions = (restaurant_id, pk ,params) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    dispatch(startFetchingClosedExceptions());
    await api.patch(`/restaurants/${restaurant_id}/closedexceptions/${pk}`, params, config)
      .then((response) => dispatch(fetchClosedExceptionsSuccess(response.data)));
  } catch (error) {
    dispatch(fetchClosedExceptionsError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};


            /* time management  create */
export const createRestaurantOpenDays = (restaurant_id, params) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    dispatch(startCreateConfirmation());
    await api.post(`/restaurants/${restaurant_id}/opentimes/`, params, config)
      .then((response) => dispatch(createSuccess(response.data)));
    dispatch(fetchRestaurantOpenDays(restaurant_id));
  } catch (error) {
    dispatch(createError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};

export const createEstimatedTimeCustomersSpend = (restaurant_id, params) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    dispatch(startCreateConfirmation());
    await api.post(`/restaurants/${restaurant_id}/estimatedtime/`, params, config)
      .then((response) => dispatch(createSuccess(response.data)));
      dispatch(fetchEstimatedTimeCustomersSpend(restaurant_id));
  } catch (error) {
    dispatch(createError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};

export const createClosedExceptions = (restaurant_id, params) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    dispatch(startCreateConfirmation());
    await api.post(`/restaurants/${restaurant_id}/closedexceptions/`, params, config)
      .then((response) => dispatch(createSuccess(response.data)));
    dispatch(fetchClosedExceptions(restaurant_id));
  } catch (error) {
    dispatch(createError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};


                    /* Tables managements */

const { startFetchingPlaceOfTable, fetchPlaceOfTableSuccess, fetchPlaceOfTableError } = placeOfTableSlice.actions;

export const fetchPlaceOfTable = (restaurant_id) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    dispatch(startFetchingPlaceOfTable());
    await api.get(`/restaurants/${restaurant_id}/placeoftable`, config)
      .then((response) => dispatch(fetchPlaceOfTableSuccess(response.data)));
  } catch (error) {
    dispatch(fetchPlaceOfTableError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};

const { startFetchingTables, fetchTablesSuccess, fetchTablesError } = tablesSlice.actions;

export const fetchTables = (restaurant_id) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    dispatch(startFetchingTables());
    await api.get(`/restaurants/${restaurant_id}/tables`, config)
      .then((response) => dispatch(fetchTablesSuccess(response.data)));
  } catch (error) {
    dispatch(fetchTablesError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};

const { startFetchingConnectTables, fetchcanConnectTablesSuccess, fetchConnectTablesError } = fetchConnectTablesSlice.actions;

export const fetchConnectTablesConfig = (restaurant_id) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    dispatch(startFetchingConnectTables());
    await api.get(`/restaurants/${restaurant_id}/numberofpeopleperconnectedtable/`, config)
      .then((response) => dispatch(fetchcanConnectTablesSuccess(response.data)));
  } catch (error) {
    dispatch(fetchConnectTablesError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};


                      /* Tables management patch */

export const patchPlaceOfTable = (restaurant_id, pk ,params) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    dispatch(startCreateConfirmation());
    await api.patch(`/restaurants/${restaurant_id}/placeoftable/${pk}/`, params, config)
      .then((response) => dispatch(createSuccess(response.data)));
  } catch (error) {
    dispatch(createError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};

export const patchTables = (restaurant_id, pk , params) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    dispatch(startCreateConfirmation());
    await api.patch(`/restaurants/${restaurant_id}/tables/${pk}/`, params, config)
      .then((response) => dispatch(createSuccess(response.data)));
    dispatch(fetchTables(restaurant_id));
  } catch (error) {
    dispatch(createError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};

                      /* Tables management create */

export const createPlaceOfTable = (restaurant_id, params) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    dispatch(startCreateConfirmation());
    await api.post(`/restaurants/${restaurant_id}/placeoftable/`, params, config)
      .then((response) => dispatch(createSuccess(response.data)));
    dispatch(fetchPlaceOfTable(restaurant_id));  
  } catch (error) {
    dispatch(createError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};

export const createTables = (restaurant_id, params) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    dispatch(startCreateConfirmation());
    await api.post(`/restaurants/${restaurant_id}/tables/`, params, config)
      .then((response) => dispatch(createSuccess(response.data)));
      dispatch(fetchTables(restaurant_id));
  } catch (error) {
    dispatch(createError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};


const { startFetchingHoursEach, fetchHoursEachSuccess, fetchHoursEachError } = fetchHoursEachTimeSlice.actions;

export const fetchHoursEach = (restaurant_id) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    dispatch(startFetchingHoursEach());
    await api.get(`/restaurants/time_slots/${restaurant_id}`, config)
      .then((response) => dispatch(fetchHoursEachSuccess(response.data)));
  } catch (error) {
    dispatch(fetchHoursEachError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};

const { startFetchingAllShifts, fetchAllShiftsSuccess, fetchAllShiftsError } = fetchAllExistingShiftsSlice.actions;

export const fetchAllShifts = (restaurant_id) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    dispatch(startFetchingAllShifts());
    await api.get(`/restaurants/shifts/${restaurant_id}`, config)
      .then((response) => dispatch(fetchAllShiftsSuccess(response.data)));
  } catch (error) {
    dispatch(fetchAllShiftsError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};

const { startGetReservationSums, getReservationSumsSuccess, getReservationSumsError } = getReservationSumsSlice.actions;

export const getReservationSums = (restaurant_id) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    dispatch(startGetReservationSums());
    await api.get(`/restaurants/reservation-sums/${restaurant_id}`, config)
      .then((response) => dispatch(getReservationSumsSuccess(response.data)));
  } catch (error) {
    dispatch(getReservationSumsError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};

const { startCustomerAttendUpdate, 
        customerAttendUpdateSuccess, 
        customerAttendUpdateError,
        startArrangedTableUpdate,
        arrangedTableSuccess,
        ArrangedTableError,
        startChangeTableNumber,
        changeTableNumberSuccess,
        changeTableNumberError,
        customerAttendUpdateReset } = customerAttendUpdateSlice.actions;

export const updateCustomerAttendance = (token, status) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    console.log('config attendance: ', config)
    dispatch(startCustomerAttendUpdate());
    await api.patch(`/restaurants/update_attendance/`,
    {
      'token':token,
      'attended_choice': status,
    },
    config)
      .then((response) => dispatch(customerAttendUpdateSuccess(response.data)));
  } catch (error) {
    dispatch(customerAttendUpdateError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};

export const resetAttendance = () => async (dispatch) => {
  dispatch(customerAttendUpdateReset());
};

export const updateArrangedTable = (token, status) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    dispatch(startArrangedTableUpdate());
    await api.patch(`/restaurants/arrange_table/`,
    {
      'token':token,
      'status': status,
    }, config)
      .then((response) => dispatch(arrangedTableSuccess(response.data)));
  } catch (error) {
    dispatch(ArrangedTableError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};

export const changeTableNumber = (token, number) => async (dispatch, getState) => {
  try {
    dispatch(startChangeTableNumber());
    const {
      userLogin: { userInfo }
    } = getState()
    let config;
        if (userInfo) {
            config = {
              headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
              },
            };
          } else {
            config = {
              headers: {
                'nothing': 'nothing',
              },
            };
          }
      console.log('config: ', config)
    
    await api.post(`/restaurants/change_table_number/${token}/${number}/`, {}, config)
      .then((response) => dispatch(changeTableNumberSuccess(response.data)));
  } catch (error) {
    dispatch(changeTableNumberError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};

const { startGetTutorialSteps, getTutorialStepsSuccess, getTutorialStepsError } = getTutorialStepsSlice.actions;

export const getTutorialSteps = (step) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    dispatch(startGetTutorialSteps());
    await api.get(`/restaurants/tutorial_steps/`,
    {
      'step':step,
    }, config)
      .then((response) => dispatch(getTutorialStepsSuccess(response.data)));
  } catch (error) {
    dispatch(getTutorialStepsError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};

const { startCreateRestaurant, CreateRestaurantSuccess, CreateRestaurantError, CreateRestaurantReset } = createRestaurantSlice.actions;

export const createRestaurant = (params, reset=false) => async (dispatch, getState) => {
  if (reset === true) {
    dispatch(CreateRestaurantReset());
  } else {
    try {
      dispatch(startCreateRestaurant());
  
      const {
          userLogin: { userInfo }
      } = getState()
      let config;
      if (userInfo) {
          config = {
            headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${userInfo.token}`,
            },
          };
        } else {
          config = {
            headers: {
              'nothing': 'nothing',
            },
          };
        }
      await api.post(`/restaurants/create_restaurant/`,{'params':params},config)
        .then((response) => dispatch(CreateRestaurantSuccess(response.data)));
      } catch (error) {
        dispatch(CreateRestaurantError(error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message));
      }

  }
 
};

const { startDestroyItem, destroyItemSuccess, destroyItemError } = destroyItemSlice.actions;

export const destroyItems = (id, item_model) => async (dispatch, getState) => {
  try {
    dispatch(startDestroyItem());
   
    const {
        userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    if (item_model === 'restaurants') {
      await api.delete(`/restaurants/details/${id}`, config)
      .then((response) => dispatch(destroyItemSuccess(response.data)));
      dispatch(fetchRestaurantList());
    }
    
    } catch (error) {
      dispatch(destroyItemError(error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message));
    }
};

const { restaurantNotificationsStart, 
        restaurantNotificationsSuccess, 
        restaurantNotificationsError,

        createNotificationStart,
        createNotificationSuccess,
        createNotificationError,

        updateNotificationStart,
        updateNotificationSuccess,
        updateNotificationError,

        confirmNotificationStart,
        confirmNotificationSuccess,
        confirmNotificationError } = restaurantNotificationsSlice.actions;

export const getRestaurantNotificationsList = (restaurant_id) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    dispatch(restaurantNotificationsStart());
    await api.get(`/restaurants/${restaurant_id}/restaurant_notifications/`, config)
      .then((response) => dispatch(restaurantNotificationsSuccess(response.data)));
  } catch (error) {
    dispatch(restaurantNotificationsError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};

export const createRestaurantNotificationsList = (restaurant_id, params) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    dispatch(createNotificationStart());

    await api.post(`/restaurants/${restaurant_id}/restaurant_notifications/`, params, config)
      .then((response) => dispatch(createNotificationSuccess(response.data)));
    dispatch(getRestaurantNotificationsList(restaurant_id));
  } catch (error) {
    dispatch(createNotificationError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};

export const updateRestaurantNotificationsList = (restaurant_id, pk,params) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    dispatch(updateNotificationStart());
    await api.patch(`/restaurants/${restaurant_id}/restaurant_notifications/${pk}/`, params, config)
      .then((response) => dispatch(updateNotificationSuccess(response.data)));
  } catch (error) {
    dispatch(updateNotificationError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};

export const confirmNotification = (token) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    dispatch(confirmNotificationStart());
    await api.patch(`/restaurants/confirm_notifications/${token}/`, {}, config)
      .then((response) => dispatch(confirmNotificationSuccess(response.data)));
  } catch (error) {
    dispatch(confirmNotificationError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};



const { createPaymentIntentStart, 
        createPaymentIntentSuccess, 
        createPaymentIntentError, 
        paymentIntentReset,
        confirmSubscriptionStart,
        confirmSubscriptionSuccess,
        confirmSubscriptionError,
        confirmSubscriptionReset,
        resetConfirmPayment,
        veriySubscriptionStart,
        veriySubscriptionSuccess,
        veriySubscriptionError,
        getSubscriptionPackStart,
        getSubscriptionPackSuccess,
        getSubscriptionPackError} = restaurantCreatePaymentIntentSlice.actions;


export const createPaymentIntent = (restaurant_id, subscription_number, payment_method) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    dispatch(createPaymentIntentStart());
    await api.post(`/restaurants/create_payment_intent/${restaurant_id}/${subscription_number}/${payment_method}/`, {}, config)
      .then((response) => dispatch(createPaymentIntentSuccess(response.data)));
  } catch (error) {
    dispatch(createPaymentIntentError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};

export const resetCreatePaymentIntent = () => async (dispatch) => {
  dispatch(resetConfirmPayment());
};


export const confirmSubscription = (restaurant_id, subscription_number, token, isToSaveCard) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    dispatch(confirmSubscriptionStart());
    await api.post(`/restaurants/confirm_subscription/${restaurant_id}/${subscription_number}/${token}/${isToSaveCard}/`, {}, config)
      .then((response) => dispatch(confirmSubscriptionSuccess(response.data)));
  } catch (error) {
    dispatch(confirmSubscriptionError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};


export const resetConfirmSubscription= () => async (dispatch) => {
    dispatch(confirmSubscriptionReset());
};

export const getSubscriptionsPacks = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    dispatch(getSubscriptionPackStart());
    await api.get(`/restaurants/get_subscription_packs/`, config)
      .then((response) => dispatch(getSubscriptionPackSuccess(response.data)));
  } catch (error) {
    dispatch(getSubscriptionPackError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};

export const verifySubscription = (restaurant_id) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo }
    } = getState()
    let config;
    if (userInfo) {
        config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            'nothing': 'nothing',
          },
        };
      }
    dispatch(veriySubscriptionStart());
    await api.post(`/restaurants/verify_subscription/${restaurant_id}/`, {}, config)
      .then((response) => dispatch(veriySubscriptionSuccess(response.data)));
  } catch (error) {
    dispatch(veriySubscriptionError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};

const { startCreatingFilters, 
        createFiltersSuccess, 
        createFiltersError, 
        startFetchingFilters,
        fetchingFiltersSuccess,
        fetchingFiltersError,
        startDeletingFilters,
        deletingFiltersSuccess,
        deletingFiltersError,
        startUpdatingFilters,
        updatingFiltersSuccess,
        updatingFiltersError} = filtersSlice.actions;

export const createFilters = (restaurant_id, filterData) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    let config;
    if (userInfo) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
    } else {
      config = {
        headers: {
          'nothing': 'nothing',
        },
      };
    }

    dispatch(filtersSlice.actions.startCreatingFilters());

    await api.post(`/restaurants/filters/${restaurant_id}/`, filterData, config)
      .then((response) => dispatch(filtersSlice.actions.createFiltersSuccess(response.data)));

  } catch (error) {
    dispatch(filtersSlice.actions.createFiltersError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};

export const fetchFilters = (restaurant_id) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    let config;
    if (userInfo) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
    } else {
      config = {
        headers: {
          'nothing': 'nothing',
        },
      };
    }

    dispatch(filtersSlice.actions.startFetchingFilters());

    await api.get(`/restaurants/filters/${restaurant_id}`, config)
      .then((response) => dispatch(filtersSlice.actions.fetchingFiltersSuccess(response.data)));

  } catch (error) {
    dispatch(filtersSlice.actions.fetchingFiltersError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};

export const deleteFilters = (restaurant_id, filters_ids) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    let config;
    if (userInfo) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
    } else {
      config = {
        headers: {
          'nothing': 'nothing',
        },
      };
    }

    dispatch(filtersSlice.actions.startDeletingFilters());

    api.delete(`/restaurants/filter/delete/${restaurant_id}/`, { 
      data: filters_ids, 
      headers: config.headers 
    }).then(() => {
      dispatch(filtersSlice.actions.deletingFiltersSuccess(filters_ids));
    }).catch((error) => {
      // Handle any errors
    });

  } catch (error) {
    dispatch(filtersSlice.actions.deletingFiltersError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};

export const updateFilters = (restaurant_id, filtersData) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    let config;
    if (userInfo) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
    } else {
      config = {
        headers: {
          'nothing': 'nothing',
        },
      };
    }

    // Assuming you might have actions like startUpdatingFilters, updatingFiltersSuccess, and updatingFiltersError
    dispatch(startUpdatingFilters());

    await api.patch(`/restaurants/filter/update/${restaurant_id}/`, 
      filtersData, 
     config
    )
      .then((response) => dispatch(updatingFiltersSuccess(response.data)))
      .catch((error) => {
        throw error;
      });
      dispatch(fetchFilters(restaurant_id));
  } catch (error) {
    dispatch(updatingFiltersError(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message));
  }
};


