import { reservationBlockedDaysSlice, reservationBlockedWeekdaysSlice, reservationShiftSlice, 
        openTimeSlice, createReservationSlice, cancelReservationSlice, editReservationSlice,reservationCustomersMenuSlice,
        reservationActiveSlice, reservationLimitPeopleBabySlice } from '../reducers/reservationReducers'
import { api } from '../App'
import { placeOfTableSlice } from '../reducers/restaurantReducers'
import { fetchReservations, getReservationSums, changeTables, fetchAllReservations } from '../actions/restaurantActions'

const { reservationBlockedDaysRequest, reservationBlockedDaysSucess, reservationBlockedDaysError } = reservationBlockedDaysSlice.actions
  
export const fetchReservationBlockedDays = (id) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo }
        } = getState()
        let config;
        if (userInfo) {
            config  = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`,
                }
            }
        } else {
            config = {
                'nothing':'nothing',
            }
        }
        

        dispatch(reservationBlockedDaysRequest(),)

        await api.get(`/reservations/get/blockeddates/${id}/`, config)
            .then((response) => dispatch(reservationBlockedDaysSucess(response.data)))
    }
    catch (error) {
    dispatch(reservationBlockedDaysError(error.response && error.response.data.detail 
                                    ? error.response.data.detail
                                    : error.message));
    }
}

const { reservationBlockedWeekdaysRequest, reservationBlockedWeekdaysSucess, reservationBlockedWeekdaysError,
    isRestaurantOpenTodayRequest, isRestaurantOpenTodaySucess, isRestaurantOpenTodayError,
    restaurantTimeZoneRequest, restaurantTimeZoneSucess, restaurantTimeZoneError} = reservationBlockedWeekdaysSlice.actions
  
export const fetchReservationBlockedWeekdays = (id) => async (dispatch, getState) => {
    
    try {
        const {
            userLogin: { userInfo }
        } = getState()
        let config;
        if (userInfo) {
            config = {
              headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
              },
            };
          } else {
            config = {
              headers: {
                'nothing': 'nothing',
              },
            };
          }
        dispatch(reservationBlockedWeekdaysRequest(),)

        await api.get(`/reservations/get/blockedweekdays/${id}/`, config)
            .then((response) => dispatch(reservationBlockedWeekdaysSucess(response.data)))
    }
    catch (error) {
    dispatch(reservationBlockedWeekdaysError(error.response && error.response.data.detail 
                                    ? error.response.data.detail
                                    : error.message));
    }
}

export const checkIfrestaurantIsOpenToday = (restaurant_id) => async (dispatch, getState) => {
    
    try {
        const {
            userLogin: { userInfo }
        } = getState()
        const config  = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            }
        }
        dispatch(isRestaurantOpenTodayRequest(),)

        await api.get(`/reservations/check_restaurant_open_today/${restaurant_id}/`, config)
            .then((response) => dispatch(isRestaurantOpenTodaySucess(response.data)))
    }
    catch (error) {
    dispatch(isRestaurantOpenTodayError(error.response && error.response.data.detail 
                                    ? error.response.data.detail
                                    : error.message));
    }
}

export const getRestaurantTimeZone = (restaurant_id) => async (dispatch, getState) => {
    
    try {
        const {
            userLogin: { userInfo }
        } = getState()
        let config;
        if (userInfo) {
            config = {
              headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
              },
            };
          } else {
            config = {
              headers: {
                'nothing': 'nothing',
              },
            };
          }
        dispatch(restaurantTimeZoneRequest(),)

        await api.get(`/reservations/getRestaurantTimeZone/${restaurant_id}/`, config)
            .then((response) => dispatch(restaurantTimeZoneSucess(response.data)))
    }
    catch (error) {
    dispatch(restaurantTimeZoneError(error.response && error.response.data.detail 
                                    ? error.response.data.detail
                                    : error.message));
    }
}

const { reservationShiftRequest, reservationShiftSucess, reservationShiftError } = reservationShiftSlice.actions
  
export const fetchReservationShift = (id, date) => async (dispatch, getState) => {
    
    try {
        const {
            userLogin: { userInfo }
        } = getState()
        let config;
        if (userInfo) {
            config = {
              headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
              },
            };
          } else {
            config = {
              headers: {
                'nothing': 'nothing',
              },
            };
          }
        dispatch(reservationShiftRequest(),)

        await api.post(`/reservations/get/shifts/`,
        { 'id':id ,'date':date},
        config
        )
            .then((response) => dispatch(reservationShiftSucess(response.data)))
    }
    catch (error) {
    dispatch(reservationShiftError(error.response && error.response.data.detail 
                                    ? error.response.data.detail
                                    : error.message));
    }
}

const { openTimeRequest, openTimeSucess, openTimeError } = openTimeSlice.actions
  
export const fetchOpenTime = (id, date, numberOfPeople, token=false, ignoreLimitations=false) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo }
        } = getState()
        let config;
        if (userInfo) {
            config = {
              headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
              },
            };
          } else {
            config = {
              headers: {
                'nothing': 'nothing',
              },
            };
          }
        if (date != null) {
        dispatch(openTimeRequest(),)
        await api.post(`/reservations/post/getavailabletimes/`,
        { 'id':id ,'date':date, 'numberOfPeople':numberOfPeople, 'token':token, 'ignoreLimitations':ignoreLimitations},
        config
        )
            .then((response) => dispatch(openTimeSucess(response.data)))
        }
        
    }
    catch (error) {
    dispatch(openTimeError(error.response && error.response.data.detail 
                                    ? error.response.data.detail
                                    : error.message));
    }
}

const { createReservationRequest, createReservationSucess, 
        createReservationError, resetReservationError, 
        toastCreateSuccess, toastCreateCancel,
        postPhoneConfirmationStart,
        postPhoneConfirmationSucess,
        postPhoneConfirmationError,
        postResendPhoneConfirmationStart,
        postResendPhoneConfirmationSucess,
        postResendPhoneConfirmationError,
        startCreateLoader, stopCreateLoader,
        postCheckCancelStart,
        postCheckCancelSucess,
        postCheckCancelError } = createReservationSlice.actions
  
export const fetchCreateReservation = (id, date, numberOfPeople, shift, 
    time, name, phone_number, country_code, baby_chair, message, placeOfTable, 
    subscription, accessability, isItFromRestaurant=false, ignoreLimitations, fullTableInfo) => async (dispatch, getState) => {
    
    try {
        const {
            userLogin: { userInfo }
          } = getState()
          let config;
        if (userInfo) {
            config = {
              headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
              },
            };
          } else {
            config = {
              headers: {
                'nothing': 'nothing',
              },
            };
          }
        dispatch(createReservationRequest(),)

        const response = await api.post(`/reservations/post/createReservation/`,
        {
            'id': id,
            'date': date,
            'numberOfPeople': numberOfPeople,
            'shift': shift,
            'time': time,
            'name': name,
            'phone_number': phone_number,
            'baby_chair': baby_chair,
            'message': message,
            'placeOfTables':placeOfTable,
            'subscription':subscription,
            'accessability':accessability,
            'isItFromRestaurant':isItFromRestaurant,
            'country_code': country_code,
            'ignoreLimitations': ignoreLimitations,
        },config)
        dispatch(createReservationSucess(response.data));
        localStorage.setItem("createReservation", JSON.stringify(response.data));
        dispatch(fetchReservationCustomerMenu(response.data.token, 'no', true));
        if (ignoreLimitations) {
            dispatch(changeTables(response.data.token, fullTableInfo, id));
        }
        if (response.data.error) {
            console.error('response.data.error',response.data.error);  // If there's an error, log it
        } else {
            // Handle successful response
        }

    }
    catch (error) {
    dispatch(createReservationError(error.response && error.response.data.detail 
                                    ? error.response.data.detail
                                    : error.message));
    }
}

export const postPhoneConfirmation = (token, answer) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo }
          } = getState()
          let config;
        if (userInfo) {
            config = {
              headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
              },
            };
          } else {
            config = {
              headers: {
                'nothing': 'nothing',
              },
            };
          }
        dispatch(postPhoneConfirmationStart());
        const response = await api.post(`/reservations/phone_confirmation/${token}/${answer}/`, {}, config);
        dispatch(postPhoneConfirmationSucess(response.data));
        dispatch(fetchReservationCustomerMenu(token, 'no', true));
    }
    catch (error) {
        dispatch(postPhoneConfirmationError(error.response && error.response.data.detail 
                                    ? error.response.data.detail
                                    : error.message));
    }
};

export const checkCancelAction = (token) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo }
          } = getState()
          let config;
        if (userInfo) {
            config = {
              headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
              },
            };
          } else {
            config = {
              headers: {
                'nothing': 'nothing',
              },
            };
          }
        dispatch(postCheckCancelStart());
        const response = await api.post(`/reservations/warning_check/${token}/`, {}, config);
        dispatch(postCheckCancelSucess(response.data));
    }
    catch (error) {
        dispatch(postCheckCancelError(error.response && error.response.data.detail 
                                    ? error.response.data.detail
                                    : error.message));
    }
};

export const postResendPhoneConfirmation = (token, country_code, phone_number) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo }
          } = getState()
          let config;
        if (userInfo) {
            config = {
              headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
              },
            };
          } else {
            config = {
              headers: {
                'nothing': 'nothing',
              },
            };
          }
        dispatch(postResendPhoneConfirmationStart());

        const response = await api.post(`/reservations/resend_phone_confirmation/${token}/${country_code}/${phone_number}/`, 
        {}, config);
        
        dispatch(postResendPhoneConfirmationSucess(response.data));

        dispatch(fetchReservationCustomerMenu(token, 'no', true));
    }
    catch (error) {
        dispatch(postResendPhoneConfirmationError(error.response && error.response.data.detail 
                                    ? error.response.data.detail
                                    : error.message));
    }
};

export const fetchResetReservation = () => async dispatch => {
    dispatch(resetReservationError());
}
const { cancelReservationRequest, cancelReservationSucess, cancelReservationError } = cancelReservationSlice.actions
  
export const fetchCancelReservation = (token, isCustomer=false, restaurant_id, dateFilter, shiftFilter, placeFilter, 
    statusFilter, rejectEdition=false) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo }
          } = getState()
          let config;
        if (userInfo) {
            config = {
              headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
              },
            };
          } else {
            config = {
              headers: {
                'nothing': 'nothing',
              },
            };
          }
        dispatch(cancelReservationRequest(),)
        const data = { 'isCustomer': isCustomer, 'token':token, 'rejectEdition':rejectEdition ? 1 : 0}
        await api.post(`/reservations/cancel_reservation/`, data, config)
            .then((response) => dispatch(cancelReservationSucess(response.data)))
        if (restaurant_id !== false) {
            dispatch(getReservationSums(restaurant_id));
        }
        
    }
    catch (error) {
    dispatch(cancelReservationError(error.response && error.response.data.detail 
                                    ? error.response.data.detail
                                    : error.message));
    }
}

const { editReservationRequest, editReservationSucess, editReservationError } = editReservationSlice.actions
  
export const fetchEditReservation = (token, id, date, 
    numberOfPeople, shift, time, name, 
    phone_number, country_code , 
    baby_chair, message, placeOfTable, 
    subscription, accessability, ignoreLimitations=false, 
    fullTableInfo, from_restaurant) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo }
          } = getState()
          let config;
        if (userInfo) {
            config = {
              headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
              },
            };
          } else {
            config = {
              headers: {
                'nothing': 'nothing',
              },
            };
          }
        dispatch(editReservationRequest(),)
        const response = await api.post(`/reservations/edit_reservation/${token}/`,
        {
            'id': id,
            'date': date,
            'numberOfPeople': numberOfPeople,
            'shift': shift,
            'time': time,
            'name': name,
            'phone_number': phone_number,
            'baby_chair': baby_chair,
            'message': message,
            'placeOfTables': placeOfTable,
            'subscription':subscription,
            'accessability':accessability,
            'country_code':country_code,
            'token':token,
            'ignoreLimitations':ignoreLimitations,
            'from_restaurant':from_restaurant,
        }, config)
            dispatch(editReservationSucess(response.data))
            dispatch(fetchReservationCustomerMenu(token, 'no', true))
            if (fullTableInfo !== false && from_restaurant) {
                dispatch(changeTables(token, fullTableInfo, id));
            }
            console.log('donee')
    }
    catch (error) {
    dispatch(editReservationError(error.response && error.response.data.detail 
                                    ? error.response.data.detail
                                    : error.message));
    }
}

const { getReservationCustomerRequest, 
    getReservationCustomerSucess, 
    getReservationCustomerError, 
    resetReservationCustomerMenu,
    startCustomerMenuLoader,
    stopCustomerMenuLoader} = reservationCustomersMenuSlice.actions

export const fetchReservationCustomerMenu = (reservation_id, change_satatus, isLoader=false) => async (dispatch, getState) => {
      
    try {
        const {
            userLogin: { userInfo }
          } = getState()
          let config;
        if (userInfo) {
            config = {
              headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
              },
            };
          } else {
            config = {
              headers: {
                'nothing': 'nothing',
              },
            };
          }
        if (isLoader) {
            dispatch(startCustomerMenuLoader());
        }
        
        dispatch(getReservationCustomerRequest(),)
  
        await api.get(`/reservations/customersmenu/${reservation_id}/${change_satatus}`, config)
            .then((response) => dispatch(getReservationCustomerSucess(response.data)))
        if (isLoader) {
            dispatch(stopCustomerMenuLoader());
        }
        
    }
    catch (error) {
      dispatch(getReservationCustomerError(error.response && error.response.data.detail 
                                    ? error.response.data.detail
                                    : error.message));
    }
  }

  export const ResetReservation = () => async dispatch => {
    dispatch(resetReservationCustomerMenu())
  }

  const { getPeopleBabyRequest, getLimitsPeopleBabySucess, getPeopleBabyError } = reservationLimitPeopleBabySlice.actions

  export const fetchReservationLimitPeopleBaby = (reservation_id, date, time) => async (dispatch, getState) => {
    let time_to_send = time;
    if (time === '') {
        time_to_send = null;
    }
      try {
        const {
            userLogin: { userInfo }
          } = getState()
          let config;
        if (userInfo) {
            config = {
              headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
              },
            };
          } else {
            config = {
              headers: {
                'nothing': 'nothing',
              },
            };
          }
          dispatch(getPeopleBabyRequest(),)
          await api.get(`/reservations/reservation-limits/${reservation_id}/${date}/${time_to_send}/`, config)
              .then((response) => dispatch(getLimitsPeopleBabySucess(response.data)))
      }
      catch (error) {
        dispatch(getPeopleBabyError(error.response && error.response.data.detail 
                                      ? error.response.data.detail
                                      : error.message));
      }
    }

  const { getReservationActiveRequest, getReservationActiveError, getReservationDeactivateRequest } = reservationActiveSlice.actions

export const fetchReservationActive = () => async (dispatch, getState) => {
      
    try {
        dispatch(getReservationActiveRequest());
    }
    catch (error) {
      dispatch(getReservationActiveError(error.response && error.response.data.detail 
                                    ? error.response.data.detail
                                    : error.message));
    }
  }

  export const fetchReservationDeactivate = () => async dispatch => {
      
    try {
        dispatch(getReservationDeactivateRequest())
  
    }
    catch (error) {
      dispatch(getReservationActiveError(error.response && error.response.data.detail 
                                    ? error.response.data.detail
                                    : error.message));
    }
  }

  const { reservationUpdateStart, reservationUpdateSucess, reservationUpdateError } = createReservationSlice.actions

  export const updateReservation = (reservation_id, params) => async (dispatch, getState) => {
    try {
      const {
          userLogin: { userInfo }
        } = getState()
        let config;
      if (userInfo) {
          config = {
            headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${userInfo.token}`,
            },
          };
        } else {
          config = {
            headers: {
              'nothing': 'nothing',
            },
          };
        }
        console.log('params', params)
        console.log('reservation_id', reservation_id)
        console.log('config', config)
        dispatch(reservationUpdateStart(),)
        await api.patch(`/reservations/update/${reservation_id}/`, params, config)
            .then((response) => dispatch(reservationUpdateSucess(response.data)))
    }
    catch (error) {
      dispatch(reservationUpdateError(error.response && error.response.data.detail 
                                    ? error.response.data.detail
                                    : error.message));
    }
  }

