import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import ClosedExceptions from '../components/ClosedExceptions';
import styled from 'styled-components';
import SubMenu from '../components/SubMenu';
import Separator from '../components/Separator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faChair, faGear, faCircleXmark } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const ModernButton = styled.button`
  padding: 5px 8px;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0056b3;
  }
`;

const Wrapper = styled.div`
  position: relative;
  margin: 10px 0;
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
  border-radius: 5px;
  color: black;
  border: 1px solid #e8e6e1;
`;

const WrapperTop = styled.div`
  width:100%;
  color:white;
  height:60px;
  background-color:#8FEBC5;
  border-radius: 5px 5px 0 0; 
  margin-bottom:10px;
`;

const WrapperBot = styled.div`
  width:100%;
  height:auto;
  padding:10px;
`;


const Title = styled.h6`
  padding: 20px;
  color: white;
`;

const ZoneTitle = styled.h2`
  color:white;
  font-size:18px;
  padding: 5px;
`;


function RestaurantOpenedOrLimitedExceptions() {


    const dispatch = useDispatch();
    const params = useParams();
    const [restaurant_id, setRestaurant_id] = useState(params.restaurant_id);


  return (
    <Container>
        <Row>
            <Col sm={0} md={0} xl={4}>
            <SubMenu restaurant_id={restaurant_id} />
            </Col>
            <Col xs={12} md={12} xl={8}>
            {/* <Separator
                    step={'Fechar ou limitar dias no restaurante'}
                    stepName={'tables_and_zones'}
                /> */}
                <ClosedExceptions 
                    restaurant_id={restaurant_id}
                />
            </Col>
        </Row>
    </Container>
    
  )
}

export default RestaurantOpenedOrLimitedExceptions