import React, { useEffect } from 'react';
import { update } from 'react-spring';
import styled from 'styled-components';

const CustomSelectOption = styled.div`
  display: inline-flex;
  align-items: center;
  position:relative;
  padding: 5px;
  font-size: 1rem;
  border: 1px solid ${({ borderColor }) => borderColor || 'black'};
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: ${({ isF }) => (isF ? 'none' : 'pointer')};
  pointer-events: ${({ isF }) => (isF ? 'none' : 'auto')}; // block all events when isF is true
  background-color: ${({ isSelected, isF }) => (isF ? '#ed5d1a' : isSelected ? '#28d493' : 'transparent')};
  color: ${({ isSelected, isF }) => (isF ? 'white' : isSelected ? 'white' : 'black')};
  transition: all 0.2s ease-in-out;
  margin-right: 10px;

  &:hover {
    border-color: #88F1D3;
  }
`;

const WarningMessage = styled.span`
  color: red;
  position:absolute;
  top: 31px;
  font-size: 13px;
  left: 0;
`;

const CustomSelectText = styled.span`
  margin-right: 5px;
`;

const CustomSelectCheckWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid black;
  border-radius: 50%;
  background-color: white;
  margin-left: 5px;
  position: relative;
`;

const CustomSelectCheck = styled.span`
  position: absolute;
  z-index: 1;
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};
  color: black;
`;

function CustomSelect({ items, onSelectChange, selectedItem, getBorderColor, update, setUpdate=false}) {
  const handleItemClick = (item) => {
    onSelectChange(item);
    if (setUpdate) {
      setUpdate(update + 1)
    }
  };

  return (
    <div>
      {items && 
      <>
      {items.map((item, index) => (
        <>
         <CustomSelectOption
            key={item}
            id={`custom-select-option-${index}`}
            isSelected={selectedItem === item}
            onClick={() => handleItemClick(item)}
            borderColor={getBorderColor ? getBorderColor(item) : undefined}
            isF={item.endsWith('/f')}
          >
            <CustomSelectText>{item === 'Lunch'  || item === 'Lunch/f' ? 'Almoço' 
                              : item === 'Dinner' || item === 'Lunch/f' ? 'Jantar' 
                              : item === 'Breakfast' || item === 'Lunch/f' ? 'Pequeno Almoço' 
                              : item.endsWith('/f') ? item.slice(0, -2) : item} 
                              { item.endsWith('/f') && <p style={{color:'white'}} > | Lotado*</p>}
            </CustomSelectText>
            <CustomSelectCheckWrapper>
              <CustomSelectCheck isSelected={selectedItem === item}>&#10003; </CustomSelectCheck> 
            </CustomSelectCheckWrapper>
          </CustomSelectOption>

        </>   
        ))}
      </>
        
      }
      
    </div>
  );
}

export default CustomSelect;