import React, { useState, memo, useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { Table } from './Table.js';
import { ItemTypes } from './ItemTypes.js';

function getStyles(left, top, isDragging, isPlaced, height, width) {
  return {
    position: 'absolute',
    top: `${top}px`,
    left: `${left}px`,
    width: `${width}px`,
    height: `${height}px`,
    display: isPlaced ? 'block' : 'none',
    opacity: isDragging ? 0 : 1,
    
  };
}

const snapTablePosition = (tables, movingTableId, newX, newY, snapThreshold = 20) => {
  let snappedX = newX;
  let snappedY = newY;

  Object.values(tables).forEach(table => {
    if (table.id !== movingTableId) {
      const isCloseX = Math.abs(newX - table.left) < snapThreshold;
      const isCloseY = Math.abs(newY - table.top) < snapThreshold;

      if (isCloseX && isCloseY) {
        snappedX = table.left;
        snappedY = table.top;
      }
    }
  });

  return { snappedX, snappedY };
};

const DraggableTable = memo(function DraggableTable({ id, 
                                                      table_name, 
                                                      left, 
                                                      top, 
                                                      height, 
                                                      width,  
                                                      isPlaced, 
                                                      selectedItem, 
                                                      selectItem, 
                                                      moveTable, 
                                                      tables,
                                                      table}) {
  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: ItemTypes.TABLE,
    item: { id, left, top, table_name },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const delta = monitor.getDifferenceFromInitialOffset();
      if (delta) {
        const newLeft = Math.round(item.left + delta.x);
        const newTop = Math.round(item.top + delta.y);
        const { snappedX, snappedY } = snapTablePosition(tables, id, newLeft, newTop);
        moveTable(id, snappedX, snappedY);
      }
    },
  }), [id, left, top, table_name, tables, moveTable]);

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  const handleClick = (e) => {
    selectItem(table);
  };

  return (
    <div
      ref={drag}
      style={getStyles(left, top, isDragging, isPlaced, height, width)}
      role="DraggableTable"
      onMouseDown={handleClick}
    >
        <Table table_name={table_name} selectedItem={selectedItem} id={id} item={table}/>
    </div>
  );
});

export default DraggableTable;
