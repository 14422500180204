import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import AnimatedCheck from './CheckMarkAnimation';
import { storeSubscription } from '../actions/userActions';
import { useSpring, animated } from 'react-spring';
import BellIconPng from '../icons/bell-svgrepo-com.png'; 

const BellIcon = styled.img`
  // Styles for the bell icon image
  width:30px;;
  height:30px;;
`;
const NotificationBox = styled(animated.div)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 15px;
  margin: auto;
  margin-top: 20px;
  background-color: #fafafa;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  font-size: 18px;
  font-family: Arial, sans-serif;
  transition: height 0.5s ease-out;
  height: ${props => props.hide ? '0' : 'auto'};
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    width: 90%;
    padding: 10px;
  }
`;

const NotificationButton = styled.button`
  color: #fff;
  background: #69b8a6; /* A gradient background */
  border: none; /* Remove the default button border */
  padding: .375rem .75rem;
  border-radius: 10px; /* More rounded border */
  cursor: pointer;
  margin-left: 20px; /* Add space between the text and the button */
  font-size: 18px; /* Match the text size */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15); /* Match the box shadow */
  transition: all 0.3s ease; /* Add a transition for hover effect */
  padding: 10px;

  &:hover {
    background: #92e8d6; /* Switch the gradient direction on hover */
  }

  @media only screen and (max-width: 768px) {
    padding: .25rem .5rem;
    font-size: 16px; /* Slightly smaller text on mobile */
  }
`;

const CheckContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
function urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');
  
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
  
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
  

const PushNotificationForReservation = ({setSubscription}) => {
  const [isShowButton, setIsShowButton] = useState(false);
  const [noServiceWorker, setNoServiceWorker] = useState(true);
  const [subscribe, setSubscribe] = useState(false);
  const [subscriptionInsideComponent, setSubscriptionInsideComponent] = useState({});


  useEffect(() => {
            if (typeof Notification !== 'undefined') {
            if ('serviceWorker' in navigator) {
                if (Notification.permission === "granted") {
                // The user has granted permission for notifications.
                console.log('Notification granted')
                } else if (Notification.permission === "denied") {
                // The user has denied permission for notifications.
                setIsShowButton(true);
                } else {
                // The user has not made a choice yet. You can ask them to grant permission.
                setIsShowButton(true);
                }
                console.log('permission status:',Notification.permission)
                if (subscribe) {
                    setSubscribe(false);
                    console.log('inside sub')
                    navigator.serviceWorker.register('sw.js')
                    .then(registration => {
                        // Wait until the service worker is active/ready
                        return navigator.serviceWorker.ready;
                    })
                    .then(registration => {
                        // Now subscribe to the push manager
                        return registration.pushManager.subscribe({
                            userVisibleOnly: true,
                            applicationServerKey: urlB64ToUint8Array('BDeLJiWmgkmvhJK-nhK0NuhLS0qpnuBNHlxhmpQR_OdK42tzaWfP-IAN1goje3_IJJRP-S4DTiQNQ1QpuyXhlOw')
                        });
                    })
                    .then(subscription => {
                        setSubscriptionInsideComponent(subscription);
                        console.log(' sub changed inside component')
                    })
                    .catch(error => {
                        console.error('Error creating push subscription:', error);
                    });     
                } 

                if (!isShowButton) {
                    console.log('hereeeeeeeeeeeeeeeeeeeeeeeeeeeeee');
                    // verify the current subscription
                    navigator.serviceWorker.register('sw.js')
                    navigator.serviceWorker.ready
                      .then(registration => {
                        console.log('navigator.serviceWorker.ready');
                        registration.pushManager
                          .getSubscription()
                          .then(subscription => {
                            console.log('registration.pushManager.getSubscription');
                            if (subscription) {
                              setSubscriptionInsideComponent(subscription);
                              console.log('sub changed inside component');
                            } else {
                              console.log('No current subscription.');
                              setIsShowButton(true);
                            }
                          })
                          .catch(error => {
                            console.error('Error getting subscription:', error);
                          });
                      })
                      .catch(error => {
                        console.error('Error with navigator.serviceWorker.ready:', error);
                      });
                  }
                  console.log('yata')
                }
            }
        }, [subscribe]);

  const [showCheck, setShowCheck] = useState(false); // Show the check mark animation
  const [hideBox, setHideBox] = useState(false); // Hide the box

  const boxAnimation = useSpring({
    from: { transform: 'translateY(0)', opacity: 1, height: 'auto' },
    to: {
      transform: hideBox ? 'translateY(-100%)' : 'translateY(0)',
      opacity: hideBox ? 0 : 1,
    },
    config: { tension: 210, friction: 20 },
});


useEffect (() => {
  if (typeof Notification !== 'undefined') {
  if ('serviceWorker' in navigator) {
    if (Notification.permission === "granted") {
        // The user has granted permission for notifications.
        if (Object.keys(subscriptionInsideComponent).length >= 0) {
            handleNotification();
            setSubscription(subscriptionInsideComponent);
        }
        } 
      }
      }
},[subscriptionInsideComponent]);




  const handleNotification = () => {
    setShowCheck(true); // Show the check mark animation
    setTimeout(() => {
      setHideBox(true); // Hide the box after a delay
    }, 1700); // Adjust the delay as needed
    setTimeout(() => {
        setIsShowButton(false); // Hide the box after a delay
      }, 2000); // Adjust the delay as needed

  };

  return (
    <>
    {isShowButton?
    <NotificationBox style={boxAnimation} hide={hideBox}>
      {showCheck ? (
        <CheckContainer>
          <AnimatedCheck />
        </CheckContainer>
      ) : true ? (
        <>
          <h6>Ativar Notificações de reserva :)</h6>
          <NotificationButton onClick={()=> setSubscribe(true)}>
          <BellIcon src={BellIconPng} alt="Bell Icon" />
          </NotificationButton>
        </>
      ) : null}
    </NotificationBox>
    :
    <></>
    }
    </>
  );
};

export default PushNotificationForReservation;
