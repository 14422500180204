import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import AnimatedCheck from './CheckMarkAnimation';
import { storeSubscription } from '../actions/userActions';
import { useSpring, animated } from 'react-spring';

const NotificationBox = styled(animated.div)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 15px;
  margin: auto;
  margin-top: 20px;
  background-color: #fafafa;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  font-size: 18px;
  font-family: Arial, sans-serif;
  transition: height 0.5s ease-out;
  height: ${props => props.hide ? '0' : 'auto'};
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    width: 90%;
    padding: 10px;
  }
`;

const NotificationButton = styled.button`
  color: #fff;
  background: #69b8a6; /* A gradient background */
  border: none; /* Remove the default button border */
  padding: .375rem .75rem;
  border-radius: 10px; /* More rounded border */
  cursor: pointer;
  margin-left: 20px; /* Add space between the text and the button */
  font-size: 18px; /* Match the text size */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15); /* Match the box shadow */
  transition: all 0.3s ease; /* Add a transition for hover effect */

  &:hover {
    background: #92e8d6; /* Switch the gradient direction on hover */
  }

  @media only screen and (max-width: 768px) {
    padding: .25rem .5rem;
    font-size: 16px; /* Slightly smaller text on mobile */
  }
`;

const CheckContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
function urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');
  
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
  
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
  

const NotificationPage = () => {
  const subscription = useSelector(state => state.userSubscription);
  const { pushSubscription } = subscription
  const user = useSelector(state => state.userLogin);
  const { userInfo } = user
  const dispatch = useDispatch();
  const [showButton, setShowButton] = useState(false);
  const [noServiceWorker, setNoServiceWorker] = useState(true);
  const [subscribe, setSubscribe] = useState(false);

  useEffect(() => {
        if (userInfo){
          if (typeof Notification !== 'undefined') {
            if ('serviceWorker' in navigator) {
                if (Notification.permission === "granted") {
                  // The user has granted permission for notifications.
                  console.log('Notification granted')
                } else if (Notification.permission === "denied") {
                  // The user has denied permission for notifications.
                  setShowButton(true);
                } else {
                  // The user has not made a choice yet. You can ask them to grant permission.
                  setShowButton(true);
                }
                console.log(Notification.permission)

                if (subscribe) {
                    navigator.serviceWorker.register('sw.js')
                    .then(registration => {
                        // Wait until the service worker is active/ready
                        return navigator.serviceWorker.ready;
                    })
                    .catch(error => {
                      console.error('Error during service worker registration or initialization:', error);
                    })
                    .then(registration => {
                        // Now subscribe to the push manager
                        return registration.pushManager.subscribe({
                            userVisibleOnly: true,
                            applicationServerKey: urlB64ToUint8Array('BDeLJiWmgkmvhJK-nhK0NuhLS0qpnuBNHlxhmpQR_OdK42tzaWfP-IAN1goje3_IJJRP-S4DTiQNQ1QpuyXhlOw')
                        });
                    })
                    .then(subscription => {
                    let isExistingSubscription = true;
                    if (subscription) {
                    for(let i = 0; i < pushSubscription.length; i++) {
                        console.log('pushSubscription[i].subscription.endpoint',pushSubscription[i].subscription_notification.endpoint)
                        console.log('subscription.endpoint',subscription.endpoint)
                        if(pushSubscription[i].subscription_notification.endpoint === subscription.endpoint) {
                            console.log('here')
                        isExistingSubscription = false;
                        break;
                        }
                    }
                    if (isExistingSubscription) {
                        console.log('Existing subscription not detected, updating.');
                        // Update the subscription on the server
                        dispatch(storeSubscription(userInfo.id, subscription));
                    } else {
                        console.log('Subscription already exists, no need to register.');
                    }
                    } 
                    }) 
                    .catch(error => {
                        console.error('Error creating push subscription:', error);
                    });     
                } 
                if (!showButton && pushSubscription) {
                    // verify the current subscription
                    navigator.serviceWorker.register('sw.js')
                    navigator.serviceWorker.ready.then(registration => {
                      registration.pushManager.getSubscription().then(subscription => {
                        if (subscription) {
                          let isExistingSubscription = pushSubscription.some(sub => 
                            sub && 
                            sub.subscription_notification && 
                            sub.subscription_notification.endpoint === subscription.endpoint
                        );
            
                          if (isExistingSubscription) {
                            console.log('Subscription exists in stored subscriptions.');
                          } else {
                            console.log('Current subscription does not exist in stored subscriptions. Updating.');
                            dispatch(storeSubscription(userInfo.id, subscription));
                          }
                        } else {
                          console.log('No current subscription.');
                          setShowButton(true);
                        }
                      });
                    }).catch(error => {
                      console.error('Error getting subscription:', error);
                    });
                  }
                }
              }
            }
            }, [userInfo, subscribe]);
  const [showCheck, setShowCheck] = useState(false); // Show the check mark animation
  const [hideBox, setHideBox] = useState(false); // Hide the box

  const boxAnimation = useSpring({
    from: { transform: 'translateY(0)', opacity: 1, height: 'auto' },
    to: {
      transform: hideBox ? 'translateY(-100%)' : 'translateY(0)',
      opacity: hideBox ? 0 : 1,
    },
    config: { tension: 210, friction: 20 },
});

  const handleNotification = () => {
    setSubscribe(true);
    setShowButton(false);
    setShowCheck(true); // Show the check mark animation

    setTimeout(() => {
      setHideBox(true); // Hide the box after a delay
    }, 1700); // Adjust the delay as needed
    setTimeout(() => {
        setShowButton(false); // Hide the box after a delay
      }, 2000); // Adjust the delay as needed

  };

  return (
    <>
    {showButton?
    <NotificationBox style={boxAnimation} hide={hideBox}>
      {showCheck ? (
        <CheckContainer>
          <AnimatedCheck />
        </CheckContainer>
      ) : userInfo ? (
        <>
          <p>Ative as notificações para não perder reservas</p>
          <NotificationButton onClick={handleNotification}>
            Ativar Notificações
          </NotificationButton>
        </>
      ) : null}
    </NotificationBox>
    :
    <></>
    }
    </>
  );
};

export default NotificationPage;
