import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Loader from './Loader';

const CustomSelectOption = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 2px;
  z-index: 0;
  font-size: 1rem;
  border: 1px solid ${({ borderColor }) => borderColor || 'black'};
  border-radius: 5px;
  margin-bottom: 2px;
  cursor: pointer;
  background-color: ${({ isSelected }) => (isSelected ? '#88F1D3' : 'transparent')};
  color: ${({ isSelected }) => (isSelected ? 'white' : 'black')};
  transition: all 0.2s ease-in-out;
  margin-right: 2px;
  &:hover {
    border-color: #88F1D3;
  }
`;

const CustomSelectText = styled.span`
  font-size:13px;
  color:black;
  width: 100%;
  display: inline-flex; // or flex if you want block behavior
  white-space: nowrap; // This will prevent text wrapping
`;

const CustomSelectCheckWrapper = styled.span`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 20px;
  border: 1px solid black;
  border-radius: 50%;
  background-color: white;
  margin-left: 1px;
  position: relative;
  z-index: 0;
`;

const CustomSelectCheck = styled.span`
  position: absolute;
  z-index: 0;
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};
  color: black;
`;
const CustomSelectContainer = styled.div`
  display: flex;
  flex-wrap: ${({ wrap }) => (wrap ? 'wrap' : 'nowrap')};
  align-items: center;
`;

function CustomSelectArrayOfObjects({ items, onSelectChange, selectedItems, wrap=false}) {
    console.log('items: ', items);
    console.log('selectedItems: ',selectedItems);

  const handleItemClick = (item) => {
    if (selectedItems.includes(item)) {
      onSelectChange(selectedItems.filter((selectedItem) => selectedItem !== item));
    } else {
      onSelectChange([...selectedItems, item]);
    }
  };

  return (
    <div>
      {Array.isArray(items) && items.length > 0 ?
      <div>
        <CustomSelectContainer wrap={wrap}>
        {items.map((item, index) => (
        <CustomSelectOption
          key={item.id}
          id={`custom-select-option-${index}`}
          isSelected={selectedItems.includes(item)}
          onClick={() => handleItemClick(item)}
        >
        <CustomSelectText>{item.name === '' ? `${item.start_time} | ${item.end_time}` : item.name}</CustomSelectText>
          <CustomSelectCheckWrapper>
            <CustomSelectCheck isSelected={selectedItems.includes(item) ? 1 : 0}>&#10003;</CustomSelectCheck>
          </CustomSelectCheckWrapper>
        </CustomSelectOption>
      ))}
        </CustomSelectContainer>
      </div>
      :
      <Loader></Loader>}
     
    </div>
  );
}
export default CustomSelectArrayOfObjects;