import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 0,
}

const getManagerInfo = localStorage.getItem('manager_info') ?
    JSON.parse(localStorage.getItem('manager_info')) : null;
const manager_info = getManagerInfo == null ? 
{
    value:0
} : {
    managerInfo:getManagerInfo,
}

export const crudManagerPermissionsSlice = createSlice({
    name: 'crudManagerPermissions',
    initialState: manager_info,
    reducers: {
    // Register Manager
    registerManagerRequest: (state) => {
        state.isLoadingRegisterManager = true;
    },
    registerManagerSuccess: (state, action) => {
        state.registerManager = action.payload;
        state.isLoadingRegisterManager = false;
    },
    registerManagerFail: (state, action) => {
        state.registerManagerError = action.payload;
        state.isLoadingRegisterManager = false;
    },
    // get Manager
    getManagerRequest: (state) => {
        state.isLoadingManagerInfo = true;
    },
    getManagerSuccess: (state, action) => {
        state.managerInfo = action.payload;
        state.isLoadingManagerInfo = false;
    },
    getManagerFail: (state, action) => {
        state.managerInfoError = action.payload;
        state.isLoadingManagerInfo = false;
        state.managerInfo = false;
    },
    // get all Managers from a specific account
    getManagersRequest: (state) => {
        state.isLoadingManagersInfo = true;
    },
    getManagersSuccess: (state, action) => {
        state.managersInfo = action.payload;
        state.isLoadingManagerInfo = false;
    },
    getManagersFail: (state, action) => {
        state.managersInfoError = action.payload;
        state.isLoadingManagersInfo = false;
        state.managersInfo = false;
    },
    // update Managers Permissions
    updateManagerRequest: (state) => {
        state.isLoadingUpdateManager = true;
    },
    updateManagerSuccess: (state, action) => {
        state.updatedManagerInfo = action.payload;
        state.isLoadingUpdateManager = false;
    },
    updateManagerFail: (state, action) => {
        state.updateManagersInfoError = action.payload;
        state.isLoadingUpdateManager = false;
        },
    // delete Manager Accounts
    deleteManagesrRequest: (state) => {
        state.isLoadingDeleteManager = true;
    },
    deleteManagersSuccess: (state, action) => {
        state.deleteManager = action.payload;
        state.isLoadingDeleteManager = false;
    },
    deleteManagersFail: (state, action) => {
        state.deleteManagerError = action.payload;
        state.isLoadingDeleteManager = false;
        },
    },
  })

  export const deleteManagerSlice = createSlice({
    name: 'deleteManagerSlice',
    initialState,
    reducers: {
    getManagesrRequest: (state) => {
        state.isLoadingDeleteManager = true;
    },
    getManagersSuccess: (state, action) => {
        state.deleteManager = action.payload;
        state.isLoadingDeleteManager = false;
    },
    getManagersFail: (state, action) => {
        state.deleteManagerError = action.payload;
        state.isLoadingDeleteManager = false;
        },
    },
  })

