import React, { useState } from 'react';
import { useSpring, animated, config } from 'react-spring';

const AnimatedCheck = () => {
  const [display, setDisplay] = useState('block');

  const scaleProps = useSpring({
    to: [{ scale: 1.2 }, { scale: 1 }],
    from: { scale: 0 },
    config: config.wobbly,
    delay: 100,
  });

  const { progress } = useSpring({
    to: { progress: 1 },
    from: { progress: 0 },
    config: config.molasses,
    delay: 900,
    onRest: () => setDisplay('none'),
  });

  return (
    <animated.svg
      style={{ display: display, transform: scaleProps.scale.interpolate((s) => `scale(${s})`) }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52"
    >
      <animated.circle fill="green" cx="26" cy="26" r="25" style={{ fill: 'green' }} />
      <animated.path
        fill="none"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="166"
        strokeDashoffset={progress.interpolate((p) => (1 - p) * 166)}
        d="M14 27l7.8 7.8L38 18"
      />
    </animated.svg>
  );
};

export default AnimatedCheck;