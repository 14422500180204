import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBaby } from '@fortawesome/free-solid-svg-icons';

const BabyChairOption = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid gray; // Add this line for a black border
  cursor: pointer;
  background-color: ${({ active }) => (active ? '#88F1D3' : 'white')};
  color: ${({ active }) => (active ? 'white' : 'black')};
  &:hover {
    background-color: #88F1D3;
    color: white;
  }
  &::after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    line-height: 24px;
    border-radius: 50%;
    border: 1px solid black;
    background-color: ${({ active }) => (active ? 'black' : '')};
    margin-left: auto;
    text-align: center;
    text-align:center;
    vertical-align:middle;
  }
`;

const BabyIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  size="lg"; 
  margin-right: 10px;
  font-size: 24px; // Increased font size
`;

const CustomSelectOption = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  font-size: 1rem;
  border: 1px solid ${({ borderColor }) => borderColor || 'black'};
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  background-color: ${({ isSelected }) => (isSelected ? '#88F1D3' : 'transparent')};
  color: ${({ isSelected }) => (isSelected ? 'white' : 'black')};
  transition: all 0.2s ease-in-out;
  margin-right: 10px;

  &:hover {
    border-color: #88F1D3;
  }
`;

const CustomSelectText = styled.span`
  margin-right: 5px;
`;

const CustomSelectCheckWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid black;
  border-radius: 50%;
  background-color: white;
  margin-left: 5px;
  position: relative;
`;

const CustomSelectCheck = styled.span`
  position: absolute;
  z-index: 1;
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};
  color: black;
`;

function BabyChairSelect({ active, onClick }) {
  return (
    <CustomSelectOption onClick={onClick} isSelected={active}>
      <BabyIcon icon={faBaby} />
      <CustomSelectText>Cadeirinha para bebé</CustomSelectText>
      <CustomSelectCheckWrapper>
        <CustomSelectCheck isSelected={active}>&#10003;</CustomSelectCheck>
      </CustomSelectCheckWrapper>
      
    </CustomSelectOption>
  );
}

export default BabyChairSelect;