import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const TimeInputField = styled.input`
display: block;
width: 100%;
height: calc(1.5em + .75rem + 2px);
padding: .375rem .75rem;
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
color: #495057;
background-color: #fff;
background-clip: padding-box;
border: 1px solid #ced4da;
border-radius: .25rem;
transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
margin-bottom: 10px;

&:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0,123,255,.25);
}
`;

const TimeOption = styled.div`
  padding: 5px;
  cursor: pointer;
  &:hover {
    background-color: '#f1f1f1';
  }
  background-color: 'white';
  color: 'black'; 
`;

const ModalBackground = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const PopupContainer = styled.div`
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1051;
  width: 100%; 
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
`;

function SelectableTimeInputForEstimatedTime({ options, onTimeChange, initialTime, id, isNewItem = false }) {
    const [selectedTime, setSelectedTime] = useState(initialTime || '');
    const [showPopup, setShowPopup] = useState(false);
  
    const handleTimeClick = (event, time) => {
      event.preventDefault();
      setSelectedTime(time);
      setShowPopup(false);
      // Create an event object that matches what your existing function expects
      const evt = {
        target: { value: time },
      };
      if (isNewItem) {
        onTimeChange(evt)
      } else {
        onTimeChange(id, evt);
      }
      
    };
  
    const togglePopup = (event) => {
      event.preventDefault();
      setShowPopup(!showPopup);
    };
  
    useEffect(() => {
        if (initialTime && initialTime.length > 5) {
          setSelectedTime(initialTime.slice(0, -3));
        } else {
          setSelectedTime(initialTime);
        }
      }, [initialTime]);
  
    return (
      <InputWrapper>
        <TimeInputField 
          type="text" 
          value={selectedTime} 
          onClick={togglePopup} 
          readOnly 
        />
  
        {showPopup && (
          <ModalBackground onClick={(event) => event.stopPropagation()}>
            <PopupContainer>
              {options.map((time) => (
                <TimeOption
                  key={time}
                  onMouseDown={(event) => handleTimeClick(event, time)}
                >
                  {time}
                </TimeOption>
              ))}
            </PopupContainer>
          </ModalBackground>
        )}
      </InputWrapper>
    );
  }
  
  export default SelectableTimeInputForEstimatedTime;