import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useStripe, useElements, PaymentElement, ElementsConsumer  } from '@stripe/react-stripe-js';
import {createPaymentIntent, confirmSubscription, resetConfirmSubscription} from '../actions/restaurantActions'
import styled from 'styled-components';


const stripePromise = loadStripe('pk_test_51OHTqdGwmqMQdn7MI69vIxvTMioVBA1EdYU78d6peZcznJt6r0X2Ck0cId43DCnOsX3whaGRGreLy6IhQsn7seGE00yaWQTNDf');



const CheckoutForm = ({restaurant_id, subscription_number, payment_method, clientSecret}) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(false);

  const paymentIntentInfo = useSelector(state => state.paymentIntent)
  const {  confirmSubscription, confirmPayment, isLoadingConfirmPayment,  confirmPaymentError} = paymentIntentInfo

  useEffect(() => {
    dispatch(resetConfirmSubscription());
  },[])

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        // You can still omit `return_url` if you handle the next steps in your app
      },
      redirect: 'if_required',
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      console.log('clientSecret: ', clientSecret)
      dispatch(confirmSubscription(restaurant_id, payment_method, clientSecret));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button className='btn btn-success' disabled={!stripe}>Pagar</button>
    </form>
  )
};


function StripePay({restaurant_id, subscription_number, payment_method}) {
    const [clientSecret, setClientSecret] = useState(false);

    const dispatch = useDispatch();

    const paymentIntentInfo = useSelector(state => state.paymentIntent)
    const {  paymentIntent, isLoadingPaymentIntent,  paymentIntentError} = paymentIntentInfo
  
    useEffect(() => {
      dispatch(createPaymentIntent(restaurant_id, subscription_number, payment_method))
  }, [restaurant_id, subscription_number, payment_method]);
  
  useEffect(() =>{
      if (paymentIntent){
          setClientSecret(paymentIntent.clientSecret) 
      }
  },[paymentIntent])
  

    if (!clientSecret) {
        return <div>Loading...</div>; // Or any other loading state representation
    }
    return (
        <Elements stripe={stripePromise} options={{clientSecret}}>
            <CheckoutForm 
                restaurant_id={restaurant_id}
                subscription_number={subscription_number}
                payment_method={payment_method}
                clientSecret={clientSecret}
            />
        </Elements>
    );
}

export default StripePay;