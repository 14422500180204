import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Button, Container } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { fetchReservation } from '../actions/restaurantActions';

const ReservationFromRestaurantDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const reservation = useSelector((state) => state.currentReservation);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(fetchReservation(id.reservation_id));
    }
  }, [id, dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    reservation[name] = value;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // dispatch(saveReservation(reservation));
    navigate('/reservations');
  };

  return (
    <Container>
      <h1>{id ? 'Edit Reservation' : 'Create Reservation'}</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="full_name">
          <Form.Label>Full Name</Form.Label>
          <Form.Control
            type="text"
            name="full_name"
            value={reservation.full_name || ''}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={reservation.email || ''}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="phone_number">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type="text"
            name="phone_number"
            value={reservation.phone_number || ''}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="date">
          <Form.Label>Date</Form.Label>
          <Form.Control
            type="date"
            name="date"
            value={reservation.date || ''}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="shift">
          <Form.Label>Shift</Form.Label>
          <Form.Control
            as="select"
            name="shift"
            value={reservation.shift || ''}
            onChange={handleChange}
            required
          >
            <option value="Breakfast">Breakfast</option>
            <option value="Lunch">Lunch</option>
            <option value="Dinner">Dinner</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="time">
          <Form.Label>Time</Form.Label>
          <Form.Control
            type="text"
            name="time"
            value={reservation.time || ''}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="number_of_people">
          <Form.Label>Number of People</Form.Label>
          <Form.Control
            type="number"
            min="1"
            name="number_of_people"
            value={reservation.number_of_people || ''}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="baby_chair">
          <Form.Label>Baby Chair</Form.Label>
          <Form.Control
            type="number"
            min="0"
            name="baby_chair"
            value={reservation.baby_chair || ''}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="message">
        <Form.Label>Message</Form.Label>
        <Form.Control
        as="textarea"
        rows={3}
        name="message"
        value={reservation.message || ''}
        onChange={handleChange}
        />
        </Form.Group>
        {/* Add the remaining form inputs for other fields, such as table_place_preference, estimatedtime, and tablesused /}
        {/ ... */}
        <Form.Group controlId="table_place_preference">
        <Form.Label>table_place_preference</Form.Label>
        <Form.Control
        as="textarea"
        rows={3}
        name="message"
        value={reservation.table_place_preference || ''}
        onChange={handleChange}
        />
        </Form.Group>
        <Form.Group controlId="estimatedtime">
        <Form.Label>estimatedtime</Form.Label>
        <Form.Control
        as="textarea"
        rows={3}
        name="message"
        value={reservation.estimatedtime || ''}
        onChange={handleChange}
        />
        </Form.Group>
        <Form.Group controlId="tablesused">
        <Form.Label>tablesused</Form.Label>
        <Form.Control
        as="textarea"
        rows={3}
        name="message"
        value={reservation.tablesused || ''}
        onChange={handleChange}
        />
        </Form.Group>

        <Button variant="primary" type="submit">
        {id ? 'Update' : 'Create'} Reservation
        </Button>
        </Form>
</Container>
);
};

export default ReservationFromRestaurantDetails;