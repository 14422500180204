import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Button, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Messages from '../components/Messages'
import { updateUser, resetUpdate } from '../actions/userActions'
import Separator from '../components/Separator'
import { updateManager } from '../actions/managersActions'
import { Alert } from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey,  faEye, faEyeSlash, faCircleCheck } from '@fortawesome/free-solid-svg-icons';

const Title = styled.h3`
  margin: 20px;
  font-weight: bold;
  text-transform: uppercase;
`;

const AbsoluteKeySquare = styled.div`
  position: absolute;
  display:flex;
  justify-content:center;
  align-items:center;
  border-radius: 4px 0 0 4px;
  background-color: #f2f2f2;
  border: solid 1px #cfcfcf;
  top: 0px;
  left: 0px;
  width: 50px;
  height: 38px;
  padding: 1px;
  font-size: 18px;
  text-align: center;
  &:hover {
    background-color: #e8e8e8;
    border-color: #76d6c5;
  }
`;

const StyledCard = styled.div`
  border-radius:5px;
  max-width: 500px;
  padding:15px;
  border: 1px solid #dbdbdb;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width:100%;
`;

function UpdateProfile() {
const params = useParams();
const userLogin = useSelector(state => state.userLogin)
const { userInfo, isLoading, error} = userLogin

const userUpdate = useSelector(state => state.userUpdate)
const { success } = userUpdate

const manager_info = useSelector(state => state.crudManager)
const { managerInfo } = manager_info

const [name, setName] = useState(userInfo.name);
const [email, setEmail] = useState(userInfo.email);
const [password, setPassword] = useState('');
const [confirmPassword, setConfirmPassword] = useState(''); 
const [passwordsMatch, setPasswordsMatch] = useState(true);
const [accountupdated, setaccountupdated] = useState(false);
const [first_time_password, setFirst_time_password] = useState(params.firs_time_password_change === 'first_time'? true : false);
const [showPasswords, setShowPasswords] = useState(true);
const navigate = useNavigate()
const dispatch = useDispatch()
const location = useLocation() 
const redirect = location.search ? location.search.split('=')[1] : '/'

  
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    };


    
  useEffect(()=> {
    if (password !== confirmPassword) {
        setPasswordsMatch(false);
    } else {
        setPasswordsMatch(true);
    }

    if (success == true) {
      dispatch(resetUpdate())
      setaccountupdated(true)
    }

  },);

  const handleSubmit = (event) => {
    event.preventDefault();
    // TODO: Update user profile with new data
    if (managerInfo && managerInfo.first_time_password_change === false) {
      if (passwordsMatch) {
        dispatch(updateUser({
          'id':userInfo.id,
          'password':password,
        }))
        dispatch(updateManager(false, false, true, false));
        navigate(redirect)
      }
    } else {
      if (passwordsMatch) {
        dispatch(updateUser({
          'id':userInfo.id,
          'name':name,
          'email':email,
          'password':password,
        }))
      }
  };
}

  return (
    <Container>
      <Separator
        step={'Gerir conta'}
        stepName={'manager_first_time_password'}
      />
      <Form onSubmit={handleSubmit}>
        {accountupdated && <Messages variant='success'>Informações atualizadas!</Messages>}
        {managerInfo && managerInfo.first_time_password_change === false ?
        <></>
        :
        <>
          <Form.Group className="mb-3" controlId="formName">
          <Form.Label>Nome de utilizador</Form.Label>
          <Form.Control required type="text" value={name} onChange={(e) => setName(e.target.value)} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formEmail">
            <Form.Label>Endereço de email</Form.Label>
            <Form.Control required type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </Form.Group>
        </>
        }
        {managerInfo && managerInfo.first_time_password_change === false &&
        <Alert variant="success">
            <Alert.Heading>
                <i className="fas fa-smile-beam" style={{marginRight: "10px"}}></i>
                Olá!
            </Alert.Heading>
            <p>
                Por sua segurança, necessitamos que troque a sua palavra passe. Assim ajuda-nos a manter a sua conta segura.
            </p>
            <hr />
            <p className="mb-0">
                Vamos começar?
            </p>
        </Alert>
        }
        <Form.Group className="mb-3" controlId="formPassword">
          
          <div style={{ position: 'relative' }}>
              <Form.Control required type={showPasswords ? 'password' : 'text'} placeholder="palavra passe" value={password} onChange={(e) => setPassword(e.target.value)} style={{ paddingLeft: '60px' }} />
                  <AbsoluteKeySquare style={{cursor: 'pointer'}} onClick={() => setShowPasswords(!showPasswords)}>
                  {showPasswords ?
                    <FontAwesomeIcon size='lg' icon={faEyeSlash} style={{color: "#00ad74"}}/>
                  :
                    <FontAwesomeIcon size='lg' icon={faEye} style={{color: "#00ad74"}}/>
                  }
                  </AbsoluteKeySquare>
          </div>
          <Form.Text className="text-muted">
          </Form.Text>

        </Form.Group>

        <Form.Group controlId="formBasicConfirmPassword">
          <div style={{ position: 'relative' }}>
              <Form.Control
              type={showPasswords ? 'password' : 'text'}
              placeholder="confirmar palavra passe" 
              value={confirmPassword} 
              required
              onChange={handleConfirmPasswordChange}
              style={{ paddingLeft: '60px' }}/>
              <AbsoluteKeySquare style={{cursor: 'pointer'}} onClick={() => setShowPasswords(!showPasswords)}>
              {showPasswords ?
                <FontAwesomeIcon size='lg' icon={faEyeSlash} style={{color: "#00ad74"}}/>
              :
                <FontAwesomeIcon size='lg' icon={faEye} style={{color: "#00ad74"}}/>
              }
              </AbsoluteKeySquare>
          </div>
          {!passwordsMatch && <Form.Text className="text-danger">As palavra-passe não coincidem</Form.Text>}
          </Form.Group>
        

        <Button className='mt-3' variant="primary" type="submit">
          {managerInfo && managerInfo.first_time_password_change === false ?
          <>
          Confirmar a palavra-passe
          </>:
          <>
          Guardar
          </>
          }
        </Button>
      </Form>
    </Container>
    
  );
}

export default UpdateProfile;