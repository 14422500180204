import React, { useState, useRef, useEffect } from 'react';
import Loader from './Loader'
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock} from '@fortawesome/free-solid-svg-icons'



const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
  min-width:200px;
`;

const SelectButton = styled.button`
  width: 65%;
  background-color: ${props => (props.allOptionsFull && props.enableFullFlag) ? '#f44336' : props.blocked ? '#ed7a40' : 'white'};
  color: ${props => (props.allOptionsFull && props.enableFullFlag) ? 'white' : props.blocked ? 'white' : 'black'};
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  pointer-events: ${({ blocked }) => (blocked === true ? 'none' : 'auto')};
  cursor:  ${({ allOptionsFull, enableFullFlag, blocked}) => (allOptionsFull && enableFullFlag ? 'none': blocked ? 'none' :'pointer')};
  pointer-events: ${props => (props.allOptionsFull && props.enableFullFlag) ? 'none' : 'auto'}; 
`;

const PopupContainer = styled.div`
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1051;
  width: 500px; // Set the desired width here
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // Custom scrollbar styles
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  @media (max-width: 576px) {
    width: 95%;  // increase width for smaller screens
  }
`;



const ModalBackground = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const TimeOption = React.forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{
      padding: '5px',
      cursor: 'pointer',
      backgroundColor: props.isFull ? '#f44336' : (props.isSelected ? '#f1f1f1' : 'white'),
      color: props.isFull ? 'white' : 'black',
      pointerEvents: props.isFull ? 'none' : 'auto'
      // Add other styles if needed...
    }}
    onMouseDown={props.onMouseDown}
  >
    {props.children}
  </div>
));


function SelectablePopUpOptions({ options, onTimeChange, time, setTime, 
                                  deactivateBaby, handleBabyActive, 
                                  enableFullFlag = false, currentShift, 
                                  currentZone, editingStartFalse, setShift}) {
    const [selectedTime, setSelectedTime] = useState(time || null);
    const [showPopup, setShowPopup] = useState(false);
    const [wasActive, setWasActive] = useState(false);
    const [allOptionsFull, setAllOptionsFull] = useState(false);
    const [loading, setLoading] = useState(true);
    const [currentOptions, setCurrentOptions] = useState('');
    const [selectedTimeIndex, setSelectedTimeIndex] = useState(null);
    const optionRefs = useRef([]);
    const selectedTimeRef = useRef(null);

    const handleTimeClick = (event, time, originalTime,index) => {
      event.preventDefault();
      if (enableFullFlag && time.endsWith('/f')) {
        // Ignore clicks on 'full' options
        return;
      }
      let prefix;
      switch (originalTime.slice(0,2)) {
        case 'L/':
          prefix = 'Lunch';
          break;
        case 'D/':
          prefix = 'Dinner';
          break;
        case 'B/':
          prefix = 'Breakfast';
          break;
      }
      console.log('originalTime.slice(2): ', originalTime.slice(0,2))
      console.log('prefix: ', prefix)
      setShift(prefix);
      console.log('original time: ', originalTime)
      setSelectedTime(time);
      setShowPopup(false);
      setTime(time);
      onTimeChange(time);
      if (wasActive == true) {
        handleBabyActive(true);
      } else {
        handleBabyActive(false);
      }
      setSelectedTimeIndex(index);
    };

    useEffect(() => {
      if (!options || !currentZone) return;
    
      let timesInCurrentZone = options[currentZone];
      /* if (!timesInCurrentZone) return; */
    
      /* let prefix;
      switch (currentShift) {
        case 'Lunch':
          prefix = 'L/';
          break;
        case 'Dinner':
          prefix = 'D/';
          break;
        case 'Breakfast':
          prefix = 'B/';
          break;
        default:
          return;
      }
    
      let filteredTimes = timesInCurrentZone.filter(time => time.startsWith(prefix)); */
      setCurrentOptions(timesInCurrentZone);
      
    }, [options, currentZone, currentShift]);
    

    useEffect(() => {
      let notFound = true;
      console.log('currentOptions :', currentOptions)
      if (Array.isArray(currentOptions)) {
        for (let option of currentOptions) {
          if (selectedTime === option.slice(2)) {
            console.log('option.slice(2): ', option.slice(2))
            console.log('time: ', time)
            let prefix;
            console.log('option slice', option.slice(0,2))
            switch (option.slice(0,2)) {
              case 'L/':
                prefix = 'Lunch';
                break;
              case 'D/':
                prefix = 'Dinner';
                break;
              case 'B/':
                prefix = 'Breakfast';
                break;
            }
            setShift(prefix);
            notFound = false;
          } 
        }
      }
      
      console.log('notFound: ', notFound)
      console.log('editingStartFalse: ', editingStartFalse)
      if (notFound && editingStartFalse && Array.isArray(currentOptions)) {
        for (let option of currentOptions) {
          if (!option.endsWith('/f')) {
              setTime(option.slice(2));
              setSelectedTime(option.slice(2));
              let prefix;
              console.log('option.slice(0,2): ', option.slice(0,2))
              switch (option.slice(0,2)) {
                case 'L/':
                  prefix = 'Lunch';
                  break;
                case 'D/':
                  prefix = 'Dinner';
                  break;
                case 'B/':
                  prefix = 'Breakfast';
                  break;
              }
              setShift(prefix);
              break; 
          }
      }
      }
      
    }, [currentOptions]);
    
    useEffect(() => {
      let findNewTime = false;
      if (time && Array.isArray(currentOptions)) {
        for (let option of currentOptions) {
          if (option.slice(2,7) === time && option.endsWith('/f')) {
            findNewTime = true;
          }
        }
      }
      if (findNewTime) {
        for (let option of currentOptions) {
          if (!option.endsWith('/f')) {
              setTime(option.slice(2));
              setSelectedTime(option.slice(2));
              break;
            }
          }
      }
    },[time, currentOptions])

    const togglePopup = (event) => {
      event.preventDefault();
      setShowPopup(!showPopup);
      if (deactivateBaby == true) {
        handleBabyActive(false);
        setWasActive(true);
      } else {
        handleBabyActive(false);
      }
    
      // Scroll to the selected item when the popup opens
      if (!showPopup && typeof selectedTimeIndex === 'number') {
        setTimeout(() => {
          const elementToScrollTo = optionRefs.current[selectedTimeIndex];
          if (elementToScrollTo && document.body.contains(elementToScrollTo)) {
            elementToScrollTo.scrollIntoView({ behavior: 'smooth', block: 'center' });
          } else {
            console.error("Element does not exist or is not in the document:", elementToScrollTo);
          }
        }, 500); // Increase the delay to 500ms, you can tweak this value
      }
    };

    useEffect(() => {
      if (Array.isArray(currentOptions) && currentOptions && currentOptions.length) { 
        if (enableFullFlag) {
          setAllOptionsFull(currentOptions.every(opt => opt.endsWith('/f')));
        } else {
          setAllOptionsFull(false);
        }
          setLoading(false);
      } else {
          setLoading(true);
      }
    }, [currentOptions, enableFullFlag]);

    useEffect(() => {
      if (showPopup && selectedTimeRef.current) {
        selectedTimeRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, [showPopup]);

    return (
      <SelectWrapper>
        {loading ? 
          <></>
          : Array.isArray(currentOptions) && currentOptions.length <= 0 ?
          <></>
          
          :
            <div style={{display:'flex', flexWrap:'wrap', width:'100%'}}>
              <FontAwesomeIcon size='xl' icon={faClock} style={{ marginRight: '5px', paddingTop:'5px'}}/>
              <SelectButton allOptionsFull={allOptionsFull} enableFullFlag={enableFullFlag} onMouseDown={(event) => {!allOptionsFull && togglePopup(event)}}>
                {allOptionsFull && enableFullFlag ? 'Lotado por favor selecionar outra sala' 
                : Array.isArray(currentOptions) && currentOptions.length <= 0 ? 'Escolher numero de pessoas' 
                : (selectedTime || 'Selecionar')}
              </SelectButton>
            </div> 
        }
        
        {showPopup && (
          <ModalBackground onClick={() => {
              setShowPopup(false);
              if (wasActive) {
                  handleBabyActive(true);
              } else {
                  handleBabyActive(false);
              }
          }}>
            <PopupContainer onClick={(event) => event.stopPropagation()}>
              {Array.isArray(currentOptions) && currentOptions.map((originalTime, index) => {
                let isFull = enableFullFlag && originalTime.endsWith('/f');
                let displayTime = isFull ? originalTime.slice(2, -2) + "     Lotado" : originalTime.slice(2);
                let timeForHandler = originalTime.slice(2);
                let isSelected = selectedTime === timeForHandler;
  
                return (
                  <TimeOption
                    ref={timeForHandler === selectedTime ? selectedTimeRef : null}
                    key={originalTime}
                    isFull={isFull}
                    isSelected={isSelected}
                    onMouseDown={(event) => handleTimeClick(event, timeForHandler, originalTime,index)}
                  >
                    {displayTime}
                  </TimeOption>
                );
              })}
            </PopupContainer>
          </ModalBackground>
        )}
      </SelectWrapper>
    );
  }
export default SelectablePopUpOptions;