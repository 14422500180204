import { memo, useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledTable = styled.div`
  display: flex; /* Use flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  border: 1px dashed gray;
  height: ${50}px;
  width: ${50}px;
  border-radius: ${props => (props.item.round_or_square === 'square' ? 0 : 50)}%;
  cursor: move;
  color: ${props => (props.isSelected ? 'white' : 'gray')};
  background-color: ${props => (props.isSelected ? 'green' : 'white')};
  text-align: center;
`;

export const Table = memo(function Table({ table_name, 
                                            yellow, 
                                            preview, 
                                            id,  
                                            selectedItem, 
                                            item }) {
  const [isSelected, setIsSelected] = useState(false);
  useEffect(() => {
    if (selectedItem && selectedItem.id === item.id){
      setIsSelected(true)
    } else {
      setIsSelected(false)
    }
  },[selectedItem, item.id])

  useEffect(() => {
    console.log('item inside table: ', item, id)
  },[item]);
  
  return (
    <>
    {item &&
      <StyledTable isSelected={isSelected} role={preview ? 'TablePreview' : 'Table'} item={item}>
        {table_name}
      </StyledTable>
    }
    </>
  );
});
